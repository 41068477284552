/**
 * Identifica o gênero gramatical de uma palavra em português brasileiro
 * @param {string} palavra - A palavra a ser analisada
 * @returns {string} - "masculine" para palavras masculinas ou "feminine" para palavras femininas
 */
function identificarGenero(palavra) {
    // Normaliza a palavra: remove espaços extras, converte para minúsculas
    palavra = palavra.trim().toLowerCase();

    // Lista de exceções conhecidas (palavras que não seguem os padrões regulares)
    const excecoes = {
        // Palavras femininas que não terminam em 'a'
        "task": "feminine",
        "mão": "feminine",
        "paz": "feminine",
        "cor": "feminine",
        "luz": "feminine",
        "flor": "feminine",
        "matriz": "feminine",
        "nação": "feminine",
        "digital": "feminine",
        "vogal": "feminine",
        "virtude": "feminine",
        "legião": "feminine",
        "tribo": "feminine",
        "libido": "feminine",
        "viagem": "feminine",
        "coragem": "feminine",
        "imagem": "feminine",
        "mensagem": "feminine",
        "oportunidade": "feminine",
        "personalidade": "feminine",
        "homenagem": "feminine",
        "análise": "feminine",
        "síntese": "feminine",
        "classe": "feminine",
        "noite": "feminine",
        "arte": "feminine",
        "ponte": "feminine",
        "fonte": "feminine",
        "pele": "feminine",
        "sede": "feminine",
        "rede": "feminine",
        "carne": "feminine",
        "árvore": "feminine",

        // Palavras masculinas que terminam em 'a'
        "dia": "masculine",
        "mapa": "masculine",
        "planeta": "masculine",
        "clima": "masculine",
        "poema": "masculine",
        "sistema": "masculine",
        "problema": "masculine",
        "diagrama": "masculine",
        "programa": "masculine",
        "telegrama": "masculine",
        "teorema": "masculine",
        "dilema": "masculine",
        "cinema": "masculine",
        "tema": "masculine",
        "idioma": "masculine",
        "aroma": "masculine",
        "enigma": "masculine",
        "drama": "masculine",
        "panorama": "masculine",
        "sofá": "masculine",
        "protocolo": "masculine"
    };

    // Verifica se a palavra está na lista de exceções
    if (excecoes.hasOwnProperty(palavra)) {
        return excecoes[palavra];
    }

    // Regras para terminations comuns

    // Palavras terminadas em "dade", "ção", "são", "gem" são geralmente femininas
    if (palavra.endsWith("dade") ||
        palavra.endsWith("ção") ||
        palavra.endsWith("são") ||
        palavra.endsWith("gem") ||
        palavra.endsWith("tude") ||
        palavra.endsWith("ase") ||
        palavra.endsWith("ese") ||
        palavra.endsWith("ise")) {
        return "feminine";
    }

    // Palavras terminadas em "o", "or", "im", "om", "um", "l", "r", "s", "z" são geralmente masculinas
    if (palavra.endsWith("o") ||
        palavra.endsWith("or") ||
        palavra.endsWith("im") ||
        palavra.endsWith("om") ||
        palavra.endsWith("um") ||
        (palavra.endsWith("l") && !palavra.endsWith("al")) || // muitas palavras em "al" são femininas
        (palavra.endsWith("r") && !palavra.endsWith("er")) || // algumas palavras em "er" são femininas
        (palavra.endsWith("s") && !palavra.endsWith("es")) || // muitas palavras em "es" são femininas
        palavra.endsWith("z")) {
        return "masculine";
    }

    // Palavras terminadas em "a", "ã", "á" são geralmente femininas
    if (palavra.endsWith("a") ||
        palavra.endsWith("ã") ||
        palavra.endsWith("á")) {
        return "feminine";
    }

    // Palavras terminadas em "e" podem ser tanto masculinas quanto femininas
    // Vamos considerar como masculinas por padrão (mais comum)
    if (palavra.endsWith("e")) {
        return "masculine";
    }

    // Regra padrão: se não conseguir determinar, assumimos masculino
    return "masculine";
}

export default identificarGenero;