import React, { useContext } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ViewListIcon from '@material-ui/icons/ViewList';
import ArchiveIcon from '@material-ui/icons/Archive';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AdjustIcon from '@material-ui/icons/Adjust';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth/AuthContext';

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: theme.mode === 'light' ? '#000000' : '#FFF',
    '&:hover': {
      backgroundColor: theme.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
    },
  },
  icon: {
    color: theme.mode === 'light' ? '#000000' : '#FFF',
    minWidth: 32,
  },
  divider: {
    borderTop: theme.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.1)' : '1px solid rgba(255, 255, 255, 0.1)',
    margin: theme.spacing(1, 0),
  },
  footer: {
    marginTop: 'auto', // Empurra o footer para o final
    borderTop: theme.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.1)' : '1px solid rgba(255, 255, 255, 0.1)', // Adiciona uma linha de divisão
  },
}));

const MainMenu = ({ onNavigate }) => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();

  const menuItems = [
    // Primeira seção
    [
      {
        icon: <InfoIcon />,
        text: 'Sobre este quadro',
        secondary: 'Adicione uma descrição ao seu quadro',
        screen: 'about'
      },
      { icon: <ViewListIcon />, disabled: true, text: 'Atividade (Em breve)' },
      { icon: <ArchiveIcon />, disabled: true, text: 'Itens arquivados (Em breve)' },
    ],
    // Segunda seção
    [
      ...(user?.profile === 'admin' ? [{ icon: <SettingsIcon />, text: 'Configurações', screen: 'settings' }] : []),
      { icon: <LabelIcon />, text: 'Tags', screen: 'tags' },
      { icon: <WatchLaterIcon />, text: 'Status', screen: 'status' },
      { icon: <AdjustIcon />, text: 'Fontes de oportunidades', screen: 'source' },
    ],
  ];

  console.info({ menuItems });

  const menuItemsFooter = [
    //  { icon: <ExitToAppIcon />, text: 'Sair do quadro', screen: 'exit' },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 65px)', }}>
      {/* Renderiza os menuItems */}
      {menuItems.map((section, sectionIndex) => (
        <React.Fragment key={`section-${sectionIndex}`}>
          {sectionIndex > 0 && <div className={classes.divider} />}
          <List>
            {section.map((item, itemIndex) => (
              <ListItem
                disabled={item.disabled}
                button
                className={classes.listItem}
                key={`item-${sectionIndex}-${itemIndex}`}
                onClick={() => item.screen && onNavigate(item.screen)}
              >
                <ListItemIcon className={classes.icon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  secondary={item.secondary}
                  secondaryTypographyProps={
                    item.secondary ? { style: { color: theme.mode === 'light' ? '#575757' : '#C9D1D9', fontSize: 12 } } : undefined
                  }
                />
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      ))}

      {/* Renderiza o menuItemsFooter no final */}
      {menuItemsFooter?.length > 0 && (
        <div className={classes.footer}>
          <List>
            {menuItemsFooter.map((item, index) => (
              <ListItem
                button
                className={classes.listItem}
                key={`footer-item-${index}`}
                onClick={() => item.screen === 'exit' ? history.push(`/opportunities`) : onNavigate(item.screen)}
              >
                <ListItemIcon className={classes.icon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
};

export default MainMenu;