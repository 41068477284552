const handleEditCard = async ({ action, cardId, card: cardData, setLoadingCard, setKanbanData, enableLoading = false }) => {
    try {
        // Inicia o estado de loading para este card
        if (enableLoading) setLoadingCard((prevLoading) => ({ ...prevLoading, [`card-${cardId}`]: true }));

        // Criando novo card
        if (action === 'create') {
            setKanbanData((prevState) => {
                const updatedColumns = prevState.column.map((c) => {
                    if (c.id === cardData.kanbanColumnId) {
                        // Obtém todos os cards da coluna ordenados
                        const currentCards = [...c.cards].sort((a, b) => a.order - b.order);

                        // Define a ordem do novo card (no final da coluna ou na posição especificada)
                        const newCardOrder = cardData.order !== undefined ?
                            cardData.order :
                            currentCards.length;

                        // Cria o novo card com a ordem definida
                        const newCard = {
                            ...cardData,
                            id: cardId, // Usa o ID fornecido
                            order: newCardOrder
                        };

                        // Adiciona o novo card na posição correta
                        currentCards.splice(newCardOrder, 0, newCard);

                        // Reordena todos os cards da coluna
                        const updatedCards = currentCards.map((card, index) => ({
                            ...card,
                            order: index
                        }));

                        return { ...c, cards: updatedCards };
                    }
                    return c;
                });

                return { ...prevState, column: updatedColumns };
            });
        }

        // Movendo card de posição
        if (action === 'order') {
            setKanbanData((prevState) => {
                const updatedColumns = prevState.column.map((c) => {
                    if (c.id === cardData.kanbanColumnId) {
                        const currentCards = [...c.cards].sort((a, b) => a.order - b.order);
                        const cardIndex = currentCards.findIndex((card) => card.id === cardId);
                        const removedCard = currentCards.splice(cardIndex, 1);
                        currentCards.splice(cardData.order, 0, removedCard[0]);
                        console.info({ currentCards });
                        const updatedCards = currentCards.map((card, index) => ({ ...card, order: index }));
                        return { ...c, cards: updatedCards };
                    }
                    return c;
                });
                return { ...prevState, column: updatedColumns };
            });
        };

        // Movendo card de coluna (e talvez de posição)
        if (action === 'column') {
            setKanbanData((prevState) => {
                const updatedColumns = [...prevState.column];
                // Encontra as colunas de origem e destino
                const sourceCol = updatedColumns.find(col => col.id === cardData.oldColumnId);
                const destCol = updatedColumns.find(col => col.id === cardData.kanbanColumnId);
                if (!sourceCol) return prevState;
                // Encontra e remove o card da coluna de origem
                const sourceCards = [...sourceCol.cards].sort((a, b) => a.order - b.order);
                const cardIndex = sourceCards.findIndex(card => card.id === cardId);
                if (cardIndex === -1) return prevState;
                const movedCard = { ...sourceCards[cardIndex], order: cardData.order };
                sourceCards.splice(cardIndex, 1);
                // Atualiza a ordem dos cards na coluna de origem
                sourceCol.cards = sourceCards.map((card, idx) => ({ ...card, order: idx }));
                // Se for uma coluna diferente e existir, adiciona o card à coluna de destino
                if (cardData.oldColumnId !== cardData.kanbanColumnId && destCol) {
                    // Inverte a ordem dos cards antes de adicionar o novo
                    let destCards = [...destCol.cards].sort((a, b) => a.order - b.order);

                    if (!destCards.some(card => card.id === cardId)) {
                        const newPos = Math.min(movedCard.order, destCards.length);
                        destCards.splice(newPos, 0, { ...movedCard, order: newPos });

                        destCol.cards = destCards.map((card, idx) => ({ ...card, order: idx }));
                        // Reverte de volta para a ordem original antes de atualizar os índices
                        destCards = destCards;
                    }
                }
                return { ...prevState, column: updatedColumns };
            });
        };

        // Atualizando dados do card
        if (action === 'update') {
            console.info("Revisar este componente", cardData)
            setKanbanData((prevData) => {
                const updatedColumns = prevData.column.map((c) => {
                    const cardIndex = c.cards.findIndex((card) => card.id === cardId);
                    if (cardIndex !== -1) {
                        const updatedCards = [...c.cards];
                        console.info({ cardData, card: updatedCards[cardIndex] })
                        updatedCards[cardIndex] = { ...cardData };
                        return { ...c, cards: updatedCards };
                    }
                    return c;
                });
                console.info({ ...prevData, column: updatedColumns });
                return { ...prevData, column: updatedColumns };
            });
        };

        // Remover card
        if (action === 'delete') {
            setKanbanData((prevData) => {
                const updatedColumns = prevData.column.map((column) => {
                    const updatedCards = column.cards.filter((card) => card.id !== cardId);
                    return { ...column, cards: updatedCards };
                });
                return { ...prevData, column: updatedColumns };
            });
        };
    } catch (error) {
        console.error({ action: 'erro ao editar card', error });
    } finally {
        if (enableLoading) setTimeout(() => setLoadingCard((prevLoading) => ({ ...prevLoading, [`card-${cardId}`]: false })), 300);
    }
};

export default handleEditCard;