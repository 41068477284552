import api from '../../services/api';

class InMemoryCache {
    constructor() {
        this.store = new Map();
    }

    set(key, value, ttl) {
        const expires = Date.now() + ttl;
       // console.log(`[CACHE] Armazenando chave: ${key}, TTL: ${ttl}ms`);
        this.store.set(key, { value, expires });
        setTimeout(() => {
           // console.log(`[CACHE] Expirando chave após TTL: ${key}`);
            this.store.delete(key);
        }, ttl);
    }

    get(key) {
        const entry = this.store.get(key);
        if (!entry || Date.now() > entry.expires) {
            this.store.delete(key);
            return null;
        }
        return entry.value;
    }
}

const cache = new InMemoryCache();
const pendingRequests = new Map();

const normalizeParams = (params) => {
    const { status, ...rest } = params;
    const normalize = (value) => {
        if (Array.isArray(value)) {
            return value.map(normalize).sort();
        } else if (typeof value === 'object' && value !== null) {
            return Object.keys(value)
                .sort()
                .reduce((acc, key) => {
                    acc[key] = normalize(value[key]);
                    return acc;
                }, {});
        }
        return value;
    };
    return JSON.stringify(normalize(rest));
};

const getCacheKey = (params) => normalizeParams(params);

const useTickets = () => {
    const count = async (params) => {
        const status = params.status || 'default';
        const cacheKey = `${status}-${getCacheKey(params)}`;

        const cachedData = cache.get(cacheKey);
        if (cachedData) {
            return Promise.resolve(cachedData);
        }

        if (pendingRequests.has(cacheKey)) {
            return pendingRequests.get(cacheKey);
        }

        const request = api.get('/tickets/count', { params }).then(response => {
            const result = response.data;
            cache.set(cacheKey, result, 3000); // TTL = 3 segundos
            pendingRequests.delete(cacheKey);
            return result;
        }).catch(error => {
            console.error(`[ERRO] Falha na requisição para: ${cacheKey}`, error);
            pendingRequests.delete(cacheKey);
            throw error;
        });

        pendingRequests.set(cacheKey, request);
        return request;
    };

    return { count };
};

export default useTickets;
