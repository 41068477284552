import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Box,
    IconButton,
    Paper,
    FormControl
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useCallback } from "react";
import toastError from '../../errors/toastError';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import KanbanCardSelectUsersAutoComplete from "../KanbanCardSelectUsersAutoComplete";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minWidth: 450,
        maxWidth: 600,
        borderRadius: 10,
        boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)"
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2, 3),
        "& h2": {
            fontSize: "1.25rem",
            fontWeight: 600,
            margin: 0
        }
    },
    closeButton: {
        color: theme.palette.primary.contrastText,
        marginRight: -theme.spacing(1)
    },
    fieldLabel: {
        fontWeight: 500,
        marginBottom: theme.spacing(0.5),
        fontSize: "0.875rem"
    },
    dialogContent: {
        padding: theme.spacing(3),
    },
    infoText: {
        fontSize: "0.75rem",
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(0.5)
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    formLabel: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
        display: 'block',
    },
    dialogActions: {
        padding: theme.spacing(2, 3, 3),
        justifyContent: "flex-end"
    },
    helperText: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
        "& svg": {
            fontSize: "0.875rem"
        }
    },
    cancelButton: {
        fontWeight: 500,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.75, 2)
    },
    saveButton: {
        fontWeight: 500,
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.75, 2),
        boxShadow: theme.shadows[2],
        "&:hover": {
            boxShadow: theme.shadows[4]
        }
    },
    loadingButton: {
        position: "relative"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -10,
        marginLeft: -10,
        color: theme.palette.common.white
    },
    textField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: theme.shape.borderRadius,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 3px ${theme.palette.primary.light}25`
            }
        },
        "& .MuiFormHelperText-root": {
            marginTop: theme.spacing(0.5)
        }
    },
    fieldContainer: {
        marginBottom: '5px'
    },
    spinner: {
        margin: theme.spacing(3, 0)
    }
}));

const INITIAL_FORM_STATE = {
    title: "",
    description: "",
    taskName: "oportunidade",
    ownerName: 'gestor'
};

const CreateOrEditKanbanModal = ({ open, onClose, kanbanId, onSuccess }) => {
    const classes = useStyles();

    const [formData, setFormData] = useState(INITIAL_FORM_STATE);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.title.trim()) {
            newErrors.title = i18n.t("kanbanNew.validation.titleRequired");
        } else if (formData.title.trim().length < 2) {
            newErrors.title = i18n.t("kanbanNew.validation.titleLength");
        }

        if (!formData.taskName.trim()) {
            newErrors.taskName = i18n.t("kanbanNew.validation.taskNameRequired");
        }

        if (!formData.ownerName.trim()) {
            newErrors.ownerName = i18n.t("kanbanNew.validation.ownerNameRequired");
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: (name === 'taskName' || name === 'ownerName') ? value.toLowerCase() : value
        }));

        // Clear error when user types
        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: undefined }));
        }
    };

    const resetForm = () => {
        setFormData(INITIAL_FORM_STATE);
        setSelectedUsers([]);
        setErrors({});
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const fetchKanbanData = useCallback(async () => {
        if (!kanbanId || !open) return;

        try {
            setIsFetching(true);
            const { data } = await api.get(`/kanban/${kanbanId}`);

            setFormData({
                title: data.title || "",
                description: data.description || "",
                taskName: data.taskName || "oportunidade",
                ownerName: data.ownerName || "gestor",
                followers: data.followers || []
            });

            if (data.followers) {
                setSelectedUsers(data.followers);
            }
        } catch (error) {
            console.error("Erro ao carregar dados do Kanban:", error);
            toastError(i18n.t("kanbanNew.errors.fetchFailed"));
        } finally {
            setIsFetching(false);
        }
    }, [kanbanId, open]);

    const handleChangeAutoFollowers = (newValues) => {
        setSelectedUsers(newValues);
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        try {
            setIsLoading(true);

            const payload = {
                title: formData.title.trim(),
                description: formData.description.trim(),
                taskName: formData.taskName.trim(),
                ownerName: formData.ownerName.trim(),
                followers: selectedUsers.map((user) => user.id)
            };

            const apiMethod = kanbanId ? api.put : api.post;
            const apiUrl = kanbanId ? `/kanban/${kanbanId}` : "/kanban/";

            const { data } = await apiMethod(apiUrl, payload);

            toast.success(
                kanbanId
                    ? "Quadro editado com sucesso!"
                    : "Quadro criado com sucesso!"
            );

            if (onSuccess) {
                onSuccess(data);
            }

            handleClose();
        } catch (error) {
            console.error("Erro ao salvar o Kanban:", error);
            toastError("Ocorreu um erro na ação, tente novamente.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchKanbanData();
        }
    }, [open, fetchKanbanData]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="kanban-dialog-title"
        >
            <DialogTitle id="kanban-dialog-title" className={classes.dialogTitle} disableTypography>
                <Typography variant="h2">
                    {kanbanId
                        ? i18n.t("kanbanNew.modal.title.edit")
                        : i18n.t("kanbanNew.modal.title.create")
                    }
                </Typography>
                <IconButton
                    className={classes.closeButton}
                    onClick={handleClose}
                    aria-label="fechar"
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent} dividers>
                {isFetching ? (
                    <Box display="flex" justifyContent="center" className={classes.spinner}>
                        <CircularProgress size={40} />
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Typography variant="subtitle2" className={classes.fieldLabel}>
                                {i18n.t("kanbanNew.modal.field.title")}*
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder={i18n.t("kanbanNew.modal.placeholder.title")}
                                error={!!errors.title}
                                helperText={errors.title}
                                size="small"
                                disabled={isLoading}
                                className={classes.textField}
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Typography variant="subtitle2" className={classes.fieldLabel}>
                                {i18n.t("kanbanNew.modal.field.taskName")}*
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="taskName"
                                value={formData.taskName}
                                onChange={handleInputChange}
                                placeholder={i18n.t("kanbanNew.modal.help.taskName")}
                                error={!!errors.taskName}
                                helperText={
                                    <span className={classes.helperText}>
                                        {errors.taskName || (
                                            <>
                                                <HelpOutlineIcon />
                                                {i18n.t("kanbanNew.modal.help.taskName")}
                                            </>
                                        )}
                                    </span>
                                }
                                size="small"
                                disabled={isLoading}
                                className={classes.textField}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Typography variant="subtitle2" className={classes.fieldLabel}>
                                {i18n.t("kanbanNew.modal.field.ownerName")}*
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="ownerName"
                                value={formData.ownerName}
                                onChange={handleInputChange}
                                placeholder={i18n.t("kanbanNew.modal.help.ownerName")}
                                error={!!errors.ownerName}
                                helperText={
                                    <span className={classes.helperText}>
                                        {errors.ownerName || (
                                            <>
                                                <HelpOutlineIcon />
                                                {i18n.t("kanbanNew.modal.help.ownerName")}
                                            </>
                                        )}
                                    </span>
                                }
                                size="small"
                                disabled={isLoading}
                                className={classes.textField}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Typography variant="subtitle2" className={classes.fieldLabel}>
                                {i18n.t("kanbanNew.modal.field.description")}
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="description"
                                value={formData.description}
                                onChange={(e) => {
                                    // Limita o texto a 1000 caracteres
                                    if (e.target.value.length <= 500) {
                                        handleInputChange(e);
                                    }
                                }}
                                placeholder={i18n.t("kanbanNew.modal.placeholder.description")}
                                size="small"
                                disabled={isLoading}
                                multiline
                                rows={3}
                                maxRows={6}
                                helperText={`${formData?.description?.length || 0}/500`}
                                className={classes.textField}
                                error={formData?.description?.length >= 500}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <FormControl className={classes.formControl} size='small'>
                                <Typography className={classes.formLabel}>
                                    <Box>
                                        Seguidores
                                        {/*
                         <Tooltip title="Quando ativado, uma mensagem será enviada automaticamente para o contato associado ao card">
                          <IconButton
                            size="small">
                            <InfoIcon
                              fontSize="small"
                              style={{
                                width: '18px',
                                height: '18px'
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        */}
                                    </Box>
                                </Typography>
                                <KanbanCardSelectUsersAutoComplete
                                    label=""
                                    placeholder="Buscar usuários..."
                                    defaultValue={formData?.followers || []}
                                    onChange={handleChangeAutoFollowers}
                                    multiple={true}
                                />
                            </FormControl>
                        </Grid>
                        {/* Seção para atribuição de usuários poderia ser implementada aqui */}
                    </Grid>
                )}
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    color="secondary"
                    disabled={isLoading}
                    className={classes.cancelButton}
                >
                    {i18n.t("kanbanNew.modal.buttons.cancel")}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading || isFetching}
                    className={`${classes.saveButton} ${isLoading ? classes.loadingButton : ''}`}
                >
                    {kanbanId
                        ? i18n.t("kanbanNew.modal.buttons.edit")
                        : i18n.t("kanbanNew.modal.buttons.save")
                    }
                    {isLoading && (
                        <CircularProgress size={20} className={classes.buttonProgress} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOrEditKanbanModal;