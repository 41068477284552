import api from "../../services/api";

const onDragEnd = async ({ result, kanbanData, filteredData, setKanbanData, setFilteredData, setLoadingCard, setLoadingColumn, handleEditColumn, handleEditCard }) => {
    const oldData = { ...kanbanData };
    const { destination, source, draggableId, type } = result;
    if (!destination) return; // Se não houver destino válido, não fazer nada
    if (destination.droppableId === source.droppableId && destination.index === source.index) return; // Se o destino for o mesmo que a origem (mesma posição), não fazer nada
    const currentData = filteredData || kanbanData;
    if (!currentData) return;

    // Se estiver arrastando uma coluna
    if (type === 'column') {
        const columnId = draggableId.replace('column-', '');
        // ==================== Fake edition ==================== //
        const newColumnOrder = Array.from(currentData.columnOrder);
        newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, draggableId);
        const newData = { ...currentData, columnOrder: newColumnOrder, };
        setFilteredData(newData);
        // ==================== Fake edition ==================== //

        try {
            setLoadingColumn((prevLoading) => ({ ...prevLoading, [draggableId]: true }));
            const { data } = await api.put(`/column/kanban/${columnId}`, { kanbanColumnId: columnId, position: destination.index });
            console.info({ action: 'movendo coluna de posição', columnId, position: destination.index });
            // ==================== Real edition ==================== //
            handleEditColumn({ action: 'position', columnId, column: data, setLoadingColumn, setKanbanData });
            // ==================== Real edition ==================== //
        } catch (err) {
            console.error(err);
            setKanbanData(oldData);
        } finally {
            setTimeout(() => {
                setLoadingColumn((prevLoading) => ({ ...prevLoading, [draggableId]: false }));
            }, 500);
        }
        return;
    }

    // Se estiver arrastando um card
    const startColumn = currentData.columns[source.droppableId];
    const finishColumn = currentData.columns[destination.droppableId];

    // Se mover dentro da mesma coluna
    if (startColumn === finishColumn) {
        const cardId = draggableId.replace('card-', '');
        const oldData = kanbanData;

        // ==================== Fake edition ==================== //
        const newCardIds = Array.from(startColumn.cardIds);
        newCardIds.splice(source.index, 1);
        newCardIds.splice(destination.index, 0, draggableId);
        const newColumn = { ...startColumn, cardIds: newCardIds };
        const newData = { ...currentData, columns: { ...currentData.columns, [newColumn.id]: newColumn } };
        setFilteredData(newData);
        // ==================== Fake edition ==================== //


        try {
            setLoadingCard((prevLoading) => ({ ...prevLoading, [draggableId]: true }));
            const columnId = destination.droppableId.replace('column-', '');
            const { data } = await api.put(`/card/kanban/${cardId}`, { cardId, kanbanColumnId: columnId, order: destination.index });
            console.info({ action: 'movendo card de posição', cardId, columnId, order: destination.index });

            // ==================== Real edition ==================== //  
            await handleEditCard({ action: 'order', cardId, card: data, setLoadingCard, setKanbanData });
            // ==================== Real edition ==================== //

        } catch (err) {
            console.error(err);
            setKanbanData(oldData);
        } finally {
            setTimeout(() => {
                setLoadingCard((prevLoading) => ({ ...prevLoading, [draggableId]: false }));
            }, 500);
        }
        return;
    }

    // Se mover para outra coluna
    // ==================== Fake edition ==================== //
    const startCardIds = Array.from(startColumn.cardIds);
    startCardIds.splice(source.index, 1);
    const newStartColumn = { ...startColumn, cardIds: startCardIds };
    const finishCardIds = Array.from(finishColumn.cardIds);
    finishCardIds.splice(destination.index, 0, draggableId);
    const newFinishColumn = { ...finishColumn, cardIds: finishCardIds, };
    const newData = { ...currentData, columns: { ...currentData.columns, [newStartColumn.id]: newStartColumn, [newFinishColumn.id]: newFinishColumn, }, };
    setFilteredData(newData);
    // ==================== Fake edition ==================== //

    try {
        setLoadingCard((prevLoading) => ({ ...prevLoading, [draggableId]: true }));
        const cardId = draggableId.replace('card-', '');
        const columnId = destination.droppableId.replace('column-', '');
        const { data } = await api.put(`/card/kanban/${cardId}`, { cardId, kanbanColumnId: columnId, order: destination.index });
        console.info({ action: 'movendo card de coluna', cardId, columnId, order: destination.index });

        // ==================== Real edition ==================== //
        await handleEditCard({ action: 'column', cardId, card: data, setLoadingCard, setKanbanData });
        // ==================== Real edition ==================== //

    } catch (err) {
        console.error(err);
        setKanbanData(oldData);
    } finally {
        setLoadingCard((prevLoading) => ({ ...prevLoading, [draggableId]: false }));
    }

};

export default onDragEnd;