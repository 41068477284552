import React, { useContext, useEffect, useState } from 'react';

import { Box, Divider, InputAdornment, Tab, Tabs, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';

import useSettings from '../../hooks/useSettings';

import { blue, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../context/Auth/AuthContext';
import api from '../../services/api';
import ButtonWithSpinner from '../ButtonWithSpinner';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 240,
    },
    tab: {
        background: '#f2f5f3',
        borderRadius: 4,
        width: '100%',
        '& .MuiTab-wrapper': {
            color: '#128c7e',
        },
        '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
        width: '100%',
    },
    cardAvatar: {
        fontSize: '55px',
        color: grey[500],
        backgroundColor: '#ffffff',
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: '18px',
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: '14px',
    },
    alignRight: {
        textAlign: 'right',
    },
    fullWidth: {
        width: '100%',
    },
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
    selectContainerTime: {
        width: '50%',
        textAlign: 'left',
    },
}));

export default function Options(props) {
    const { settings, scheduleTypeChanged } = props;
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [userRating, setUserRating] = useState('disabled');
    const [scheduleType, setScheduleType] = useState('disabled');
    const [chatBotType, setChatBotType] = useState('text');

    const [loadingUserRating, setLoadingUserRating] = useState(false);
    const [loadingScheduleType, setLoadingScheduleType] = useState(false);

    const [UserCreation, setUserCreation] = useState('disabled');
    const [loadingUserCreation, setLoadingUserCreation] = useState(false);

    // recursos a mais
    const [CheckMsgIsGroup, setCheckMsgIsGroup] = useState('enabled');
    const [loadingCheckMsgIsGroup, setLoadingCheckMsgIsGroup] = useState(false);

    const [SendGreetingAccepted, setSendGreetingAccepted] = useState('enabled');
    const [loadingSendGreetingAccepted, setLoadingSendGreetingAccepted] = useState(false);

    const [UserRandom, setUserRandom] = useState('enabled');
    const [loadingUserRandom, setLoadingUserRandom] = useState(false);

    const [SettingsTransfTicket, setSettingsTransfTicket] = useState('enabled');
    const [loadingSettingsTransfTicket, setLoadingSettingsTransfTicket] = useState(false);

    const [AcceptCallWhatsapp, setAcceptCallWhatsapp] = useState('enabled');
    const [loadingAcceptCallWhatsapp, setLoadingAcceptCallWhatsapp] = useState(false);

    const [, setHoursCloseTicketsAuto] = useState('enabled');

    const [sendSignMessage, setSendSignMessage] = useState('enabled');
    const [loadingSendSignMessage, setLoadingSendSignMessage] = useState(false);

    const [sendGreetingMessageOneQueues, setSendGreetingMessageOneQueues] = useState('enabled');
    const [loadingSendGreetingMessageOneQueues, setLoadingSendGreetingMessageOneQueues] = useState(false);

    const [sendQueuePosition, setSendQueuePosition] = useState('enabled');
    const [loadingSendQueuePosition, setLoadingSendQueuePosition] = useState(false);

    const [sendFarewellWaitingTicket, setSendFarewellWaitingTicket] = useState('enabled');
    const [loadingSendFarewellWaitingTicket, setLoadingSendFarewellWaitingTicket] = useState(false);

    const [acceptAudioMessageContact, setAcceptAudioMessageContact] = useState('enabled');
    const [loadingAcceptAudioMessageContact, setLoadingAcceptAudioMessageContact] = useState(false);

    const [ticketWithoutQueue, setTicketWithoutQueue] = useState('enabled');
    const [loadingTicketWithoutQueue, setLoadingTicketWithoutQueue] = useState(false);

    const [sendAutoAIEmailMessage, setSendAutoAIEmailMessage] = useState('disabled');
    const [loadingSendAutoAIEmailMessage, setLoadingSendAutoAIEmailMessage] = useState(false);

    const [viewForwardedFlag, setViewForwardedFlag] = useState('disabled');
    const [loadingViewForwardedFlag, setLoadingViewForwardedFlag] = useState(false);

    const [reopenTicketByTime, setReopenTicketByTime] = useState('disabled');
    const [reopenTicketTime, setReopenTicketTime] = useState(30);
    const [loadingReopenTicketByTime, setLoadingReopenTicketByTime] = useState(false);

    const [tokenTypebot, setTokenTypebot] = useState('');
    const [loadingtokenTypebot, setLoadingTokenTypebot] = useState(false);

    const [urlTypebot, setUrlTypebot] = useState('');
    const [loadingUrlTypebot, setLoadingUrlTypebot] = useState(false);

    const [urlBotTypebot, setUrlBotTypebot] = useState('');
    const [loadingUrlBotTypebot, setLoadingUrlBotTypebot] = useState(false);

    const [urlN8N, setUrlN8N] = useState('');
    const [loadingUrlN8N, setLoadingUrlN8N] = useState(false);

    const [apiKeyN8N, setApiKeyN8N] = useState('');
    const [loadingApiKeyN8N, setLoadingApiKeyN8N] = useState(false);

    const [logoImageFileName, setLogoImageFileName] = useState('');
    const [loadingLogoImageFileName, setLoadingLogoImageFileName] = useState(false);

    const { update } = useSettings();

    const isSuper = () => {
        return user.super;
    };

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const userCreation = settings.find((s) => s.key === 'userCreation');
            if (userCreation) {
                setUserRating(userCreation.value);
            }

            const userRating = settings.find((s) => s.key === 'userRating');
            if (userRating) {
                setUserRating(userRating.value);
            }

            const scheduleType = settings.find((s) => s.key === 'scheduleType');
            if (scheduleType) {
                setScheduleType(scheduleType.value);
            }

            const chatBotType = settings.find((s) => s.key === 'chatBotType');
            if (chatBotType) {
                setChatBotType(chatBotType.value);
            }

            const CheckMsgIsGroup = settings.find((s) => s.key === 'CheckMsgIsGroup');
            if (CheckMsgIsGroup) {
                setCheckMsgIsGroup(CheckMsgIsGroup.value);
            }

            const SendGreetingAccepted = settings.find((s) => s.key === 'sendGreetingAccepted');
            if (SendGreetingAccepted) {
                setSendGreetingAccepted(SendGreetingAccepted.value);
            }

            const UserRandom = settings.find((s) => s.key === 'userRandom');
            if (UserRandom) {
                setUserRandom(UserRandom.value);
            }

            const SettingsTransfTicket = settings.find((s) => s.key === 'sendMsgTransfTicket');
            if (SettingsTransfTicket) {
                setSettingsTransfTicket(SettingsTransfTicket.value);
            }

            const AcceptCallWhatsapp = settings.find((s) => s.key === 'acceptCallWhatsapp');
            if (AcceptCallWhatsapp) {
                setAcceptCallWhatsapp(AcceptCallWhatsapp.value);
            }

            const HoursCloseTicketsAuto = settings.find((s) => s.key === 'hoursCloseTicketsAuto');
            if (HoursCloseTicketsAuto) {
                setHoursCloseTicketsAuto(HoursCloseTicketsAuto.value);
            }

            const sendSignMessage = settings.find((s) => s.key === 'sendSignMessage');
            if (sendSignMessage) {
                setSendSignMessage(sendSignMessage.value);
            }

            const sendGreetingMessageOneQueues = settings.find((s) => s.key === 'sendGreetingMessageOneQueues');
            if (sendGreetingMessageOneQueues) {
                setSendGreetingMessageOneQueues(sendGreetingMessageOneQueues.value);
            }

            const sendQueuePosition = settings.find((s) => s.key === 'sendQueuePosition');
            if (sendQueuePosition) {
                setSendQueuePosition(sendQueuePosition.value);
            }

            const sendFarewellWaitingTicket = settings.find((s) => s.key === 'sendFarewellWaitingTicket');
            if (sendFarewellWaitingTicket) {
                setSendFarewellWaitingTicket(sendFarewellWaitingTicket.value);
            }

            const acceptAudioMessageContact = settings.find((s) => s.key === 'acceptAudioMessageContact');
            if (acceptAudioMessageContact) {
                setAcceptAudioMessageContact(acceptAudioMessageContact.value);
            }

            const ticketWithoutQueue = settings.find((s) => s.key === 'ticketWithoutQueue');
            if (ticketWithoutQueue) {
                setTicketWithoutQueue(ticketWithoutQueue.value);
            }

            const sendAutoAIEmailMessage = settings.find((s) => s.key === 'sendAutoAIEmailMessage');
            if (sendAutoAIEmailMessage) {
                setSendAutoAIEmailMessage(sendAutoAIEmailMessage.value);
            }

            const viewForwardedFlag = settings.find((s) => s.key === 'viewForwardedFlag');
            if (viewForwardedFlag) {
                setViewForwardedFlag(viewForwardedFlag.value);
            }
            const tokenTypebot = settings.find((s) => s.key === 'tokenTypebot');
            if (tokenTypebot) {
                setTokenTypebot(tokenTypebot.value);
            }

            const urlTypebot = settings.find((s) => s.key === 'urlTypebot');
            if (urlTypebot) {
                setUrlTypebot(urlTypebot.value);
            }

            const urlBotTypebot = settings.find((s) => s.key === 'urlBotTypebot');
            if (urlBotTypebot) {
                setUrlBotTypebot(urlBotTypebot.value);
            }

            const urlN8N = settings.find((s) => s.key === 'urlN8N');
            if (urlN8N) {
                setUrlN8N(urlN8N.value);
            }

            const apiKeyN8N = settings.find((s) => s.key === 'apiKeyN8N');
            if (apiKeyN8N) {
                setApiKeyN8N(apiKeyN8N.value);
            }

            const logoImageFileName = settings.find((s) => s.key === 'logoImageFileName');
            if (logoImageFileName) {
                setLogoImageFileName(logoImageFileName.value);
            }

            const findReopenTicketByTime = settings.find((s) => s.key === 'reopenTicketByTime');
            if (findReopenTicketByTime) {
                setReopenTicketByTime(findReopenTicketByTime.value);
            }

            const findReopenTicketTime = settings.find((s) => s.key === 'reopenTicketTime');
            if (findReopenTicketTime) {
                setReopenTicketTime(+findReopenTicketTime.value || 30);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    async function handleChangeUserCreation(value) {
        setUserCreation(value);
        setLoadingUserCreation(true);
        await update({
            key: 'userCreation',
            value,
        });
        setLoadingUserCreation(false);
    }

    async function handleChangeUserRating(value) {
        setUserRating(value);
        setLoadingUserRating(true);
        await update({
            key: 'userRating',
            value,
        });
        setLoadingUserRating(false);
    }

    async function handleScheduleType(value) {
        setScheduleType(value);
        setLoadingScheduleType(true);
        await update({
            key: 'scheduleType',
            value,
        });
        setLoadingScheduleType(false);
        if (typeof scheduleTypeChanged === 'function') {
            scheduleTypeChanged(value);
        }
    }

    async function handleChatBotType(value) {
        setChatBotType(value);
        await update({
            key: 'chatBotType',
            value,
        });
        if (typeof scheduleTypeChanged === 'function') {
            setChatBotType(value);
        }
    }

    async function handleCheckMsgIsGroup(value) {
        setCheckMsgIsGroup(value);
        setLoadingCheckMsgIsGroup(true);
        await update({
            key: 'CheckMsgIsGroup',
            value,
        });
        setLoadingCheckMsgIsGroup(false);
    }

    async function handleSendGreetingAccepted(value) {
        setSendGreetingAccepted(value);
        setLoadingSendGreetingAccepted(true);
        await update({
            key: 'sendGreetingAccepted',
            value,
        });
        setLoadingSendGreetingAccepted(false);
    }

    async function handleUserRandom(value) {
        setUserRandom(value);
        setLoadingUserRandom(true);
        await update({
            key: 'userRandom',
            value,
        });
        setLoadingUserRandom(false);
    }

    async function handleSettingsTransfTicket(value) {
        setSettingsTransfTicket(value);
        setLoadingSettingsTransfTicket(true);
        await update({
            key: 'sendMsgTransfTicket',
            value,
        });
        setLoadingSettingsTransfTicket(false);
    }

    async function handleAcceptCallWhatsapp(value) {
        setAcceptCallWhatsapp(value);
        setLoadingAcceptCallWhatsapp(true);
        await update({
            key: 'acceptCallWhatsapp',
            value,
        });
        setLoadingAcceptCallWhatsapp(false);
    }

    async function handleSendSignMessage(value) {
        setSendSignMessage(value);
        setLoadingSendSignMessage(true);
        await update({
            key: 'sendSignMessage',
            value,
        });
        setLoadingSendSignMessage(false);
    }

    async function handleSendGreetingMessageOneQueues(value) {
        setSendGreetingMessageOneQueues(value);
        setLoadingSendGreetingMessageOneQueues(true);
        await update({
            key: 'sendGreetingMessageOneQueues',
            value,
        });
        setLoadingSendGreetingMessageOneQueues(false);
    }

    async function handleSendQueuePosition(value) {
        setSendQueuePosition(value);
        setLoadingSendQueuePosition(true);
        await update({
            key: 'sendQueuePosition',
            value,
        });
        setLoadingSendQueuePosition(false);
    }

    async function handleSendFarewellWaitingTicket(value) {
        setSendFarewellWaitingTicket(value);
        setLoadingSendFarewellWaitingTicket(true);
        await update({
            key: 'sendFarewellWaitingTicket',
            value,
        });
        setLoadingSendFarewellWaitingTicket(false);
    }

    async function handleAcceptAudioMessageContact(value) {
        setAcceptAudioMessageContact(value);
        setLoadingAcceptAudioMessageContact(true);
        await update({
            key: 'acceptAudioMessageContact',
            value,
        });
        setLoadingAcceptAudioMessageContact(false);
    }

    async function handleTicketWithoutQueue(value) {
        setTicketWithoutQueue(value);
        setLoadingTicketWithoutQueue(true);
        await update({
            key: 'ticketWithoutQueue',
            value,
        });
        setLoadingTicketWithoutQueue(false);
    }

    async function handleSendAutoAIEmailMessage(value) {
        setSendAutoAIEmailMessage(value);
        setLoadingSendAutoAIEmailMessage(true);
        await update({
            key: 'sendAutoAIEmailMessage',
            value,
        });
        setLoadingSendAutoAIEmailMessage(false);
    }

    async function handleViewForwardedFlag(value) {
        setViewForwardedFlag(value);
        setLoadingViewForwardedFlag(true);
        await update({
            key: 'viewForwardedFlag',
            value,
        });
        setLoadingViewForwardedFlag(false);
    }


    async function handleReopenTicketByTime(value) {
        setReopenTicketByTime(value);
        setLoadingReopenTicketByTime(true);
        await update({
            key: 'reopenTicketByTime',
            value,
        });
        // Caso reative ou ative a primeira vez, salvar o valor automaticamente de tempo inicial como 30 minutos
        if (value === 'enabled') {
            await update({
                key: 'reopenTicketTime',
                value: reopenTicketTime,
            });
        }
        setLoadingReopenTicketByTime(false);
    }

    async function handleReopenTicketTime(value) {
        setReopenTicketTime(value);
        setLoadingReopenTicketByTime(true);
        await update({
            key: 'reopenTicketTime',
            value,
        });
        setLoadingReopenTicketByTime(false);
    }

    async function handleChangeTokenTypebot(value) {
        setTokenTypebot(value);
        setLoadingTokenTypebot(true);
        await update({
            key: 'tokenTypebot',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingTokenTypebot(false);
    }

    async function handleChangeUrlTypebot(value) {
        setUrlTypebot(value);
        setLoadingUrlTypebot(true);
        await update({
            key: 'urlTypebot',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingUrlTypebot(false);
    }

    async function handleChangeUrlBotTypebot(value) {
        setUrlBotTypebot(value);
        setLoadingUrlBotTypebot(true);
        await update({
            key: 'urlBotTypebot',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingUrlBotTypebot(false);
    }

    async function handleChangeUrlN8N(value) {
        setUrlN8N(value);
        setLoadingUrlN8N(true);
        await update({
            key: 'urlN8N',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingUrlN8N(false);
    }

    async function handleChangeApiKeyN8N(value) {
        setApiKeyN8N(value);
        setLoadingApiKeyN8N(true);
        await update({
            key: 'apiKeyN8N',
            value,
        });
        toast.success('Operação atualizada com sucesso.');
        setLoadingApiKeyN8N(false);
    }

    async function handleChangeLogoImageFileName({ target }) {
        setLoadingLogoImageFileName(true);
        const formData = new FormData();
        formData.append('file', target.files[0]);
        const response = await api.post(`/settings/logoImageFileName/media-upload`, formData);
        setLogoImageFileName(response.data.value);
        setLoadingLogoImageFileName(false);
    }

    return (
        <>
            <Grid spacing={3} container>
                {/* CRIAÇÃO DE COMPANY/USERS */}
                {isSuper() ? (
                    <Grid xs={12} sm={6} md={4} item>
                        <FormControl className={classes.selectContainer}>
                            <InputLabel id="UserCreation-label">Criação de Empresa/Usuário</InputLabel>
                            <Select
                                labelId="UserCreation-label"
                                value={UserCreation}
                                onChange={async (e) => {
                                    handleChangeUserCreation(e.target.value);
                                }}
                            >
                                <MenuItem value={'disabled'}>Desabilitadas</MenuItem>
                                <MenuItem value={'enabled'}>Habilitadas</MenuItem>
                            </Select>
                            <FormHelperText>{loadingUserCreation && 'Atualizando...'}</FormHelperText>
                        </FormControl>
                    </Grid>
                ) : null}

                {/* AVALIAÇÕES */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="ratings-label">Avaliações</InputLabel>
                        <Select
                            labelId="ratings-label"
                            value={userRating}
                            onChange={async (e) => {
                                handleChangeUserRating(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitadas</MenuItem>
                            <MenuItem value={'enabled'}>Habilitadas</MenuItem>
                        </Select>
                        <FormHelperText>{loadingUserRating && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* AGENDAMENTO DE EXPEDIENTE */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="schedule-type-label">Agendamento de Expediente</InputLabel>
                        <Select
                            labelId="schedule-type-label"
                            value={scheduleType}
                            onChange={async (e) => {
                                handleScheduleType(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'queue'}>Gerenciamento Por Fila</MenuItem>
                            <MenuItem value={'company'}>Gerenciamento Por Empresa</MenuItem>
                        </Select>
                        <FormHelperText>{loadingScheduleType && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* ENVIAR SAUDAÇÃO AO ACEITAR O TICKET */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendGreetingAccepted-label">Enviar Saudação ao Aceitar o Ticket</InputLabel>
                        <Select
                            labelId="sendGreetingAccepted-label"
                            value={SendGreetingAccepted}
                            onChange={async (e) => {
                                handleSendGreetingAccepted(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSendGreetingAccepted && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* ESCOLHER OPERADOR ALEATORIO */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="userRandom-label">Escolher Operador Aleatório Ao Escolher uma Fila</InputLabel>
                        <Select
                            labelId="userRandom-label"
                            value={UserRandom}
                            onChange={async (e) => {
                                handleUserRandom(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingUserRandom && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* ENVIAR MENSAGEM DE TRANSFERENCIA DE SETOR/ATENDENTE */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendMsgTransfTicket-label">
                            Enviar Mensagem de Transferência de Fila/Atendente
                        </InputLabel>
                        <Select
                            labelId="sendMsgTransfTicket-label"
                            value={SettingsTransfTicket}
                            onChange={async (e) => {
                                handleSettingsTransfTicket(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSettingsTransfTicket && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* IGNORAR MENSAGEM DE GRUPOS */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="CheckMsgIsGroup-label">Ignorar Mensagem de Grupo</InputLabel>
                        <Select
                            labelId="CheckMsgIsGroup-label"
                            value={CheckMsgIsGroup}
                            onChange={async (e) => {
                                handleCheckMsgIsGroup(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingCheckMsgIsGroup && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* TIPO DO BOT */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="schedule-type-label">Tipo do Bot</InputLabel>
                        <Select
                            labelId="schedule-type-label"
                            value={chatBotType}
                            onChange={async (e) => {
                                handleChatBotType(e.target.value);
                            }}
                        >
                            <MenuItem value={'text'}>Texto</MenuItem>
                            <MenuItem value={'button'}>Botões</MenuItem>
                            <MenuItem value={'list'}>Lista</MenuItem>
                        </Select>
                        <FormHelperText>{loadingScheduleType && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* AVISO SOBRE LIGAÇÃO DO WHATSAPP */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="acceptCallWhatsapp-label">Aceita Ligação no Whatsapp?</InputLabel>
                        <Select
                            labelId="acceptCallWhatsapp-label"
                            value={AcceptCallWhatsapp}
                            onChange={async (e) => {
                                handleAcceptCallWhatsapp(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingAcceptCallWhatsapp && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* HABILITAR PARA O ATENDENTE RETIRAR O ASSINATURA */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendSignMessage-label">Permite Atendente Escolher ENVIAR Assinatura</InputLabel>
                        <Select
                            labelId="sendSignMessage-label"
                            value={sendSignMessage}
                            onChange={async (e) => {
                                handleSendSignMessage(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSendSignMessage && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* ENVIAR SAUDAÇÃO QUANDO HOUVER SOMENTE 1 FILA */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendGreetingMessageOneQueues-label">
                            Enviar Saudação Quando Houver Somente 1 Fila
                        </InputLabel>
                        <Select
                            labelId="sendGreetingMessageOneQueues-label"
                            value={sendGreetingMessageOneQueues}
                            onChange={async (e) => {
                                handleSendGreetingMessageOneQueues(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSendGreetingMessageOneQueues && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* ENVIAR MENSAGEM COM A POSIÇÃO DA FILA */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendQueuePosition-label">
                            Enviar Mensagem com o Número da Posição da Fila
                        </InputLabel>
                        <Select
                            labelId="sendQueuePosition-label"
                            value={sendQueuePosition}
                            onChange={async (e) => {
                                handleSendQueuePosition(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSendQueuePosition && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                {/* ENVIAR MENSAGEM DE DESPEDIDA NO AGUARDANDO */}
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendFarewellWaitingTicket-label">
                            Enviar Mensagem de Despedida na Espera
                        </InputLabel>
                        <Select
                            labelId="sendFarewellWaitingTicket-label"
                            value={sendFarewellWaitingTicket}
                            onChange={async (e) => {
                                handleSendFarewellWaitingTicket(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSendFarewellWaitingTicket && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="acceptAudioMessageContact-label">
                            Aceita Receber Audio de Todos Contatos?
                        </InputLabel>
                        <Select
                            labelId="acceptAudioMessageContact-label"
                            value={acceptAudioMessageContact}
                            onChange={async (e) => {
                                handleAcceptAudioMessageContact(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingAcceptAudioMessageContact && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="ticketWithoutQueue-label">Manipular ticket sem atribuir fila?</InputLabel>
                        <Select
                            labelId="ticketWithoutQueue-label"
                            value={ticketWithoutQueue}
                            onChange={async (e) => {
                                handleTicketWithoutQueue(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingTicketWithoutQueue && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendAutoAIEmailMessage-label">Envio automático de msg novos emails?</InputLabel>
                        <Select
                            style={{ paddingTop: '9px' }}
                            labelId="sendAutoAIEmailMessage-label"
                            value={sendAutoAIEmailMessage}
                            onChange={async (e) => {
                                handleSendAutoAIEmailMessage(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingSendAutoAIEmailMessage && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item> <InputLabel
                    id="reopenTicketByTime-label"
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    Tempo mínimo para iniciar novo atendimento?
                    {' '}
                    <Tooltip title="Ao ativar esta opção, atendimentos finalizados serão automaticamente reabertos na mesma fila e atribuídos ao mesmo atendente caso o cliente realize uma nova interação dentro do tempo definido.">
                        <HelpIcon />
                    </Tooltip>
                </InputLabel>
                    <FormControl className={classes.selectContainerTime}>
                        <Select
                            labelId="reopenTicketByTime-label"
                            value={reopenTicketByTime}
                            onChange={async (e) => {
                                handleReopenTicketByTime(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.selectContainerTime}>
                        <TextField
                            disabled={reopenTicketByTime === 'disabled'}
                            type="number"
                            id="reopenTicketTime"
                            placeholder="Minutos"
                            value={reopenTicketTime}
                            onChange={e => handleReopenTicketTime(e.target.value)}
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: <InputAdornment position="end">Minutos</InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <FormHelperText>{loadingReopenTicketByTime && 'Atualizando...'}</FormHelperText>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="viewForwardedFlag-label">Exibir "Encaminhada" nas mensagem encaminhadas pelo sistema?</InputLabel>
                        <Select
                            style={{ paddingTop: '9px' }}
                            labelId="viewForwardedFlag-label"
                            value={viewForwardedFlag}
                            onChange={async (e) => {
                                handleViewForwardedFlag(e.target.value);
                            }}
                        >
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'enabled'}>Habilitado</MenuItem>
                        </Select>
                        <FormHelperText>{loadingViewForwardedFlag && 'Atualizando...'}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px',
                        }}
                    >
                        <div>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                style={{ display: 'none' }}
                                type="file"
                                onChange={handleChangeLogoImageFileName}
                            />
                            <label htmlFor="contained-button-file">
                                <ButtonWithSpinner
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    disabled={loadingLogoImageFileName}
                                    loading={loadingLogoImageFileName}
                                >
                                    Upload Logotipo
                                </ButtonWithSpinner>
                            </label>
                        </div>
                        <div
                            style={{
                                width: '200px',
                            }}
                        >
                            <img
                                style={{ width: '100%' }}
                                src={`${process.env.REACT_APP_BACKEND_URL}/public/company${user.companyId}/${logoImageFileName}`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://clipground.com/images/placeholder-logo-2.png';
                                }}
                            />
                        </div>
                    </div>
                </Grid>

                {/* Horas para fechar ticket automaticamente */}
                {/* <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="HoursCloseTicketsAuto-type-label">
              Fechar tickets automaticamente após
            </InputLabel>
            <Select
              labelId="HoursCloseTicketsAuto-type-label"
              value={HoursCloseTicketsAuto}
              onChange={async (e) => {
                handleHoursCloseTicketsAuto(e.target.value);
              }}
            >
              <MenuItem value={1}>1 Hora</MenuItem>
              <MenuItem value={2}>2 Horas</MenuItem>
              <MenuItem value={6}>6 Horas</MenuItem>
              <MenuItem value={12}>12 Horas</MenuItem>
              <MenuItem value={24}>24 Horas</MenuItem>
              <MenuItem value={36}>36 Horas</MenuItem>
              <MenuItem value={48}>48 Horas</MenuItem>
              <MenuItem value={9999999999}>Nunca</MenuItem>
            </Select>
            <FormHelperText>
              {loadingHoursCloseTicketsAuto && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid> */}

                <Grid spacing={3} container>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="on"
                        variant="scrollable"
                        className={classes.tab}
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Tab label="INTEGRAÇÕES" />
                    </Tabs>
                </Grid>

                {/*-----------------TypeBot-----------------*/}
                <Grid spacing={3} container style={{ marginBottom: 10 }}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="on"
                        variant="scrollable"
                        className={classes.tab}
                    >
                        <Tab label="TYPEBOT" />
                    </Tabs>
                    <Grid xs={12} sm={12} md={4} item>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="urltypebot"
                                name="urltypebot"
                                margin="dense"
                                label="Url Typebot"
                                variant="outlined"
                                value={urlTypebot}
                                onChange={async (e) => {
                                    handleChangeUrlTypebot(e.target.value);
                                }}
                            />
                            <FormHelperText>{loadingUrlTypebot && 'Atualizando...'}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} item>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="urlBotTypebot"
                                name="urlBotTypebot"
                                margin="dense"
                                label="Url Bot Typebot"
                                variant="outlined"
                                value={urlBotTypebot}
                                onChange={async (e) => {
                                    handleChangeUrlBotTypebot(e.target.value);
                                }}
                            />
                            <FormHelperText>{loadingUrlBotTypebot && 'Atualizando...'}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} item>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="tokentypebot"
                                name="tokentypebot"
                                margin="dense"
                                label="Token Typebot"
                                variant="outlined"
                                value={tokenTypebot}
                                onChange={async (e) => {
                                    handleChangeTokenTypebot(e.target.value);
                                }}
                            />
                            <FormHelperText>{loadingtokenTypebot && 'Atualizando...'}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                {/*-----------------N8N-----------------*/}
                <Grid spacing={3} container style={{ marginBottom: 10 }}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="on"
                        variant="scrollable"
                        className={classes.tab}
                    >
                        <Tab label="N8N" />
                    </Tabs>
                    <Grid xs={12} sm={6} md={6} item>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="urlN8N"
                                name="urlN8N"
                                margin="dense"
                                label="Url N8N"
                                variant="outlined"
                                value={urlN8N}
                                onChange={async (e) => {
                                    handleChangeUrlN8N(e.target.value);
                                }}
                            />
                            <FormHelperText>{loadingUrlN8N && 'Atualizando...'}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={6} item>
                        <FormControl className={classes.selectContainer}>
                            <TextField
                                id="apiKeyN8N"
                                name="apiKeyN8N"
                                margin="dense"
                                label="API KEY N8N"
                                variant="outlined"
                                value={apiKeyN8N}
                                onChange={async (e) => {
                                    handleChangeApiKeyN8N(e.target.value);
                                }}
                            />
                            <FormHelperText>{loadingApiKeyN8N && 'Atualizando...'}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
