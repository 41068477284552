import React, { createContext, useContext, useEffect, useState } from "react";
import mitt from "mitt";

const EventEmitterContext = createContext(null);

export const EventProvider = ({ children }) => {
    const [emitter] = useState(() => {
        const instance = mitt();

        const originalEmit = instance.emit;
        instance.emit = (type, event) => {
            console.log(`%c[Event Emitted] %c${type}`, "color: white; background:rgb(204, 0, 0); padding: 4px; border-radius: 4px;", "color: #222; font-weight: bold;", event);
            originalEmit.call(instance, type, event);
        };

        return instance;
    });

    return (
        <EventEmitterContext.Provider value={emitter}>
            {children}
        </EventEmitterContext.Provider>
    );
};

export const useEventHook = () => {
    const emitter = useContext(EventEmitterContext);
    if (!emitter) {
        throw new Error("useEvent must be used within an EventProvider");
    }
    return emitter;
};
