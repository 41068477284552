import React, { useState } from 'react';
import {
    IconButton,
    Popover,
    Button,
    Typography,
    Box,
    makeStyles
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

// Criação de estilos usando makeStyles (abordagem v4)
const useStyles = makeStyles((theme) => ({
    popoverPaper: {
        width: 360,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[3],
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    message: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    button: {
        width: '45%',
    }
}));

const DeleteItemButton = ({ itemId, handleDeleteItem }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleConfirmDelete = () => {
        handleDeleteItem(itemId);
        handleClose();
    };

    return (
        <>
            <IconButton size="small" onClick={handleClick}>
                <Delete fontSize="small" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    classes: {
                        root: classes.popoverPaper
                    }
                }}
            >
                <Typography variant="h6" className={classes.title}>
                    <strong>Atenção!</strong>
                </Typography>
                <Typography className={classes.message}>
                    Você está prestes a excluir este item. Esta ação <strong>não pode ser desfeita</strong>.
                    <br />
                    Tem certeza de que deseja continuar?
                </Typography>
                <Box className={classes.buttonsContainer}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="small"
                        className={classes.button}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                    >
                        Confirmar
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default DeleteItemButton;