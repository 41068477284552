import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField,
    Grid,
    Typography
} from '@material-ui/core';
import { ColorPicker } from "material-ui-color";
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// Estilos usando makeStyles
const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        fontWeight: 'bold',
        padding: theme.spacing(3),
    },
    dialogContent: {
        padding: theme.spacing(3),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    buttonWrapper: {
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    colorDisplay: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    colorPreview: {
        width: '36px',
        height: '36px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        marginRight: theme.spacing(2),
    },
    colorValue: {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary,
    },
    dialogActions: {
        padding: theme.spacing(3),
        justifyContent: 'space-between',
    },
}));

const KanbanEditOrCreateColumnModal = ({ open, onClose, columnId, onConfirm, initialColor = '#3287c9' }) => {
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [title, setTitle] = useState('');
    const [error, setError] = useState(false);
    const [color, setColor] = useState(initialColor);

    useEffect(() => {
        if (!open && !columnId) return;
        (async () => {
            try {
                const { data } = await api.get(`/column/kanban/${columnId.replace('column-', '')}`);
                console.info({ KanbanEditOrCreateColumnModal: data });
                setTitle(data.title);
                setColor(data.color);
            } catch (error) {
                console.error(error)
            }
        })()
    }, [columnId]);

    const handleClose = () => {
        setTitle('');
        setError(false);
        setColor(initialColor);
        onClose();
    };

    const handleConfirm = async () => {
        if (!title.trim()) {
            setError(true);
            return;
        }

        setIsSubmitting(true);
        try {
            await onConfirm({ title, color, columnId });
            handleClose();
        } catch (error) {
            console.error('Erro ao criar coluna:', error);
            // Aqui você poderia adicionar um estado para exibir o erro ao usuário
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleColorChange = (args) => {
        setColor(`#${args.hex} `);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="kanban-new-column-dialog"
            style={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "400px",
                    },
                },
            }}
        >
            <DialogTitle id="kanban-new-column-dialog" className={classes.dialogTitle}>
                <Typography variant="h6">{columnId ? "Editar coluna" : "Criar Nova Coluna"}</Typography>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined"
                            autoFocus
                            fullWidth
                            label="Título da coluna"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setError(false);
                            }}
                            error={error}
                            helperText={error ? 'O título é obrigatório' : ''}
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            label="Cor da coluna"
                            value={color}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <ColorPicker
                                        value={color}
                                        onChange={handleColorChange}
                                        hideTextfield
                                    />
                                ),
                            }}
                            className={classes.textField}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} color="default" variant="outlined">
                    Cancelar
                </Button>
                <div className={classes.buttonWrapper}>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting || !title.trim()}
                    >
                        {isSubmitting ?
                            columnId ?
                                'Editando...' :
                                'Criando...' :
                            columnId ?
                                'Editar' :
                                'Criar'
                        }
                    </Button>
                    {isSubmitting && (
                        <CircularProgress size={24} className={classes.progress} />
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default KanbanEditOrCreateColumnModal;