import { useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import useIsTabFocused from '../useIsTabFocused';

const useTicketsList = ({
    searchParam,
    tags,
    users,
    pageNumber,
    status,
    date,
    updatedAt,
    showAll,
    queueIds,
    withUnreadMessages,
}) => {

    const isTabFocused = useIsTabFocused();
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!isTabFocused) return;

        const fetchTickets = async () => {
            try {
                setLoading(true);
                const { data } = await api.get('/tickets', {
                    params: {
                        searchParam,
                        pageNumber,
                        tags,
                        users,
                        status,
                        date,
                        updatedAt,
                        showAll,
                        queueIds,
                        withUnreadMessages,
                    },
                });
                setCount(data?.count || 0)
                setTickets(data.tickets);
                setHasMore(data.hasMore);
            } catch (err) {
                toastError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTickets();
        const intervalId = setInterval(() => {
            fetchTickets();
        }, 60000);
        return () => clearInterval(intervalId);

    }, [searchParam, tags, users, pageNumber, status, date, updatedAt, showAll, queueIds, withUnreadMessages, isTabFocused]);

    return { tickets, loading, hasMore, count };
};

export default useTicketsList;
