import React, { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import api from '../../services/api';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        width: 250,
        height: 200,
        borderRadius: 8,
        overflow: 'hidden',
    },
    messageMedia: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 8,
        cursor: 'pointer',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: 0.8,
        },
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
    modalContent: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalImage: {
        maxWidth: '100vw',
        maxHeight: '100vh',
        objectFit: 'contain',
    },
    controlBar: {
        position: 'absolute',
        top: 10,
        right: 10,
        display: 'flex',
        gap: 10,
    },
    controlButton: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        padding: 10,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
    },
}));

const ModalImageCors = ({ imageUrl }) => {
    const classes = useStyles();
    const [tempUrl, setTempUrl] = useState('');
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchTempImage = useCallback(async () => {
        if (!imageUrl) return;

        try {
            const { data } = await api.post('/helpers/linkToTempFile/', { imageUrl });
            const fullUrl = `${process.env.REACT_APP_BACKEND_URL}/${data.tempUrl}`;
            setTempUrl(fullUrl);
        } catch (err) {
            console.error('Image fetch error:', err);
            setError(err);
        }
    }, [imageUrl]);

    useEffect(() => {
        fetchTempImage();
    }, [fetchTempImage]);

    const downloadImage = useCallback(async () => {
        try {
            const response = await axios({
                url: tempUrl,
                method: 'GET',
                responseType: 'blob',
            });

            const filename = tempUrl.split('/').pop();
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            console.error('Download error:', err);
        }
    }, [tempUrl]);

    if (error) {
        return <div style={{ color: 'red', textAlign: 'center' }}>Error loading image</div>;
    }

    if (!imageUrl) {
        return null;
    }

    return (
        <>
            <div className={classes.container}>
                <img
                    src={tempUrl || imageUrl}
                    alt="image"
                    className={classes.messageMedia}
                    onClick={() => setIsModalOpen(true)}
                />
            </div>

            {isModalOpen && (
                <div className={classes.modalOverlay} onClick={() => setIsModalOpen(false)}>
                    <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                        <div className={classes.controlBar}>
                            <button className={classes.controlButton} onClick={downloadImage}>
                                <GetAppIcon fontSize="small" /> Download
                            </button>
                            <button className={classes.controlButton} onClick={() => setIsModalOpen(false)}>
                                <CloseIcon fontSize="small" /> Fechar
                            </button>
                        </div>
                        <img src={tempUrl || imageUrl} alt="Full size" className={classes.modalImage} />
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(ModalImageCors);
