import React, { useState } from 'react';
import {
    makeStyles,
    Avatar,
    Box,
    Popover,
    Typography
} from '@material-ui/core';

const profiles = {
    admin: "Administrador",
    user: "Usuário",
    supervisor: "Supervisor",
};
// Estilos para o componente
const useStyles = makeStyles((theme) => ({
    avatarGroup: {
        display: 'flex',
        '& > *:not(:first-child)': {
            marginLeft: -theme.spacing(1),
        },
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '0.9rem',
        fontWeight: 500,
        border: `2px solid ${theme.palette.background.paper}`,
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.1)',
            zIndex: 1,
            boxShadow: '0 3px 8px rgba(0,0,0,0.15)'
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'none',
        padding: 0,
        overflow: 'hidden',
        width: 300,
        borderRadius: 4,
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
    },
    headerSection: {
        position: 'relative',
        padding: theme.spacing(2),
        backgroundColor: '#1976d2', // Azul similar ao da imagem
        color: '#fff',
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    headerAvatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        fontSize: '1.5rem',
        marginRight: theme.spacing(2),
        border: '2px solid rgba(255, 255, 255, 0.9)',
    },
    userDetails: {
        flex: 1,
    },
    userName: {
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.2,
    },
    userRole: {
        fontSize: '0.85rem',
        opacity: 0.9,
        marginTop: 2,
    },
    emailSection: {
        padding: theme.spacing(2),
        backgroundColor: theme.mode === "dark" ? '#242424' : '#FFF',
        color: theme.mode === "dark" ? '#fff' : '#000000',
        fontSize: '0.85rem',
        opacity: 0.9
    }
}));


const getAvatarColor = (name) => {
    const colors = [
        '#2196F3',
        '#4CAF50',
        '#F44336',
        '#FF9800',
        '#9C27B0',
        '#00BCD4',
        '#795548',
        '#607D8B',
    ];


    const hash = name
        .split('')
        .reduce((sum, char) => sum + char.charCodeAt(0), 0);

    return colors[hash % colors.length];
};

// Função para pegar as iniciais do nome
const getInitials = (name) => {
    if (!name) return '?';

    const parts = name.split(' ');
    if (parts.length === 1) return name.substring(0, 2).toUpperCase();

    return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
};

// Componente individual de avatar com popover
const UserAvatar = ({ user }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const avatarColor = getAvatarColor(user.name);
    const initials = getInitials(user.name);

    return (
        <>
            <Avatar
                className={classes.avatar}
                style={{ backgroundColor: avatarColor }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                aria-owns={open ? 'user-popover' : undefined}
                aria-haspopup="true"
            >
                {initials}
            </Avatar>

            <Popover
                id="user-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className={classes.headerSection}>
                    <div className={classes.userInfo}>
                        <Avatar
                            className={classes.headerAvatar}
                            style={{ backgroundColor: avatarColor }}
                        >
                            {initials}
                        </Avatar>
                        <div className={classes.userDetails}>
                            <Typography variant="subtitle1" className={classes.userName}>
                                {user.name}
                            </Typography>
                            <Typography variant="body2" className={classes.userRole}>
                                {user?.profile ? profiles[user.profile] : profiles["user"]}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.emailSection}>
                    {user.email || 'Email não cadastrado'}
                </div>
            </Popover>
        </>
    );
};

// Componente principal de avatares de usuários
const KanbanUsersAvatar = ({ users, maxAvatars = 5 }) => {
    const classes = useStyles();

    // Se não houver usuários, não renderiza nada
    if (!users || users.length === 0) return null;

    // Determina quais usuários mostrar e quantos estão ocultos
    const visibleUsers = users.slice(0, maxAvatars);
    const hiddenCount = Math.max(0, users.length - maxAvatars);

    return (
        <Box className={classes.avatarGroup}>
            {visibleUsers.map((user) => (
                <UserAvatar
                    key={user.id}
                    user={user}
                />
            ))}

            {hiddenCount > 0 && (
                <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor: '#9e9e9e' }}
                >
                    +{hiddenCount}
                </Avatar>
            )}
        </Box>
    );
};

export default KanbanUsersAvatar;