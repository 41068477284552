import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  fade,
  makeStyles,
  useTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArchiveIcon from '@material-ui/icons/Archive';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { format, isSameDay, parseISO } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEvent } from 'react-use';
import CreateOrEditKanbanModal from '../../components/CreateOrEditKanbanModal';
import CreateOrEditOpportunityCardModal from '../../components/CreateOrEditOpportunityCardModal';
import KanbanEditOrCreateColumnModal from '../../components/KanbanEditOrCreateColumnModal';
import KanbanInput from '../../components/KanbanInput';
import AdvancedFilterBar from '../../components/KanbanProps/filterBar';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import handleEditCard from '../../helpers/kanban/handleEditCard';
import handleEditColumn from '../../helpers/kanban/handleEditColumn';
import onDragEnd from '../../helpers/kanban/onDragEnd';
import { useEventHook } from '../../hooks/useEvent';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import calculateTotalValueCardsAmount from '../../utils/calculateTotalValueCardsAmount';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import filterKanbanData from '../../utils/filterKanbanData';
import formatMoneyBRL from '../../utils/formatMoneyBRL';
import getInitialsName from '../../utils/getInitialsName';
import getTextColor from '../../utils/getTextColor';
import identificarGenero from '../../utils/identificarGenero';
import transformKanbanData from '../../utils/transformKanbanData';
import { revertToast } from '../../components/toasts/revert';
import { ToastContainer } from 'react-toastify';

// Estilos profissionais e minimalistas integrados
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.type === 'light' ? '#f7f9fc' : '#424242',
    // overflowX: 'auto',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    // overflow: 'hidden',
    backgroundColor: theme.palette.type === 'light' ? '#f7f9fc' : '#424242',
  },
  filterContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#2b2b2b',
    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.type === 'light' ? '#e8ecf1' : '#383854'}`,
  },
  contentContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'auto',
    padding: theme.spacing(1, 0),
  },
  columnsContainer: {
    display: 'flex',
    minHeight: 'fit-content',
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  // Estilos refinados para colunas
  column: {
    width: 300,
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#2b2b2b',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    height: 'fit-content',
    boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
    transition: 'box-shadow 0.2s ease',
    border: `1px solid ${theme.palette.type === 'light' ? '#e8ecf1' : '#383854'}`,
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0 3px 8px rgba(0,0,0,0.08)',
    }
  },
  columnHeader: {
    padding: '5px 15px',
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.type === 'light' ? '#e8ecf1' : '#383854'}`,
    transition: 'background-color 0.2s ease',
    display: 'flex',
    flexDirection: 'column',
  },
  columnTitle: {
    fontSize: '0.8rem',
    fontWeight: 600,
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
  },
  columnHeaderActions: {
    display: 'flex',
    alignItems: 'center',
  },
  columnStats: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(0.5),
    fontSize: '0.75rem',
    opacity: 0.75,
    fontWeight: 500,
  },
  cardsList: {
    padding: theme.spacing(1.5),
    flexGrow: 1,
    minHeight: 40,
    // overflowY: 'auto',
    // maxHeight: 'calc(100vh - 230px)',
  },
  // Estilos refinados para cards
  card: {
    marginBottom: theme.spacing(1.5),
    userSelect: 'none',
    position: 'relative',
    borderRadius: 6,
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    transition: 'all 0.2s ease',
    border: `1px solid ${theme.palette.type === 'light' ? '#edf0f5' : '#383854'}`,
    // overflow: 'hidden',
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#424242',
    '&:hover': {
      boxShadow: '0 3px 8px rgba(0,0,0,0.08)',
      transform: 'translateY(-2px)',
      '& $cardMenuButton': {
        opacity: 1,
      }
    },
    '&:active': {
      transform: 'scale(0.98)',
    },
  },
  cardContent: {
    padding: theme.spacing(1.5, 2),
    '&:last-child': {
      paddingBottom: theme.spacing(1.5),
    },
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: '0.9rem',
    marginBottom: theme.spacing(0.5),
    lineHeight: '1.3',
    wordBreak: 'break-word',
    color: theme.palette.type === 'light' ? '#363636' : '#e8ecf1',
  },
  cardInfoRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.7),
    fontSize: '0.75rem',
    color: theme.palette.type === 'light' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.7)',
    '& svg': {
      fontSize: '0.9rem',
      marginRight: theme.spacing(0.5),
      opacity: 0.6,
    },
    '& strong': {
      fontWeight: 600,
      marginRight: theme.spacing(0.5),
    }
  },
  cardInfoIcon: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(0.5),
    opacity: 0.6,
    color: theme.palette.type === 'light' ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)',
  },
  cardLoading: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 10,
    borderRadius: 6,
  },
  loadingProgress: {
    color: theme.palette.primary.main,
  },
  // Estilos refinados para menus e botões
  menuButton: {
    padding: 5,
    marginLeft: theme.spacing(1),
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    }
  },
  cardMenuButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    padding: 2,
    backgroundColor: theme.palette.type === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(45, 45, 66, 0.9)',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    opacity: 0,
    transition: 'all 0.2s ease',
    zIndex: 5,
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(255, 255, 255, 1)' : 'rgba(45, 45, 66, 1)',
      transform: 'scale(1.1)',
    },
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  // Estilo para avatar dos proprietários
  ownerAvatar: {
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    color: '#ffffff',
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.primary.main,
    transition: 'transform 0.2s ease',
    border: 'none',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
  // Estilo refinado para chip de status e tags
  customChip: {
    height: '20px',
    borderRadius: '4px',
    fontSize: '0.7rem',
    fontWeight: 600,
    padding: '0 8px',
    margin: '2px 2px 2px 0',
  },
  statusChip: {
    height: '20px',
    borderRadius: '4px',
    fontSize: '0.7rem',
    fontWeight: 600,
    padding: '0 8px',
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  // Estilos para o botão de adicionar
  addButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: 500,
    width: '100%',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 6,
    padding: theme.spacing(0.8, 0),
    transition: 'all 0.2s ease',
    backgroundColor: theme.palette.type === 'light' ? 'rgba(63, 81, 181, 0.02)' : 'rgba(63, 81, 181, 0.05)',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(63, 81, 181, 0.05)' : 'rgba(63, 81, 181, 0.1)',
      transform: 'translateY(-2px)',
    }
  },
  newColumnButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: 500,
    width: 300,
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 6,
    padding: theme.spacing(2, 0),
    transition: 'all 0.2s ease',
    backgroundColor: theme.palette.type === 'light' ? 'rgba(63, 81, 181, 0.02)' : 'rgba(63, 81, 181, 0.05)',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(63, 81, 181, 0.05)' : 'rgba(63, 81, 181, 0.1)',
      transform: 'translateY(-2px)',
    }
  },
  // Estilos para o menu de contexto
  customMenu: {
    '& .MuiPaper-root': {
      borderRadius: 8,
      boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
      border: `1px solid ${theme.palette.type === 'light' ? '#e8ecf1' : '#383854'}`,
      overflow: 'hidden',
    }
  },
  menuItem: {
    fontSize: '0.8rem',
    minHeight: '30px',
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1.5),
      fontSize: '1.1rem',
    }
  },
  archiveMenuItem: {
    color: theme.palette.error.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    }
  },
  dividerStyle: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)',
  },
  // Estilo para a tag pago/não pago
  paymentTag: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: '0.65rem',
    fontWeight: 600,
    padding: '2px 6px',
    borderRadius: '0 0 4px 0',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
  paidTag: {
    backgroundColor: '#e6f7ed',
    color: '#1e8449',
  },
  unpaidTag: {
    backgroundColor: '#fdecea',
    color: '#d32f2f',
  },
  pendingTag: {
    backgroundColor: '#fff7e5',
    color: '#ed8936',
  },
}));

const KanbanBoard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { boardId } = useParams();
  const emitter = useEventHook();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [kanbanData, setKanbanData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [boards, setBoards] = useState([]);
  const [openKanbanModal, setOpenKanbanModal] = useState(false);
  const [loadingCard, setLoadingCard] = useState([]);
  const [loadingColumn, setLoadingColumn] = useState(false);
  const [addingColumn, setAddingColumn] = useState(false);
  const [editingColumn, setEditingColumn] = useState(false);
  const [selectedColumnEdit, setSelectedColumnEdit] = useState(null);
  const [addingCard, setAddingCard] = useState(null);
  const [renamingCard, setRenamingCard] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [openCardPropsModal, setOpenCardPropsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Estado para menus de contexto
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);
  const [currentColumnId, setCurrentColumnId] = useState(null);
  const [currentColumnPosition, setCurrentColumnPosition] = useState(null);
  const [cardMenuAnchor, setCardMenuAnchor] = useState(null);
  const [currentCardId, setCurrentCardId] = useState(null);
  const [currentCardPosition, setCurrentCardPosition] = useState(null);
  const [currentCardColumnId, setCurrentCardColumnId] = useState(null);

  const addCardInputRef = useRef(null);
  const renameCardInputRef = useRef(null);
  const contentContainerRef = useRef(null);

  const [filters, setFilters] = useState({
    minValue: 0,
    maxValue: 0,
    selectedStatus: [],
    sortBy: 'default',
    selectedOwners: [],
    selectedTags: [],
    dateRange: 'all',
    startDate: null,
    endDate: null,
    showMyOnly: false,
    showTaskNumber: false
  });
  const [isHovered, setIsHovered] = useState(false);

  useEvent('click', (event) => {
    if (addCardInputRef.current && !addCardInputRef.current.contains(event.target)) setAddingCard(false);
    if (renameCardInputRef.current && !renameCardInputRef.current.contains(event.target)) setRenamingCard(false);
  });

  const handleFilterApply = async (newFilters) => {
    try {
      const { data } = await api.put(`/filters/kanban/${boardId}`, newFilters);
      const updatedFilters = {
        ...data,
        selectedStatus: data?.status || [],
        selectedTags: data?.tags || [],
        selectedOwners: data?.owners || [],
      };
      setFilters(updatedFilters);
    } catch (err) {
      console.error(err);
    }
  };

  // Handlers para menus de contexto
  const handleColumnMenuOpen = (event, columnId, position) => {
    event.stopPropagation();
    setColumnMenuAnchor(event.currentTarget);
    setCurrentColumnId(columnId);
    setCurrentColumnPosition(position);
  };

  const handleColumnMenuClose = () => {
    setColumnMenuAnchor(null);
    setCurrentColumnId(null);
    setCurrentColumnPosition(null);
  };

  const handleCardMenuOpen = (event, cardId, columnId, position) => {
    event.stopPropagation();
    event.preventDefault();
    setCardMenuAnchor(event.currentTarget);
    setCurrentCardId(cardId);
    setCurrentCardColumnId(columnId);
    setCurrentCardPosition(position);
  };

  const handleCardMenuClose = () => {
    setCardMenuAnchor(null);
    setCurrentCardId(null);
    setCurrentCardColumnId(null);
    setCurrentCardPosition(null);
  };

  // Handler para arquivar uma coluna
  const onArchiveColumn = async () => {
    handleColumnMenuClose();
  };

  // Handler para arquivar um card
  const onArchiveCard = async () => {
    handleCardMenuClose();
    // Extrair o ID sem o prefixo "card-"
    const cleanCardId = currentCardId.replace('card-', '');

    try {
      // Capturar informações do card e coluna antes de arquivar
      const columnId = currentCardColumnId;
      const cardPosition = currentCardPosition;

      // Armazenar uma cópia dos dados completos do card para possível reversão
      const columnData = kanbanData.column.find(col => col.id === columnId);
      const cardDataComplete = columnData?.cards?.find(card => card.id === cleanCardId);

      if (!cardDataComplete) {
        console.error('Card não encontrado');
        return;
      }

      // Ativar estado de carregamento
      setLoadingCard(prev => ({ ...prev, [currentCardId]: true }));

      // Chamar a API para arquivar o card
      await api.put(`/card/kanban/${cleanCardId}`, { isArchived: true });

      // Criar uma cópia profunda do estado atual
      const updatedKanbanData = JSON.parse(JSON.stringify(kanbanData));

      // Encontrar a coluna e remover completamente o card do array
      const columnIndex = updatedKanbanData.column.findIndex(col => col.id === columnId);

      if (columnIndex !== -1) {
        // Remover o card fisicamente do array
        updatedKanbanData.column[columnIndex].cards = updatedKanbanData.column[columnIndex].cards.filter(
          card => card.id !== cleanCardId
        );

        // Atualizar o estado com o card removido
        setKanbanData(updatedKanbanData);
        setLoadingCard(prev => ({ ...prev, [currentCardId]: false }));
        console.info({ cardDataComplete })
        // Mostrar toast com opção de reverter
        revertToast(
          `${capitalizeFirstLetter(kanbanData?.taskName || 'oportunidade')} Arquiva${identificarGenero((kanbanData?.taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'}`,
          `*${cardDataComplete.title}* foi removi${identificarGenero((kanbanData?.taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} do quadro`,
          async () => {
            // Função para reverter o arquivamento
            try {
              // Ativar estado de carregamento ao restaurar
              setLoadingCard(prev => ({ ...prev, [currentCardId]: true }));

              // Chamar a API para desarquivar o card
              await api.put(`/card/kanban/${cleanCardId}`, {
                isArchived: false,
                columnId: columnId,
                order: cardPosition
              });

              // Buscar dados atualizados do kanban para evitar duplicações
              const { data: refreshedData } = await api.get(`/kanban/${boardId}`);

              // Verificar se o card já foi restaurado pelo backend
              const targetColumn = refreshedData.column.find(col => col.id === columnId);
              const cardAlreadyRestored = targetColumn?.cards?.some(card => card.id === cleanCardId);

              if (!cardAlreadyRestored) {
                // Se o card não foi restaurado pelo backend, restaurá-lo manualmente
                const newKanbanData = JSON.parse(JSON.stringify(refreshedData));
                const colIndex = newKanbanData.column.findIndex(col => col.id === columnId);

                if (colIndex !== -1) {
                  if (!newKanbanData.column[colIndex].cards) {
                    newKanbanData.column[colIndex].cards = [];
                  }

                  // Restaurar o card com seus dados originais
                  if (cardPosition >= 0 && cardPosition <= newKanbanData.column[colIndex].cards.length) {
                    newKanbanData.column[colIndex].cards.splice(cardPosition, 0, {
                      ...cardDataComplete,
                      isArchived: false
                    });
                  } else {
                    newKanbanData.column[colIndex].cards.push({
                      ...cardDataComplete,
                      isArchived: false
                    });
                  }

                  setKanbanData(newKanbanData);
                }
              } else {
                // Se o card já foi restaurado pelo backend, apenas atualizar o estado
                setKanbanData(refreshedData);
              }

              // Desativar o loading ao finalizar
              setLoadingCard(prev => ({ ...prev, [currentCardId]: false }));

            } catch (error) {
              console.error('Erro ao reverter arquivamento do card:', error);
              toastError('Não foi possível restaurar o card');
              setLoadingCard(prev => ({ ...prev, [currentCardId]: false }));
            }
          }
        );
      }
    } catch (error) {
      console.error('Erro ao arquivar card:', error);
      toastError('Não foi possível arquivar o card');

      // Resetar estado de carregamento em caso de erro
      setLoadingCard(prev => ({ ...prev, [currentCardId]: false }));
    }
  };

  // Carrega lista de boards
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/kanban/list');
        setBoards(data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  // Atualiza os dados filtrados quando kanbanData muda
  useEffect(() => {
    if (!kanbanData) return;
    const transformedData = transformKanbanData(kanbanData, filters);
    setFilteredData(transformedData);
  }, [kanbanData, filters]);

  // Atualiza os dados filtrados quando filtros ou busca mudam
  useEffect(() => {
    if (!kanbanData) return;
    const filterValues = filterKanbanData(kanbanData, {
      searchTerm,
      ...filters,
      userId: user?.id
    });
    const transformedData = transformKanbanData(filterValues, filters);
    setFilteredData(transformedData);
  }, [kanbanData, searchTerm, filters, user?.id]);

  // Carrega dados do kanban quando boardId muda
  useEffect(() => {
    if (!boardId) return;

    const loadKanban = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/kanban/${boardId}`);

        // Filtrar colunas e cards arquivados
        if (data && data.column) {
          data.column = data.column.filter(col => !col.isArchived);

          data.column.forEach(col => {
            if (col.cards) {
              col.cards = col.cards.filter(card => !card.isArchived);
            }
          });
        }

        setKanbanData(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const loadFilters = async () => {
      try {
        const { data } = await api.get(`/filters/kanban/${boardId}`);
        setFilters({
          ...data,
          selectedStatus: data?.status || [],
          selectedTags: data?.tags || [],
          selectedOwners: data?.owners || [],
        });
      } catch (err) {
        console.error(err);
      }
    };

    loadKanban();
    loadFilters();
  }, [boardId]);

  const kanban_card_event = data => {
    if ((data.kanbanId !== boardId) || (data?.userSocket === user?.id)) return;
    handleEditCard({ action: data.action, cardId: data.cardId, card: data.card, setLoadingCard, setKanbanData, enableLoading: true });
  };

  const kanban_column_event = data => {
    if ((data.kanbanId !== boardId) || (data?.userSocket === user?.id)) return;
    handleEditColumn({ action: 'update', columnId: data.kanbanColumnId, column: data, setLoadingColumn, setKanbanData });
  };

  useEffect(() => {
    if (!boardId) return;

    // Emitter
    emitter.on('kanban-card', kanban_card_event);
    emitter.on('kanban-column', kanban_column_event);

    // Socket
    socket.on('kanban-card', kanban_card_event);
    socket.on('kanban-column', kanban_column_event);

    return () => {
      // Cleanup
      emitter.off('kanban-card', kanban_card_event);
      emitter.off('kanban-column', kanban_column_event);
      socket.off('kanban-card', kanban_card_event);
      socket.off('kanban-column', kanban_column_event);
    };
  }, [boardId, user?.id, emitter]);

  const handleCloseKanbanModal = () => {
    setOpenKanbanModal(false);
  };

  const handleOpenCardPropsModal = (cardId) => {
    setSelectedCardId(cardId);
    setOpenCardPropsModal(true);
  };

  const handleCloseCardPropsModal = () => {
    setSelectedCardId(null);
    setOpenCardPropsModal(false);
  };

  const handleCreateCard = async ({
    title,
    columnId,
    kanbanData
  }) => {
    try {
      const column = kanbanData.column.find(column => column.id === columnId);
      const order = column?.cards?.length || 0;
      const { data } = await api.post(`/card/kanban`, { title, columnId, order: order });
      handleEditCard({ action: 'create', cardId: data.id, card: data, setLoadingCard, setKanbanData, enableLoading: true });
    } catch (error) {
      console.error(error);
      toastError("Ocorreu um erro ao tentar criar card");
    } finally {
      setAddingCard(false);
    }
  };

  const handleCreateColumn = async ({ title, color }) => {
    try {
      const { data } = await api.post('/column/kanban', {
        title: title,
        color: color,
        kanbanId: boardId,
        position: kanbanData ? kanbanData.length : 0,
      });
      await handleEditColumn({ action: 'create', columnId: null, column: data, setLoadingColumn, setKanbanData });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateColumn = async ({ title, color, columnId: kanbanColumnId }) => {
    try {
      const columnId = kanbanColumnId.replace('column-', '');
      setLoadingColumn((prevLoading) => ({ ...prevLoading, [kanbanColumnId]: true }));
      const { data } = await api.put(`/column/kanban/${columnId}`, { title, color, kanbanId: boardId });
      await handleEditColumn({ action: 'update', columnId, column: data, setLoadingColumn, setKanbanData });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumn((prevLoading) => ({ ...prevLoading, [kanbanColumnId]: false }));
    }
  };

  // Helper para renderizar a tag de pagamento
  const renderPaymentTag = (card) => {
    if (!card) return null;

    const isPago = card.status === 'Pago';
    const isNaoPago = card.status === 'Não pago';

    if (isPago) {
      return (
        <div className={`${classes.paymentTag} ${classes.paidTag}`}>
          Pago
        </div>
      );
    } else if (isNaoPago) {
      return (
        <div className={`${classes.paymentTag} ${classes.unpaidTag}`}>
          Não pago
        </div>
      );
    } else {
      return null;
    }
  };

  if (loading) {
    return (
      <Box p={3} display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={36} thickness={4} />
        <Typography variant="h6" style={{ marginLeft: 16, fontWeight: 500 }}>
          Carregando quadro Kanban
        </Typography>
      </Box>
    );
  }



  return (
    <div className={classes.mainContainer}>
      <KanbanEditOrCreateColumnModal
        open={addingColumn || editingColumn}
        onClose={() => {
          setAddingColumn(false);
          setEditingColumn(false);
          setSelectedColumnEdit(null);
        }}
        columnId={selectedColumnEdit}
        onConfirm={({ title, color, columnId }) => !!columnId ? handleUpdateColumn({ title, color, columnId }) : handleCreateColumn({ title, color })}
      />

      <CreateOrEditKanbanModal
        open={openKanbanModal}
        onClose={handleCloseKanbanModal}
        kanbanId={null}
      />

      <CreateOrEditOpportunityCardModal
        boardId={boardId}
        open={openCardPropsModal}
        onClose={handleCloseCardPropsModal}
        cardId={selectedCardId}
        taskName={kanbanData?.taskName}
        ownerName={kanbanData?.ownerName}
      />

      {/* Menus de contexto */}
      <Menu
        anchorEl={columnMenuAnchor}
        open={Boolean(columnMenuAnchor)}
        onClose={handleColumnMenuClose}
        className={classes.customMenu}
      >
        <MenuItem onClick={() => {
          handleColumnMenuClose();
          setSelectedColumnEdit(currentColumnId);
          setEditingColumn(true);
        }} className={classes.menuItem}>
          <EditIcon fontSize="small" />
          Editar
        </MenuItem>
        <Divider className={classes.dividerStyle} />
        {/*
        <MenuItem disabled onClick={onArchiveColumn} className={`${classes.menuItem} ${classes.archiveMenuItem}`}>
          <ArchiveIcon fontSize="small" />
          Arquivar
        </MenuItem>
        */}
      </Menu>

      <Menu
        anchorEl={cardMenuAnchor}
        open={Boolean(cardMenuAnchor)}
        onClose={handleCardMenuClose}
        className={classes.customMenu}
      >
        {/*
        <MenuItem onClick={() => {
          handleCardMenuClose();
          handleOpenCardPropsModal(currentCardId);
        }} className={classes.menuItem}>
          <EditIcon fontSize="small" />
          Editar
        </MenuItem>
        <Divider className={classes.dividerStyle} />
        */}
        <MenuItem onClick={onArchiveCard} className={`${classes.menuItem} ${classes.archiveMenuItem}`}>
          <ArchiveIcon fontSize="small" />
          Arquivar
        </MenuItem>
      </Menu>

      {/* Container fixo para a barra de filtros */}
      <Box className={classes.filterContainer}>
        <AdvancedFilterBar
          followers={kanbanData?.followers}
          initialFilters={filters}
          initialBoardId={boardId}
          boards={boards}
          onFilterApply={handleFilterApply}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          kanbanData={kanbanData}
        />
      </Box>

      {/* Container com rolagem para o conteúdo Kanban */}
      <Box className={classes.contentContainer} ref={contentContainerRef}>
        <Box className={classes.root}>
          <DragDropContext onDragEnd={result => onDragEnd({
            result,
            kanbanData,
            filteredData,
            setKanbanData,
            setFilteredData,
            setLoadingCard,
            setLoadingColumn,
            handleEditColumn,
            handleEditCard
          })}>
            <Droppable
              droppableId="all-columns"
              direction="horizontal"
              type="column"
              ignoreContainerClipping={true}
            >
              {(provided) => (
                <Box
                  className={classes.columnsContainer}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {filteredData?.columnOrder?.map((columnId, columnIndex) => {
                    const column = filteredData.columns[columnId];
                    if (!column) return null;

                    const cards = column.cardIds
                      .map((cardId) => filteredData.cards[cardId])
                      .filter(Boolean);

                    const totalAmount = calculateTotalValueCardsAmount(cards);

                    return (
                      <Draggable
                        key={column.id}
                        draggableId={column.id}
                        index={columnIndex}
                      >
                        {(provided) => (
                          <Paper
                            className={classes.column}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <Box
                              style={{ backgroundColor: column.color, color: getTextColor(column.color) }}
                              className={classes.columnHeader}
                              {...provided.dragHandleProps}
                              onMouseEnter={() => setIsHovered(column.id)}
                              onMouseLeave={() => setIsHovered(null)}
                            >
                              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography className={classes.columnTitle}>
                                  {column.title}
                                </Typography>
                                <IconButton
                                  aria-label="column-menu"
                                  size="small"
                                  className={classes.menuButton}
                                  onClick={(e) => handleColumnMenuOpen(e, column.id, columnIndex)}
                                >
                                  <MoreVertIcon style={{ color: getTextColor(column.color), fontSize: '1.2rem' }} />
                                </IconButton>
                              </Box>
                              <Box className={classes.columnStats}>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                  {column.cardIds.length} {capitalizeFirstLetter(kanbanData?.taskName || 'oportunidade')}s
                                </Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                  {formatMoneyBRL(totalAmount)}
                                </Typography>
                              </Box>
                            </Box>
                            <Droppable
                              droppableId={column.id}
                              type="card"
                              ignoreContainerClipping={true}
                            >
                              {(provided) => (
                                <Box
                                  id="card-list"
                                  className={classes.cardsList}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {cards.map((card, cardIndex) => (
                                    <Draggable
                                      key={card.id}
                                      draggableId={card.id}
                                      index={cardIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <Card
                                          className={`${classes.card} ${(loadingCard && loadingCard[card.id] === true) ? classes.cardLoading : ''}`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          elevation={snapshot.isDragging ? 3 : 0}
                                          onClick={() => handleOpenCardPropsModal(card.id)}
                                        >
                                          {/* Tag de pagamento */}
                                          {renderPaymentTag(card)}

                                          {/* Botão de menu do card */}
                                          <IconButton
                                            aria-label="card-menu"
                                            className={classes.cardMenuButton}
                                            size="small"
                                            onClick={(e) => handleCardMenuOpen(
                                              e,
                                              card.id,
                                              column.id.replace('column-', ''),
                                              cardIndex
                                            )}
                                          >
                                            <MoreVertIcon fontSize="small" />
                                          </IconButton>

                                          {(loadingCard && loadingCard[card.id] === true) && (
                                            <Fade in={(loadingCard && loadingCard[card.id] === true)}>
                                              <div className={classes.loadingOverlay}>
                                                <CircularProgress
                                                  size={28}
                                                  thickness={3}
                                                  className={classes.loadingProgress}
                                                />
                                              </div>
                                            </Fade>
                                          )}
                                          <CardContent className={classes.cardContent}>
                                            {/* Status chip */}
                                            {card?.kanbanPriorityLevel && (
                                              <Chip
                                                label={card?.kanbanPriorityLevel.title}
                                                size="small"
                                                className={classes.statusChip}
                                                style={{
                                                  backgroundColor: theme.palette.type === 'light' ? fade(card?.kanbanPriorityLevel.color, 0.15) : card?.kanbanPriorityLevel.color,
                                                  color: theme.palette.type === 'light' ? card?.kanbanPriorityLevel.color : getTextColor(card?.kanbanPriorityLevel.color)
                                                }}
                                              />
                                            )}

                                            {/* Card header com título e avatar */}
                                            <Box className={classes.cardHeader}>
                                              <Typography className={classes.cardTitle}>
                                                {card.name}
                                              </Typography>

                                              {card?.owner && (
                                                <Tooltip title={`${capitalizeFirstLetter(kanbanData?.ownerName || 'vendedor')}: ${card.owner.name}`}>
                                                  <Avatar className={classes.ownerAvatar}>
                                                    {getInitialsName(card.owner.name)}
                                                  </Avatar>
                                                </Tooltip>
                                              )}
                                            </Box>

                                            {/* Informações do card */}
                                            {card?.businessName && (
                                              <Box className={classes.cardInfoRow}>
                                                <BusinessIcon className={classes.cardInfoIcon} />
                                                <strong>Empresa:</strong> {card.businessName}
                                              </Box>
                                            )}

                                            {card?.owner && (
                                              <Box className={classes.cardInfoRow}>
                                                <PersonOutlineIcon className={classes.cardInfoIcon} />
                                                <strong>{capitalizeFirstLetter(kanbanData?.ownerName || 'vendedor')}:</strong> {card.owner.name}
                                              </Box>
                                            )}

                                            {card?.kanbanOpportunitySource && (
                                              <Box className={classes.cardInfoRow}>
                                                <ControlCameraIcon className={classes.cardInfoIcon} />
                                                <strong>Fonte:</strong> {card?.kanbanOpportunitySource?.title || ''}
                                              </Box>
                                            )}

                                            {/* Tags */}
                                            {card?.tags && card?.tags?.length > 0 && (
                                              <Box className={classes.tagsContainer}>
                                                {card.tags.map((tag, tagIndex) => (
                                                  <Tooltip key={tagIndex} title={`Tag: ${tag.title}`}>
                                                    <Chip
                                                      label={tag.title}
                                                      size="small"
                                                      className={classes.customChip}
                                                      style={{
                                                        backgroundColor: theme.palette.type === 'light' ? fade(tag.color, 0.15) : tag.color,
                                                        color: theme.palette.type === 'light' ? tag.color : getTextColor(tag.color)

                                                      }}
                                                    />
                                                  </Tooltip>
                                                ))}
                                              </Box>
                                            )}

                                            {/* Valor */}
                                            {card?.amount && (
                                              <Box className={classes.cardInfoRow}>
                                                <AttachMoneyIcon className={classes.cardInfoIcon} />
                                                <strong>Valor:</strong> {formatMoneyBRL(card.amount)}
                                              </Box>
                                            )}

                                            {(card?.taskNumber && filters?.showTaskNumber) && (
                                              <Box className={classes.cardInfoRow}>
                                                <ConfirmationNumberIcon className={classes.cardInfoIcon} />
                                                <strong>Protocolo:</strong> {card.taskNumber}
                                              </Box>
                                            )}

                                            {/* Data de criação */}
                                            {card?.createdAt && (
                                              <Box className={classes.cardInfoRow}>
                                                <InsertInvitationIcon className={classes.cardInfoIcon} />
                                                <strong>Criado:</strong> {isSameDay(parseISO(card.createdAt), new Date()) ? (
                                                  <>Hoje às {format(parseISO(card.createdAt), 'HH:mm')}</>
                                                ) : (
                                                  <>{format(parseISO(card.createdAt), 'dd/MM/yyyy')}</>
                                                )}
                                              </Box>
                                            )}
                                          </CardContent>
                                        </Card>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                            <Box padding={2}>
                              {column.id === addingCard ?
                                (
                                  <Box>
                                    <KanbanInput
                                      ref={addCardInputRef}
                                      label={`Título ${identificarGenero((kanbanData?.taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(kanbanData?.taskName || 'oportunidade').toLowerCase()}`}
                                      limit={1000}
                                      value={''}
                                      onSave={title =>
                                        handleCreateCard({
                                          title,
                                          columnId: column?.id?.replace('column-', ''),
                                          kanbanData
                                        })
                                      }
                                      onCancel={() => setAddingCard(false)}
                                    />
                                  </Box>
                                )
                                :
                                (
                                  <Button
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAddingCard(column.id);
                                    }}
                                    variant="outlined"
                                    className={classes.addButton}
                                    startIcon={<AddIcon />}
                                  >
                                    {`${identificarGenero((kanbanData?.taskName || 'oportunidade')) === 'feminine' ? 'Nova' : 'Novo'} ${(kanbanData?.taskName || 'oportunidade').toLowerCase()}`}
                                  </Button>
                                )}
                            </Box>
                          </Paper>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}

                  {/* Botão para adicionar nova coluna */}
                  <Button
                    size="small"
                    onClick={() => setAddingColumn(true)}
                    variant="outlined"
                    className={classes.newColumnButton}
                    startIcon={<AddIcon />}
                  >
                    Nova Coluna
                  </Button>
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default KanbanBoard;