import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    Assessment as AssessmentIcon,
    Edit as EditIcon,
    Search as SearchIcon
} from '@material-ui/icons';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CreateOrEditKanbanModal from '../../components/CreateOrEditKanbanModal';
import DeleteItemButton from '../../components/DeleteItemButton';
import MainContainer from '../../components/MainContainer';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { useEventHook } from '../../hooks/useEvent';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
    },
    tableContainer: {
        width: '100%'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, 2, 0, 2),
    },
    title: {
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    searchContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        gap: theme.spacing(2),
    },
    searchField: {
        flexGrow: 1,
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.shape.borderRadius * 2,
        }
    },
    addButton: {
        borderRadius: theme.shape.borderRadius * 1.5,
        textTransform: 'none',
        fontWeight: 600,
        boxShadow: 'none',
    },
    tableContainer: {
        width: '97%',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(0, 2, 2, 2),
    },
    tableHeader: {
        backgroundColor: theme.palette.background.paper,
        '& .MuiTableCell-head': {
            fontWeight: 600,
            color: theme.palette.text.secondary,
        }
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        }
    },
    actionButton: {
        padding: theme.spacing(0.5),
    },
    noDataContainer: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(4),
    }
}));

// Mapeamento de textos para tipos de visualização
const viewerText = {
    private: "Vinculados ao quadro",
    admin: "Administradores",
    public: "Público"
};

const Opportunities = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const emitter = useEventHook();
    // Estados
    const [opportunities, setOpportunities] = useState([]);
    const [filteredOpportunities, setFilteredOpportunities] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [openKanbanModal, setOpenKanbanModal] = useState(false);
    const [editKanbanId, setEditKanbanId] = useState(null);

    // Função para buscar oportunidades da API
    const fetchOpportunities = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await api.get(`/kanban/list`);
            setOpportunities(data);
            setFilteredOpportunities(data);
        } catch (error) {
            toastError(error);
        } finally {
            setLoading(false);
        }
    }, [user]);

    // Carregar dados iniciais
    useEffect(() => {
        fetchOpportunities();
    }, [fetchOpportunities]);

    // Função para filtrar oportunidades
    const filterOpportunities = useCallback(() => {
        if (!searchTerm.trim()) {
            setFilteredOpportunities(opportunities);
            return;
        }

        const searchTermLower = searchTerm.toLowerCase().trim();
        const filtered = opportunities.filter(opportunity =>
            opportunity.title?.toLowerCase().includes(searchTermLower) ||
            opportunity.description?.toLowerCase().includes(searchTermLower)
        );

        setFilteredOpportunities(filtered);
    }, [searchTerm, opportunities]);

    // Atualizar filtros quando as dependências mudarem
    useEffect(() => {
        filterOpportunities();
    }, [filterOpportunities]);

    // Manipuladores de eventos
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditOpportunity = (id) => {
        setEditKanbanId(id);
        setOpenKanbanModal(true);
    };

    const handleDeleteOpportunity = async (id) => {
        try {
            await api.delete(`/kanban/${id}`);
            setOpportunities(prev => prev.filter(item => item.id !== id));
            setFilteredOpportunities(prev => prev.filter(item => item.id !== id));
        } catch (error) {
            toastError('Ocorreu um erro ao tentar deletar o quadro')
            console.error(error);
        }
    };

    const handleAddOpportunity = () => {
        setEditKanbanId(null);
        setOpenKanbanModal(true);
    };

    const handleCloseKanbanModal = (shouldRefresh = false) => {
        setOpenKanbanModal(false);
        setEditKanbanId(null);

        if (shouldRefresh) {
            fetchOpportunities();
        }
    };

    const handleCreateOrUpdateKanban = (data) => {
        const itemExists = opportunities.some(opportunity => opportunity.id === data.id);

        if (itemExists) {
            setOpportunities(prevOpportunities =>
                prevOpportunities.map(opportunity =>
                    opportunity.id === data.id ? { ...data } : opportunity
                )
            );
            setFilteredOpportunities(prevFiltered =>
                prevFiltered.map(opportunity =>
                    opportunity.id === data.id ? { ...data } : opportunity
                )
            );
        } else {
            setOpportunities(prevOpportunities => [data, ...prevOpportunities]);
            setFilteredOpportunities(prevFiltered => [data, ...prevFiltered]);
            setSearchTerm('');
            setPage(0);
        }
    };

    // Formatar data para exibição
    const formatDate = (dateString) => {
        if (!dateString) return '-';
        try {
            return format(new Date(dateString), 'dd/MM/yyyy', { locale: ptBR });
        } catch (error) {
            return dateString;
        }
    };

    const handleOpenBoard = async (boardId) => {
        const companyId = user.companyId;
        localStorage.setItem("kanbanCache", JSON.stringify({ companyId, lastKanbanId: boardId, userId: user.id }));
        history.push(`/opportunities/board/${boardId}`)
        emitter.emit("kanban-set-board", { companyId, lastKanbanId: boardId, userId: user.id });
        await api.put(`/filters/user/${user.id}`, { lastKanbanId: boardId, companyId });
    };

    return (
        <MainContainer>
            <CreateOrEditKanbanModal
                open={openKanbanModal}
                onClose={handleCloseKanbanModal}
                kanbanId={editKanbanId}
                onSuccess={handleCreateOrUpdateKanban}
            />

            <div className={classes.header}>
                <Typography variant="h6" className={classes.title}>
                    Oportunidades {filteredOpportunities.length > 0 && `(${filteredOpportunities.length})`}
                </Typography>
            </div>

            <div className={classes.searchContainer}>
                <TextField
                    className={classes.searchField}
                    placeholder="Buscar oportunidades..."
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    onClick={handleAddOpportunity}
                >
                    Nova Oportunidade
                </Button>
            </div>

            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>Título</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Visualização</TableCell>
                            <TableCell>Criado em</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={5} className={classes.loadingContainer}>
                                    <CircularProgress size={32} />
                                </TableCell>
                            </TableRow>
                        ) : filteredOpportunities.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} className={classes.noDataContainer}>
                                    <Typography variant="body2">
                                        {searchTerm ? 'Nenhuma oportunidade encontrada para esta busca.' : 'Nenhuma oportunidade cadastrada.'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredOpportunities
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((opportunity) => (
                                    <TableRow key={opportunity.id} className={classes.tableRow}>
                                        <TableCell>
                                            <Typography variant="body2" noWrap style={{ maxWidth: 200 }}>
                                                {opportunity.title || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={opportunity.description || ''} arrow>
                                                <Typography variant="body2" noWrap style={{ maxWidth: 250 }}>
                                                    {opportunity.description || '-'}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{viewerText[opportunity.viewer] || '-'}</TableCell>
                                        <TableCell>{formatDate(opportunity.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            {user.profile === 'admin' && (
                                                <>
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            className={classes.actionButton}
                                                            size="small"
                                                            onClick={() => handleEditOpportunity(opportunity.id)}
                                                        >
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <DeleteItemButton
                                                        itemId={opportunity.id}
                                                        handleDeleteItem={handleDeleteOpportunity}
                                                    />
                                                </>
                                            )}
                                            <Tooltip title="Ver quadro">
                                                <IconButton
                                                    className={classes.actionButton}
                                                    size="small"
                                                    onClick={() => handleOpenBoard(opportunity.id)}
                                                >
                                                    <AssessmentIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredOpportunities.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                labelRowsPerPage="Por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
        </MainContainer>
    );
};

export default Opportunities;