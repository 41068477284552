import { has, isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import moment from 'moment';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import socket from '../useSocket';
import { getVariablesWithUserData } from '../../utils/variableUtils';
import browserInfo from '../../utils/browserInfo';

const useAuth = () => {
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [status, setStatus] = useState(0);

    api.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
                setIsAuth(true);
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error?.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                const browser = browserInfo();
                const { data } = await api.post('/auth/refresh_token', { browser });

                const { company } = data.user;
                // Configurações globais de usuário e empresa
                const ticketWithoutQueueSetting = company.settings.find(s => s.key === 'ticketWithoutQueue');
                const ticketsInGroupsSetting = company.settings.find(s => s.key === 'CheckMsgIsGroup');
                localStorage.setItem('ticketWithoutQueue', ticketWithoutQueueSetting ? ticketWithoutQueueSetting.value === 'enabled' : false);
                localStorage.setItem('CheckMsgIsGroup', ticketsInGroupsSetting ? ticketsInGroupsSetting.value === 'enabled' : false);
                // ============================================================================================================================ //

                if (data) {
                    localStorage.setItem('token', JSON.stringify(data.token));
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                }
                return api(originalRequest);
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('companyId');
                api.defaults.headers.Authorization = undefined;
                setIsAuth(false);
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const token = localStorage.getItem('token');
        (async () => {
            if (token) {
                try {
                    const browser = browserInfo();
                    const { data } = await api.post('/auth/refresh_token', { browser });
                    const { company } = data.user;
                    // Configurações globais de usuário e empresa
                    const ticketWithoutQueueSetting = company.settings.find(s => s.key === 'ticketWithoutQueue');
                    const ticketsInGroupsSetting = company.settings.find(s => s.key === 'CheckMsgIsGroup');
                    localStorage.setItem('ticketWithoutQueue', ticketWithoutQueueSetting ? ticketWithoutQueueSetting.value === 'enabled' : false);
                    localStorage.setItem('CheckMsgIsGroup', ticketsInGroupsSetting ? ticketsInGroupsSetting.value === 'enabled' : false);
                    // ============================================================================================================================ //

                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                    setIsAuth(true);
                    await getVariablesWithUserData();
                    setUser(data.user);
                    setStatus(1);
                } catch (err) {
                    toastError(err);
                }
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-user`, (data) => {
            if (data.action === 'update' && data.user.id === user.id) {
                if (data?.user?.online && data.user.online !== status) {
                    setStatus(data.user.online)
                } else {
                    setUser(data.user);
                }
            }
        });

        socket.on(`company-${companyId}-settings`, (data) => {
            const { setting: { key, value } } = data;
            if (key === 'ticketWithoutQueue' || key === 'CheckMsgIsGroup') {
                localStorage.setItem(key, value === 'enabled');
            }
        });
        return () => { };
    }, [user]);

    const handleLogin = async (userData) => {
        setLoading(true);

        try {
            const browser = browserInfo();
            const { data } = await api.post('/auth/login', { ...userData, browser });
            const {
                user: { companyId, id, company },
            } = data;

            if (has(company, 'settings') && isArray(company.settings)) {
                const setting = company.settings.find((s) => s.key === 'campaignsEnabled');
                if (setting && setting.value === 'true') {
                    localStorage.setItem('cshow', null); //regra pra exibir campanhas
                }

                // Configurações globais de usuário e empresa
                const ticketWithoutQueueSetting = company.settings.find(s => s.key === 'ticketWithoutQueue');
                const ticketsInGroupsSetting = company.settings.find(s => s.key === 'CheckMsgIsGroup');
                localStorage.setItem('ticketWithoutQueue', ticketWithoutQueueSetting ? ticketWithoutQueueSetting.value === 'enabled' : false);
                localStorage.setItem('CheckMsgIsGroup', ticketsInGroupsSetting ? ticketsInGroupsSetting.value === 'enabled' : false);
                // ============================================================================================================================ //

            }

            moment.locale('pt-br');
            let dueDate;
            if (data.user.company.id === 1) {
                dueDate = '2999-12-31T00:00:00.000Z';
            } else {
                dueDate = data.user.company.dueDate;
            }
            const vencimento = moment(dueDate).format('DD/MM/yyyy');

            var diff = moment(dueDate).diff(moment(moment()).format());

            var before = moment(moment().format()).isBefore(dueDate);
            var dias = moment.duration(diff).asDays();

            if (before === true || data?.user?.email === "suporte@softlogsoftware.com.br") {

                localStorage.setItem('token', JSON.stringify(data.token));
                localStorage.setItem('companyId', companyId);
                localStorage.setItem('userId', id);
                localStorage.setItem('companyDueDate', vencimento);
                api.defaults.headers.Authorization = `Bearer ${data.token}`;
                setUser(data.user);
                setStatus(1);
                setIsAuth(true);
                setTimeout(() => {
                    getVariablesWithUserData();
                }, 1000);
                toast.success(i18n.t('auth.toasts.success'));
                if (Math.round(dias) < 5) {
                    if (dias < 0) {
                        toastError(`Opss! Sua assinatura venceu ${vencimento}. Entre em contato com o Suporte para mais informações! `);
                    } else {
                        toast.warn(
                            `Sua assinatura vence em ${Math.round(dias)} ${Math.round(dias) === 1 ? 'dia' : 'dias'} `
                        );
                    }

                }
                history.push('/tickets');
                setLoading(false);

            } else {
                localStorage.setItem('companyId', companyId);
                api.defaults.headers.Authorization = `Bearer ${data.token}`;
                setIsAuth(true);
                setTimeout(() => {
                    getVariablesWithUserData();
                }, 1000);
                toastError(`Opss! Sua assinatura venceu ${vencimento}. Entre em contato com o Suporte para mais informações! `);
                history.push('/financeiro-aberto');
                setLoading(false);
            }
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleLogout = async () => {
        setLoading(true);

        try {
            await api.delete('/auth/logout');
            setIsAuth(false);
            setUser({});
            localStorage.removeItem('token');
            localStorage.removeItem('companyId');
            localStorage.removeItem('userId');
            localStorage.removeItem('cshow');
            api.defaults.headers.Authorization = undefined;
            setLoading(false);
            history.push('/login');
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const getCurrentUserInfo = async () => {
        try {
            const { data } = await api.get('/auth/me');
            return data;
        } catch (err) {
            toastError(err);
        }
    };

    const handleStatus = (currentStatus) => setStatus(currentStatus);

    const handleUpdateUser = (data) => setUser(data);

    return {
        isAuth,
        user,
        loading,
        handleLogin,
        handleLogout,
        getCurrentUserInfo,
        status,
        handleStatus,
        handleUpdateUser
    };
};

export default useAuth;
