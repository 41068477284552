/**
 * Determina se uma cor hexadecimal é clara ou escura
 * e retorna a cor de texto apropriada para melhor legibilidade
 */

// Função principal que recebe uma cor hexadecimal e retorna 'light' ou 'dark'
function isLightOrDark(hexColor) {
  // Remove o # se estiver presente
  hexColor = hexColor.replace('#', '');

  // Converte formato shorthand (por exemplo #FFF para #FFFFFF)
  if (hexColor.length === 3) {
    hexColor = hexColor.split('').map(char => char + char).join('');
  }

  // Converte hex para RGB
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calcula a luminosidade percebida (fórmula baseada em YIQ)
  // Essa fórmula leva em consideração como o olho humano percebe as diferentes cores
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Valor de corte padrão é 128, mas 150 oferece melhor contraste em muitos casos
  return yiq >= 150 ? 'light' : 'dark';
}

// Função para determinar a cor do texto com base no fundo
function getTextColor(backgroundColor) {
  return isLightOrDark(backgroundColor) === 'light' ? '#000000' : '#FFFFFF';
}



export default getTextColor;
