import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Badge, Collapse, List } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { CalendarToday, LoyaltyRounded } from '@material-ui/icons';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BusinessIcon from '@material-ui/icons/Business';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ForumIcon from '@material-ui/icons/Forum';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ListIcon from '@material-ui/icons/ListAlt';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MemoryIcon from '@material-ui/icons/Memory';
import PeopleIcon from '@material-ui/icons/People';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Typography from '@material-ui/core/Typography';

import { isArray } from 'lodash';
import { Can } from '../components/Can';
import { AuthContext } from '../context/Auth/AuthContext';
import { WhatsAppsContext } from '../context/WhatsApp/WhatsAppsContext';
import toastError from '../errors/toastError';
import { useEventHook } from '../hooks/useEvent';
import usePlans from '../hooks/usePlans';
import socket from '../hooks/useSocket';
import useVersion from '../hooks/useVersion';
import api from '../services/api';
import { i18n } from '../translate/i18n';
// import logo from "../assets/logo.png";

function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to]
    );

    return (
        <li>
            <ListItem dense button component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const reducer = (state, action) => {
    if (action.type === 'LOAD_CHATS') {
        const chats = action.payload;
        const newChats = [];

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id);
                if (chatIndex !== -1) {
                    state[chatIndex] = chat;
                } else {
                    newChats.push(chat);
                }
            });
        }

        return [...state, ...newChats];
    }

    if (action.type === 'UPDATE_CHATS') {
        const chat = action.payload;
        const chatIndex = state.findIndex((u) => u.id === chat.id);

        if (chatIndex !== -1) {
            state[chatIndex] = chat;
            return [...state];
        } else {
            return [chat, ...state];
        }
    }

    if (action.type === 'DELETE_CHAT') {
        const chatId = action.payload;

        const chatIndex = state.findIndex((u) => u.id === chatId);
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }

    if (action.type === 'CHANGE_CHAT') {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat;
            }
            return chat;
        });
        return changedChats;
    }
};

const MainListItems = (props, { collapsed }) => {
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const emitter = useEventHook();
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
    const [showCampaigns, setShowCampaigns] = useState(false);
    // novas features
    const [showSchedules, setShowSchedules] = useState(false);
    const [showInternalChat, setShowInternalChat] = useState(false);
    const [showExternalApi, setShowExternalApi] = useState(false);
    const [showEmailSettings, setShowEmailSettings] = useState(false);
    const [showDocumentGenerator, setShowDocumentGenerator] = useState(false);
    const [showKanbanBoard, setShowKanbanBoard] = useState(false);
    const [userViewBoard, setUserViewBoard] = useState(false);
    const [lastKanbanId, setLastKanbanId] = useState(false);

    const history = useHistory();

    const [invisible, setInvisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam] = useState('');
    const [chats, dispatch] = useReducer(reducer, []);
    const [version, setVersion] = useState(false);
    const [openKanbanSubmenu, setOpenKanbanSubmenu] = useState(false);

    const { getPlanCompany } = usePlans();

    const { getVersion } = useVersion();

    useEffect(() => {
        async function fetchVersion() {
            const _version = await getVersion();
            setVersion(_version.version);
        }
        fetchVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        emitter.on("kanban-set-board", (data) => {
            const { lastKanbanId } = data;
            setLastKanbanId(lastKanbanId)
        });

        try {
            const kanbanCache = localStorage.getItem("kanbanCache");
            if (!kanbanCache) {
                if (user && user.lastKanbanId) {
                    setLastKanbanId(user.lastKanbanId);
                }
                return;
            }
            const { companyId, lastKanbanId, userId } = JSON.parse(kanbanCache);
            if (!user || companyId !== user.companyId || userId !== user.id) return;
            if (user.lastKanbanId || lastKanbanId) {
                setLastKanbanId(user.lastKanbanId || lastKanbanId);
            }
        } catch (error) {
            console.error("Erro ao processar kanbanCache:", error);
            if (user && user.lastKanbanId) {
                setLastKanbanId(user.lastKanbanId);
            }
        }
    }, [user]);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        (async () => {
            const companyId = localStorage.getItem('companyId');
            const planConfigs = await getPlanCompany(undefined, companyId);
            setShowCampaigns(planConfigs.plan.useCampaigns);
            setShowSchedules(planConfigs.plan.useSchedules);
            setShowInternalChat(planConfigs.plan.useInternalChat);
            setShowExternalApi(planConfigs.plan.useExternalApi);
            setShowEmailSettings(planConfigs.plan.useEmail);
            setShowDocumentGenerator(planConfigs.plan.useDocumentGenerator);
            setShowKanbanBoard(planConfigs.plan.useKanbanBoard);
        })();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-chat`, (data) => {
            if (data.action === 'new-message') {
                dispatch({ type: 'CHANGE_CHAT', payload: data });
            }
            if (data.action === 'update') {
                dispatch({ type: 'CHANGE_CHAT', payload: data });
            }
        });

        return () => { };
    }, []);

    useEffect(() => {

        (async () => {
            const { id, profile } = user;
            console.info({ id, profile });
            if (!id && profile === 'admin') return;
            try {
                const { data } = await api.get(`/user/kanbans/${user?.id}`);
                setUserViewBoard(data?.exist || false);
            } catch (error) {
                console.error(error);
            }
        })();

    }, [user]);

    useEffect(() => {
        let unreadsCount = 0;
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id) {
                        unreadsCount += chatUser.unreads;
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false);
        } else {
            setInvisible(true);
        }
    }, [chats, user.id]);

    // useEffect(() => {
    //   if (localStorage.getItem("cshow")) {
    //     setShowCampaigns(true);
    //   }
    // }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === 'qrcode' ||
                        whats.status === 'PAIRING' ||
                        whats.status === 'DISCONNECTED' ||
                        whats.status === 'TIMEOUT' ||
                        whats.status === 'OPENING'
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    const fetchChats = async () => {
        try {
            const { data } = await api.get('/chats/', {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: 'LOAD_CHATS', payload: data.records });
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div onClick={drawerClose}>
            <Can
                role={user.profile}
                perform="dashboard:view"
                yes={() => <ListItemLink to="/" primary="Dashboard" icon={<DashboardOutlinedIcon />} />}
            />

            <ListItemLink to="/tickets" primary={i18n.t('mainDrawer.listItems.tickets')} icon={<WhatsAppIcon />} />
            <ListItem button onClick={() => setOpenKanbanSubmenu((prev) => !prev)}>
                <ListItemIcon>
                    <LoyaltyRounded />
                </ListItemIcon>
                <ListItemText primary={i18n.t('kanban.title')} />
                {openKanbanSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse style={{ paddingLeft: 15 }} in={openKanbanSubmenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem onClick={() => history.push('/kanban')} button>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t('kanban.subMenus.list')} />
                    </ListItem>
                    <ListItem onClick={() => history.push('/tagsKanban')} button>
                        <ListItemIcon>
                            <CalendarToday />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t('kanban.subMenus.tags')} />
                    </ListItem>
                </List>
            </Collapse>

            <ListItemLink
                to="/quick-messages"
                primary={i18n.t('mainDrawer.listItems.quickMessages')}
                icon={<FlashOnIcon />}
            />

            <ListItemLink
                to="/contacts"
                primary={i18n.t('mainDrawer.listItems.contacts')}
                icon={<ContactPhoneOutlinedIcon />}
            />

            {showEmailSettings && (
                <ListItemLink
                    to="/emails/contacts/filters"
                    primary={i18n.t('mainDrawer.listItems.emailContacts')}
                    icon={<EmailIcon />}
                />
            )}

            {showSchedules && (
                <>
                    <ListItemLink
                        to="/schedules"
                        primary={i18n.t('mainDrawer.listItems.schedules')}
                        icon={<EventIcon />}
                    />
                </>
            )}

            {((user?.profile === 'admin' || userViewBoard) && showKanbanBoard) && (
                <ListItemLink
                    to={lastKanbanId ? `/opportunities/board/${lastKanbanId}` : '/opportunities'}
                    primary={i18n.t('mainDrawer.listItems.opportunities')}
                    icon={<AssessmentIcon />}
                />
            )}

            <ListItemLink to="/tags" primary={i18n.t('mainDrawer.listItems.tags')} icon={<LocalOfferIcon />} />

            {showInternalChat && (
                <>
                    <ListItemLink
                        to="/chats"
                        primary={i18n.t('mainDrawer.listItems.chats')}
                        icon={
                            <Badge color="secondary" variant="dot" invisible={invisible}>
                                <ForumIcon />
                            </Badge>
                        }
                    />
                </>
            )}

            <ListItemLink to="/helps" primary={i18n.t('mainDrawer.listItems.helps')} icon={<HelpOutlineIcon />} />

            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        <Divider />
                        <ListSubheader inset>{i18n.t('mainDrawer.listItems.administration')}</ListSubheader>
                        {showCampaigns && (
                            <>
                                <ListItem dense button onClick={() => setOpenCampaignSubmenu((prev) => !prev)}>
                                    <ListItemIcon>
                                        <EventAvailableIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={i18n.t('mainDrawer.listItems.campaigns')} />
                                    {openCampaignSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                <Collapse
                                    style={{ paddingLeft: 15 }}
                                    in={openCampaignSubmenu}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List dense component="div" disablePadding>
                                        <ListItem onClick={() => history.push('/campaigns')} button>
                                            <ListItemIcon>
                                                <ListIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Listagem" />
                                        </ListItem>
                                        <ListItem onClick={() => history.push('/contact-lists')} button>
                                            <ListItemIcon>
                                                <PeopleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Listas de Contatos" />
                                        </ListItem>
                                        <ListItem onClick={() => history.push('/campaigns-config')} button>
                                            <ListItemIcon>
                                                <SettingsOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Configuração" />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}

                        {user.super && (
                            <ListItemLink
                                to="/announcements"
                                primary={i18n.t('mainDrawer.listItems.annoucements')}
                                icon={<AnnouncementIcon />}
                            />
                        )}

                        {user.profile === 'admin' && showDocumentGenerator && (
                            <ListItemLink
                                to="/documents/templates"
                                primary={i18n.t('mainDrawer.listItems.documentTemplate')}
                                icon={<DescriptionIcon />}
                            />
                        )}
                        {user.profile === 'admin' && showEmailSettings && (
                            <ListItemLink
                                to="/emails/filters"
                                primary={i18n.t('mainDrawer.listItems.emailFilter')}
                                icon={<EmailIcon />}
                            />
                        )}

                        {user.profile === 'admin' && showEmailSettings && (
                            <ListItemLink
                                to="/ai/prompts"
                                primary={i18n.t('mainDrawer.listItems.aiPrompts')}
                                icon={<MemoryIcon />}
                            />
                        )}

                        {showExternalApi && (
                            <>
                                <ListItemLink
                                    to="/messages-api"
                                    primary={i18n.t('mainDrawer.listItems.messagesAPI')}
                                    icon={<CodeRoundedIcon />}
                                />
                            </>
                        )}

                        <ListItemLink
                            to="/users"
                            primary={i18n.t('mainDrawer.listItems.users')}
                            icon={<PeopleAltOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/queues"
                            primary={i18n.t('mainDrawer.listItems.queues')}
                            icon={<AccountTreeOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/connections"
                            primary={i18n.t('mainDrawer.listItems.connections')}
                            icon={
                                <Badge badgeContent={connectionWarning ? '!' : 0} color="error">
                                    <SyncAltIcon />
                                </Badge>
                            }
                        />

                        <ListItemLink
                            to="/ratings"
                            primary={i18n.t('mainDrawer.listItems.ratings')}
                            icon={<StarOutlineIcon />}
                        />

                        {/* <ListItemLink
              to="/integrations"
              primary={'Integrações'}
              icon={<AddToQueueRounded />}
            /> */}
                        <ListItemLink
                            to="/financeiro"
                            primary={i18n.t('mainDrawer.listItems.financeiro')}
                            icon={<LocalAtmIcon />}
                        />
                        <ListItemLink
                            to="/settings"
                            primary={i18n.t('mainDrawer.listItems.settings')}
                            icon={<SettingsOutlinedIcon />}
                        />

                        {/* {user.super && (
              <ListSubheader inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
            )} */}

                        {user.super && (
                            <ListItemLink
                                to="/companies"
                                primary={i18n.t('mainDrawer.listItems.companies')}
                                icon={<BusinessIcon />}
                            />
                        )}

                        {!collapsed && (
                            <React.Fragment>
                                <Divider />
                                {/* 
              // IMAGEM NO MENU
              <Hidden only={['sm', 'xs']}>
                <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />            
              </Hidden> 
              */}
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        padding: '10px',
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Versão: {version}
                                </Typography>
                            </React.Fragment>
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
