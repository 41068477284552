const handleEditColumn = async ({ action, columnId, column, setLoadingColumn, setKanbanData, enableLoading = false }) => {
    try {
        // Inicia o estado de loading para este card
        if (enableLoading) setLoadingColumn((prevLoading) => ({ ...prevLoading, [`column-${columnId}`]: true }));


        // Criar coluna
        if (action === 'create') {
            setKanbanData((prevState) => {
                const columnExists = prevState.column.some((col) => col.id === column.id);
                let updatedColumns = [...prevState.column];
                if (columnExists) { updatedColumns = updatedColumns.filter((col) => col.id !== column.id) };
                const newPosition = updatedColumns.length;
                updatedColumns.push({ ...column, position: newPosition, cards: [] });
                return { ...prevState, column: updatedColumns };
            });
        };

        // Movendo coluna de posição
        if (action === 'position') {
            const newPosition = column.position;
            setKanbanData((prevState) => {
                const columns = [...prevState.column];
                const columnIndex = columns.findIndex((col) => col.id === columnId);
                const [movedColumn] = columns.splice(columnIndex, 1);
                columns.splice(newPosition, 0, { ...movedColumn, position: newPosition });
                const reorderedColumns = columns.map((col, index) => ({ ...col, position: index }));
                return { ...prevState, column: reorderedColumns };
            });
        };

        // Atualizando dados da coluna
        if (action === 'update') {
            console.info({ action, columnId, column })
            setKanbanData((prevState) => {
                const columnExists = prevState.column.some((col) => col.id === columnId);
                return {
                    ...prevState, column: columnExists
                        ? prevState.column.map((col) => col.id === columnId ? { ...col, ...column } : col)
                        : [...prevState.column, { id: columnId, ...column }]
                };
            });
        };

        // Remover coluna
        if (action === 'delete') {
            setKanbanData((prevState) => {
                const columnExists = prevState.column.some((col) => col.id === columnId);
                if (columnExists) {
                    const updatedColumns = prevState.column.filter((col) => col.id !== columnId);
                    return { ...prevState, column: updatedColumns };
                }
                return prevState;
            });
        };

    } catch (error) {
        console.error({ action: 'erro ao editar card', error });
    } finally {
        if (enableLoading) setTimeout(() => setLoadingColumn((prevLoading) => ({ ...prevLoading, [`column-${columnId}`]: false })), 300);
    }
};

export default handleEditColumn;