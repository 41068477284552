import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { InputAdornment } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import ContactModal from '../ContactModal';

import PersonIcon from '@material-ui/icons/Person';

const filter = createFilterOptions({
    trim: true,
});

const ContactAutocomplete = ({ initialContact, onSelectContact }) => {
    const theme = useTheme();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [newContact, setNewContact] = useState('');
    const [selectedContact, setSelectedContact] = useState(initialContact || null);

    useEffect(() => {
        if (initialContact?.id !== undefined) {
            setOptions([initialContact]);
            setSelectedContact(initialContact);
        }
    }, [initialContact]);

    useEffect(() => {
        if (searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('contacts', {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam]);

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== '' && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const renderOption = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t('newTicketModal.add')} ${option.name}`;
        }
    };

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const handleSelectOption = (e, newValue) => {
        setSelectedContact(newValue);
        
        if (newValue?.number) {
            onSelectContact(newValue);
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name });
            setContactModalOpen(true);
        }
        if (newValue === null) {
            onSelectContact(null)
        }
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };

    const handleAddNewContact = (contact) => {
        setSelectedContact(contact);
        onSelectContact(contact);
    };

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContact}
            />
            <Autocomplete
                size="small"
                fullWidth
                options={options}
                loading={loading}
                clearOnBlur
                autoHighlight
                freeSolo
                clearOnEscape
                value={selectedContact}
                getOptionLabel={renderOptionLabel}
                renderOption={renderOption}
                filterOptions={createAddContactOption}
                onChange={handleSelectOption}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder='Digite para pesquisar o contato...'
                        variant="outlined"
                        autoFocus
                        onChange={(e) => setSearchParam(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" style={{
                                    '& .MuiTypography-root': {
                                        fontSize: '0.875rem',
                                        color: theme.palette.text.secondary,
                                    },
                                }}>
                                    <PersonIcon
                                        fontSize="small"
                                        style={{
                                            marginLeft: '7px'
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </>
    );
};

export default ContactAutocomplete;