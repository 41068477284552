import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Switch,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toggleItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: theme.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.1)' : '1px solid rgba(201, 209, 217, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  primaryText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 500,
  },
  secondaryText: {
    color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
    fontSize: 12,
    marginTop: theme.spacing(0.5),
  },
  switchBase: {
    color: '#484F58',
    '&.Mui-checked': {
      color: '#3287c9',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#3287c9',
    },
  },
  switchTrack: {
    backgroundColor: '#484F58',
  }
}));

const ToggleOption = ({ title, description, loading, defaultEnabled = false, onChange }) => {
  const classes = useStyles();
  const [enabled, setEnabled] = useState(defaultEnabled);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setEnabled(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    setEnabled(defaultEnabled);
  }, [defaultEnabled]);

  return (
    <Box className={classes.toggleItem}>
      <Box className={classes.textContainer}>
        <Typography className={classes.primaryText}>{title}</Typography>
        <Typography className={classes.secondaryText}>{description}</Typography>
      </Box>
      <Switch
        disabled={loading}
        color="primary"
        checked={enabled}
        onChange={handleChange}
        classes={{
          switchBase: classes.switchBase,
          track: classes.switchTrack
        }}
      />
    </Box>
  );
};

export default ToggleOption;