import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import ButtonWithSpinner from '../ButtonWithSpinner';
import api from '../../services/api';

const CreateTicketForForwardMessage = ({ modalOpen, onClose, handleCreateMessage, selectedContacts }) => {
    const [loading, setLoading] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState('');
    const [createdCount, setCreatedCount] = useState(0);
    const { user } = useContext(AuthContext);
    const ticketWithoutQueue = localStorage.getItem("ticketWithoutQueue") === 'false';

    const handleClose = () => {
        onClose(false);
        setSelectedQueue('');
        setCreatedCount(0);
    };

    const handleSaveTicket = async () => {
        if (!selectedQueue) {
            toast.error('Por favor, selecione uma fila para criar o atendimento.');
            return;
        }

        setLoading(true);
        setCreatedCount(0);

        try {
            const contactsToCreate = selectedContacts.filter(contact => !contact.isTicket);
            const existingTickets = selectedContacts.filter(contact => contact.isTicket).map(contact => contact.id);
            const successfulTickets = [];

            for (const contact of contactsToCreate) {
                try {
                    const { data } = await api.post('/tickets', {
                        contactId: contact.id,
                        queueId: selectedQueue,
                        userId: user.id,
                        status: 'open',
                    });
                    setCreatedCount(prev => prev + 1);
                    successfulTickets.push(data.contactId);
                } catch (error) {
                    console.error(`Erro ao criar atendimento para ${contact.name || contact.id}:`, error);
                    toastError(`Erro ao criar atendimento para ${contact.name || contact.id}`);
                }
            }

            const allCreatedTickets = [...existingTickets, ...successfulTickets];

            if (successfulTickets.length > 0) {
                toast.success('Todos os atendimentos válidos foram criados com sucesso!');
                handleCreateMessage({ selectedContacts: allCreatedTickets, selectedQueue });
            }

            setTimeout(() => handleClose(), 1000);
            
        } catch (err) {
            console.error('Erro inesperado:', err);
            toastError('Ocorreu um erro inesperado.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>Encaminhar mensagem</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    Você selecionou <strong>{selectedContacts.filter(contact => !contact.isTicket)?.length}</strong> contato(s) sem atendimento.
                    Para encaminhar esta mensagem, escolha a fila onde o atendimento será criado.
                </Typography>

                <Box mt={2}>
                    <Select
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={selectedQueue}
                        onChange={(e) => setSelectedQueue(e.target.value)}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                            transformOrigin: { vertical: 'top', horizontal: 'left' },
                            getContentAnchorEl: null,
                        }}
                    >
                        <MenuItem value="" disabled>Selecione uma fila</MenuItem>
                        {user.queues?.length > 0 ? (
                            user.queues.map(queue => (
                                <MenuItem key={queue.id} value={queue.id}>
                                    <ListItemText primary={queue.name} />
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>Nenhuma fila disponível</MenuItem>
                        )}
                    </Select>
                </Box>

                {loading && (
                    <Box mt={2}>
                        <Typography variant="body2" color="textSecondary">
                            Criando atendimentos: {createdCount} de {selectedContacts.length}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined" disabled={loading}>
                    Cancelar
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    color="primary"
                    onClick={handleSaveTicket}
                    disabled={!selectedQueue}
                    loading={loading}
                >
                    {loading ? `Criando (${createdCount}/${selectedContacts.length})` : "Criar e enviar"}
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    );
};

export default CreateTicketForForwardMessage;
