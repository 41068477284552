import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ArchiveIcon from '@material-ui/icons/Archive';
import React, { useContext, useEffect, useState } from 'react';

// Icons
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TaskAltIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessIcon from '@material-ui/icons/Business';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import EventIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/Info';
import NoteIcon from '@material-ui/icons/Note';
import PaymentIcon from '@material-ui/icons/Payment';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';

import { Tooltip, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AuthContext } from '../../context/Auth/AuthContext';
import { useEventHook } from '../../hooks/useEvent';
import api from '../../services/api';
import ContactAutocomplete from '../ContactAutocomplete';
import KanbanCardSelectContactsAutoComplete from '../KanbanCardSelectContactsAutoComplete';
import KanbanCardSelectUsersAutoComplete from '../KanbanCardSelectUsersAutoComplete';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import identificarGenero from '../../utils/identificarGenero';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '900px',
    overflowX: 'hidden',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2, 3),
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.25rem',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  dialogContent: {
    padding: 0,
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    minWidth: 120,
    textTransform: 'none',
    fontWeight: 500,
    '&.Mui-selected': {
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
  tabIcon: {
    marginRight: theme.spacing(1),
    fontSize: '1.25rem',
  },
  formContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  formLabel: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    display: 'block',
  },
  required: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
  },
  infoIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
  },
  sectionTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  sectionIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    fontSize: '1.2rem',
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  tagChip: {
    margin: theme.spacing(0.5),
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    fontWeight: 500,
    borderRadius: '4px',
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: fade(theme.palette.primary.main, 0.03),
    borderRadius: '4px',
  },
  footer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.mode === "dark" ? 'rgb(82 81 81)' : 'rgb(241 241 241)',
  },
  footerInfo: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    '& span': {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  actions: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  deleteButton: {
    color: theme.palette.warning.main,
    borderColor: fade(theme.palette.warning.main, 0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.warning.main, 0.04),
    },
  },
  updateButton: {
    fontWeight: 600,
    boxShadow: theme.shadows[2],
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  inputAdornment: {
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
    },
  },
  subtleTextField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
    },
  },
  tabPanel: {
    padding: 0,
  },
  iconWithText: {
    display: 'flex',
    alignItems: 'center',
  },
  inlineIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  headerSubtitle: {
    color: fade(theme.palette.primary.contrastText, 0.8),
  },
  opportunityAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
  },
}));

const initialLoading = {
  tags: false,
  users: false,
  contacts: false,
  columns: false,
  kanbans: false,
  card: false
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`opportunity-tabpanel-${index}`}
      aria-labelledby={`opportunity-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}

const CreateOrEditOpportunityCardModal = ({
  open,
  onClose,
  cardId: kanbanKardId,
  boardId,
  taskName = 'oportunidade',
  ownerName = 'vendedor'
}) => {

  const classes = useStyles();
  const theme = useTheme();
  const emitter = useEventHook();
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(0);
  const [hideEmptyFields, setHideEmptyFields] = useState(false);
  const cardId = kanbanKardId?.replace('card-', '') || null;
  const [formData, setFormData] = useState({ kanbanId: boardId });
  const [loading, setLoading] = useState(initialLoading);
  const [columns, setColumns] = useState([]);
  const [kanbans, setKanbans] = useState([]);
  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);
  const [status, setStatus] = useState([]);
  const [sources, setSources] = useState([]);


  const handleClose = () => {
    setFormData({ kanbanId: boardId });
    setColumns([]);
    setKanbans([]);
    setUsers([]);
    setTags([]);
    setActiveTab(0);
    onClose();
  };

  useEffect(() => {
    if (!open || !cardId) return;
    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, card: true }));
        const { data } = await api.get(`/card/kanban/${cardId}`);
        setFormData(data);
        if (data?.contact) {
          const { number, email } = data?.contact;
          if (number) setFormData((prev) => ({ ...prev, contactNumber: number }));
          if (email) setFormData((prev) => ({ ...prev, contactEmail: email }));
        }
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          setLoading((prevLoading) => ({ ...prevLoading, card: false }));
        }, 300);
      }
    })();
  }, [open, cardId]);

  const handleChangeAutoContacts = (newValues) => {
    setFormData((prev) => ({ ...prev, extraContacts: newValues }));
  };

  const handleChangeAutoFollowers = (newValues) => {
    setFormData((prev) => ({ ...prev, followers: newValues }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleUpdate = async () => {
    console.log('Form data submitted:', formData);
    try {
      const { data } = await api.put(`/card/kanban/${cardId}`, formData);
      emitter.emit("kanban-card", {
        action: 'update',
        kanbanId: data.kanbanId,
        kanbanColumnId: data.kanbanColumnId,
        cardId: cardId,
        card: data
      });
    } catch (error) {
      console.error(error)
    } finally {

    }
    handleClose();
  };

  const handleSelectContact = (contact) => {
    setFormData((prev) => ({
      ...prev,
      contactId: contact?.id || null,
      contactNumber: contact?.number || '',
      contactEmail: contact?.email || ''
    }));
  };

  useEffect(() => {
    if (!open) return;
    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, columns: true }));
        const { data } = await api.get(`/kanban/board/${boardId}`);
        if (data?.column) setColumns(data.column);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, columns: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, kanbans: true }));
        const { data } = await api.get(`/kanban/list`);
        setKanbans(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, kanbans: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, users: true }));
        const { data } = await api.get(`/follower/kanban/${boardId}`);
        setUsers(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, users: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, status: true }));
        const { data } = await api.get(`/priority/kanban/list/${boardId}`);
        setStatus(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, status: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, source: true }));
        const { data } = await api.get(`/source/kanban/list/${boardId}`);
        setSources(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, source: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, tags: true }));
        const { data } = await api.get(`/tag/kanban/list/${boardId}`);
        setTags(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, tags: false }));
      };
    })();

  }, [boardId, open]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="edit-opportunity-dialog"
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Avatar className={classes.opportunityAvatar}>
            {formData?.title?.charAt(0)}
          </Avatar>
          <Box>
            {formData?.title && <Typography variant="h6">
              Editando "{formData.title}"
            </Typography>}
            {/*
            <Typography variant="body2" className={classes.headerSubtitle}>
              <AccessTimeIcon
                style={{
                  fontSize: '0.9rem',
                  marginRight: 4,
                  marginBottom: 4,
                  verticalAlign: 'middle'
                }} />
              Ultima edição: 2 dias atrás
            </Typography>
          */}
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className={classes.tabsRoot}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          icon={<Box className={classes.iconWithText}><PersonIcon className={classes.tabIcon} />Detalhes</Box>}
          className={classes.tab}
        />

        {/*
        <Tab
          icon={<Box className={classes.iconWithText}><EventIcon className={classes.tabIcon} />Compromissos</Box>}
          className={classes.tab}
        />
        <Tab
          icon={<Box className={classes.iconWithText}><TaskAltIcon className={classes.tabIcon} />Tarefas</Box>}
          className={classes.tab}
        />
        <Tab
          icon={<Box className={classes.iconWithText}><PaymentIcon className={classes.tabIcon} />Pagamentos</Box>}
          className={classes.tab}
        />
        <Tab
          icon={<Box className={classes.iconWithText}><NoteIcon className={classes.tabIcon} />Notas e Comentarios</Box>}
          className={classes.tab}
        />
        */}

      </Tabs>
      {console.info({ formData })}
      <DialogContent className={classes.dialogContent}>
        <TabPanel value={activeTab} index={0} className={classes.tabPanel}>
          <Box className={classes.formContainer}>
            {/* Contato Detalhes Section */}
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography className={classes.sectionTitle}>
                <PersonIcon className={classes.sectionIcon} />
                Detalhes de contato
              </Typography>

              {/*
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideEmptyFields}
                    onChange={() => setHideEmptyFields(!hideEmptyFields)}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2">Ocultar campos vazios</Typography>
                }
              />
              */}

            </Box>
            <Box className={classes.section}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Contato <span className={classes.required}>*</span>
                    </Typography>
                    <ContactAutocomplete
                      initialContact={formData?.contact}
                      onSelectContact={handleSelectContact}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Email
                    </Typography>
                    <TextField
                      size='small'
                      value={formData?.contactEmail}
                      onChange={handleChange('contactEmail')}
                      placeholder="example@email.com"
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <EmailIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Telefone
                    </Typography>
                    <TextField
                      size='small'
                      value={formData?.contactNumber}
                      onChange={handleChange('contactNumber')}
                      placeholder="+00 00000 0000"
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <PhoneIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Typography className={classes.formLabel}>
                      Contatos extras (Max: 10)
                    </Typography>
                    <KanbanCardSelectContactsAutoComplete
                      label=""
                      placeholder="Buscar contatos..."
                      defaultValue={formData?.extraContacts || []}
                      onChange={handleChangeAutoContacts}
                      multiple={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Divider style={{ margin: '16px 0' }} />

            {/* Oportunidade Detalhes Section */}
            <Box className={classes.section}>
              <Typography className={classes.sectionTitle}>
                <BusinessIcon className={classes.sectionIcon} />
                {`Detalhes ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(taskName || 'oportunidade').toLowerCase()}`}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={formData?.description?.length > 200 ? 12 : 6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      {`Titulo ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(taskName || 'oportunidade').toLowerCase()}`} <span className={classes.required}>*</span>
                    </Typography>
                    <TextField
                      size='small'
                      value={formData.title}
                      onChange={handleChange('title')}
                      placeholder={`Titulo ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(taskName || 'oportunidade').toLowerCase()}`}
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={formData?.description?.length > 200 ? 12 : 6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      {`Descrição ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(taskName || 'oportunidade').toLowerCase()}`}
                    </Typography>
                    <TextField
                      multiline
                      maxRows={6}
                      size='small'
                      value={formData.description}
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 500) {
                          const newEvent = { ...e, target: { ...e.target, value: e.target.value } };
                          handleChange('description')(newEvent);
                        };
                      }}
                      placeholder="Descrição (Opcional)"
                      variant="outlined"
                      className={classes.subtleTextField}
                      helperText={`${formData?.description?.length || 0}/500`}
                      fullWidth
                      error={formData?.description?.length >= 500}
                    />
                  </FormControl>
                </Grid>
                {/*
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Quadro
                    </Typography>
                    <Select
                      disabled={loading.kanbans}
                      value={formData.kanbanId}
                      onChange={handleChange('kanbanId')}
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                    >
                      {!loading.kanbans &&
                        kanbans?.length === 0 &&
                        formData?.kanbanId ? (
                        <MenuItem value={formData.kanbanId}>
                          {"Carregando..."}
                        </MenuItem>
                      ) : null}
                      {kanbans?.map(kanban =>
                        <MenuItem
                          key={kanban.id}
                          value={kanban.id}>
                          {kanban.title}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Estágio (Coluna)
                    </Typography>
                    <Select
                      disabled={loading.columns}
                      value={formData?.kanbanColumnId || ""}
                      onChange={handleChange("kanbanColumnId")}
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                    >
                      {!loading.columns &&
                        columns?.length === 0 &&
                        formData?.kanbanColumnId ?
                        (
                          <MenuItem value={formData.kanbanColumnId}>
                            {"Carregando..."}
                          </MenuItem>
                        ) : null}
                      {columns?.map((column) => (
                        <MenuItem
                          key={column.id}
                          value={column.id}>
                          {column.title}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Grid>
                */}
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Status
                    </Typography>
                    <Select
                      value={formData?.kanbanPriorityId || null}
                      onChange={handleChange('kanbanPriorityId')}
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                    >
                      {!loading?.status &&
                        status?.length === 0 &&
                        formData?.kanbanPriorityId ?
                        (
                          <MenuItem value={formData.kanbanPriorityId}>
                            {"Carregando..."}
                          </MenuItem>
                        ) : null}
                      {status?.map((column) => (
                        <MenuItem
                          key={column.id}
                          value={column.id}>
                          <Chip
                            label={column.title}
                            size="small"
                            style={{
                              height: '18px',
                              borderRadius: '5px',
                              backgroundColor: fade(column.color, 0.3),
                              color: column.color
                            }}
                          />
                        </MenuItem>
                      ))}
                      <MenuItem value={null}>
                        <em>Nenhum</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      {`Valor ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(taskName || 'oportunidade').toLowerCase()}`}
                    </Typography>
                    <TextField
                      size='small'
                      value={formData.amount}
                      onChange={handleChange('amount')}
                      placeholder="0.00"
                      type='number'
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            R$
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      {`${capitalizeFirstLetter(ownerName || 'Vendedor')}`}
                      <Tooltip
                        title={`Selecione o ${ownerName.toLocaleLowerCase()} responsável por ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'esta' : 'este'} ${(taskName || 'oportunidade').toLowerCase()}`}>
                        <IconButton
                          size="small">
                          <InfoIcon
                            fontSize="small"
                            style={{
                              width: '18px',
                              height: '18px'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Select
                      disabled={loading.users}
                      value={formData?.ownerId || ""}
                      onChange={handleChange('ownerId')}
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                    >
                      {!loading.users &&
                        users?.length === 0 &&
                        formData?.ownerId ?
                        (
                          <MenuItem value={formData.ownerId}>
                            {"Carregando..."}
                          </MenuItem>
                        ) : null}
                      {users?.map((user) => (
                        <MenuItem
                          key={user.id}
                          value={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      <Box>
                        Seguidores
                      </Box>
                    </Typography>
                    <Autocomplete
                      disabled={loading.users}
                      size="small"
                      fullWidth
                      multiple
                      limitTags={3}
                      value={formData?.followers || []}
                      onChange={(event, newValue) => {
                        setFormData((prev) => ({ ...prev, followers: newValue }));
                      }}
                      id="followers-autocomplete"
                      options={users || []}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Buscar usuários..."
                          className={classes.subtleTextField}
                        />
                      )}
                      renderTags={(value, getTagProps) => (
                        value.map((option, index) => (
                          <Chip
                            style={{
                              margin: '2px',
                              backgroundColor: fade(theme.palette.primary.main, 0.3),
                              color: theme.palette.primary.main,
                              fontWeight: 500,
                              borderRadius: '4px',
                            }}
                            size="small"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      )}
                      renderOption={(option) => (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">{option.name}</Typography>
                        </Box>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Nome da empresa
                    </Typography>
                    <TextField
                      size='small'
                      value={formData.businessName}
                      onChange={handleChange('businessName')}
                      placeholder="Digite o nome da empresa"
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <BusinessIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      {`Fonte ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${(taskName || 'oportunidade').toLowerCase()}`}
                    </Typography>
                    <Select
                      value={formData?.kanbanOpportunitySourceId || null}
                      onChange={handleChange('kanbanOpportunitySourceId')}
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                    >
                      {!loading?.sources &&
                        sources?.length === 0 &&
                        formData?.kanbanOpportunitySourceId ?
                        (
                          <MenuItem value={formData.kanbanOpportunitySourceId}>
                            {"Carregando..."}
                          </MenuItem>
                        ) : null}
                      {sources?.map((source) => (
                        <MenuItem
                          key={source.id}
                          value={source.id}>
                          <Chip
                            label={source.title}
                            size="small"
                            style={{
                              height: '18px',
                              borderRadius: '5px',
                              backgroundColor: fade('#3287c9', 0.3),
                              color: '#3287c9'
                            }}
                          />
                        </MenuItem>
                      ))}
                      <MenuItem value={null}>
                        <em>Nenhum</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl} size='small'
                    disabled={loading?.tags}>
                    <Typography className={classes.formLabel}>
                      Tags
                    </Typography>
                    <Autocomplete
                      disabled={loading?.tags}
                      size="small"
                      fullWidth
                      multiple
                      limitTags={5}
                      value={formData?.tags || []}
                      onChange={(event, newValue) => {
                        setFormData({
                          ...formData,
                          ['tags']: newValue,
                        });
                      }}
                      id="multiple-limit-tags"
                      options={tags
                        .sort((a, b) => {
                          // First sort by group (global first, then board)
                          if (a.isGlobal !== b.isGlobal) {
                            return a.isGlobal ? -1 : 1; // Move global tags to the top
                          }
                          // Then sort by title within each group
                          return a.title.localeCompare(b.title);
                        })}
                      groupBy={(option) => option.isGlobal ? "Tags Globais" : "Tags do Quadro"}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Tags" />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          <Chip
                            size="small"
                            style={{
                              height: '18px',
                              borderRadius: '5px',
                              backgroundColor: option.color ? fade(option.color, 0.3) : '#e0e0e0',
                              color: option.color || '#757575',
                            }}
                            key={option.id}
                            label={option.title}
                            className={classes.chip}
                          />
                        </React.Fragment>
                      )}
                      renderTags={(value, getTagProps) => (
                        value.map((option, index) => (
                          <Chip
                            size="small"
                            style={{
                              height: '18px',
                              borderRadius: '5px',
                              backgroundColor: option.color ? fade(option.color, 0.3) : '#e0e0e0',
                              color: option.color || '#757575',
                              '& .MuiChip-deleteIconSmall': {
                                color: option.color || '#757575',
                              },
                            }}
                            key={option.id}
                            label={option.title}
                            className={classes.chip}
                            {...getTagProps({ index })}
                          />
                        ))
                      )}
                      renderGroup={(params) => (
                        <div key={params.key}>
                          <div style={{ backgroundColor: theme.mode === "dark" ? 'rgb(82 81 81)' : 'rgb(241 241 241)', padding: '4px 16px', fontWeight: 'bold' }}>
                            {params.group}
                          </div>
                          <div style={{ paddingLeft: '16px' }}>
                            {params.children}
                          </div>
                        </div>
                      )}
                    />
                  </FormControl>
                </Grid>

                {/*
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} size='small'>
                    <Typography className={classes.formLabel}>
                      Tags
                    </Typography>
                    <Select
                      value=""
                      displayEmpty
                      variant="outlined"
                      className={classes.subtleTextField}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <LocalOfferIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="">Add tags</MenuItem>
                      <MenuItem value="lead">Lead</MenuItem>
                      <MenuItem value="customer">Customer</MenuItem>
                    </Select>
                    <div className={classes.tagContainer}>
                      {(tags || []).map(tag => (
                        <Chip
                          key={tag}
                          label={tag}
                          onDelete={() => handleRemoveTag(tag)}
                          className={classes.tagChip}
                          size="small"
                        />
                      ))}
                    </div>
                  </FormControl>
                </Grid>
                */}
              </Grid>
            </Box>
          </Box>
        </TabPanel>

        {/* Compromissos e Calendário Tab Panel */}
        <TabPanel value={activeTab} index={1}>
          <Box className={classes.formContainer}>
            <Typography className={classes.sectionTitle}>
              <EventIcon className={classes.sectionIcon} />
              Book/Update Appointment
            </Typography>
            <Paper variant="outlined" style={{ padding: 24, textAlign: 'center' }}>
              <Typography variant="body1">
                No appointments scheduled yet
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 16 }}
                startIcon={<EventIcon />}
              >
                Schedule Appointment
              </Button>
            </Paper>
          </Box>
        </TabPanel>

        {/* Tarefas Tab Panel */}
        <TabPanel value={activeTab} index={2}>
          <Box className={classes.formContainer}>
            <Typography className={classes.sectionTitle}>
              <TaskAltIcon className={classes.sectionIcon} />
              Tarefas
            </Typography>
            <Paper variant="outlined" style={{ padding: 24, textAlign: 'center' }}>
              <Typography variant="body1">
                No tasks assigned to this opportunity
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 16 }}
                startIcon={<TaskAltIcon />}
              >
                Create New Task
              </Button>
            </Paper>
          </Box>
        </TabPanel>

        {/* Notas Tab Panel */}
        <TabPanel value={activeTab} index={3}>
          <Box className={classes.formContainer}>
            <Typography className={classes.sectionTitle}>
              <NoteIcon className={classes.sectionIcon} />
              Notas
            </Typography>
            <TextField
              multiline
              rows={6}
              placeholder="Add notes about this opportunity"
              variant="outlined"
              fullWidth
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
              >
                Save Note
              </Button>
            </Box>
          </Box>
        </TabPanel>

        {/* Pagamentos Tab Panel */}
        <TabPanel value={activeTab} index={4}>
          <Box className={classes.formContainer}>
            <Typography className={classes.sectionTitle}>
              <PaymentIcon className={classes.sectionIcon} />
              Pagamentos
            </Typography>
            <Paper variant="outlined" style={{ padding: 24, textAlign: 'center' }}>
              <Typography variant="body1">
                No payment information available
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 16 }}
                startIcon={<PaymentIcon />}
              >
                Add Payment
              </Button>
            </Paper>
          </Box>
        </TabPanel>
      </DialogContent>

      <div className={classes.footer}>
        <div className={classes.footerInfo}>
          Criada por: <span>{formData?.creator?.name}</span><br />
          Criada em: <span>{formData?.createdAt}</span><br />
          {`Protocolo ${identificarGenero((taskName || 'oportunidade')) === 'feminine' ? 'da' : 'do'} ${capitalizeFirstLetter(taskName || 'oportunidade')}:`} <span>{formData?.taskNumber}</span>
        </div>
      </div>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          startIcon={<ArchiveIcon />}
          className={classes.deleteButton}
          disabled
        >
          Arquivar
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        <Button
          onClick={() => handleClose()}
          variant="outlined"
          color="secondary"
          style={{ marginRight: 8 }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleUpdate}
          variant="contained"
          color="primary"
          className={classes.updateButton}
        >
          Atualizar
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default CreateOrEditOpportunityCardModal;