import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// Material UI Imports
import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

import {
    Cancel,
    CheckCircle,
    FilterList,
    Search
} from '@material-ui/icons';

// Components
import CompanyModal from '../../components/CompaniesModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import MainContainer from '../../components/MainContainer';
import TableRowSkeleton from '../../components/TableRowSkeleton';

// Context and Services
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import { useDate } from '../../hooks/useDate';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import formatSizeFormat from '../../utils/formatSizeFormat';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(2),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
        borderRadius: 8,
        boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.type === 'light' ? '#fff' : '#424242',
        borderRadius: 8,
        boxShadow: theme.palette.type === 'light' ? '0 1px 3px rgba(0,0,0,0.12)' : '0 1px 3px rgba(95, 95, 95, 0.12)',
        marginBottom: theme.spacing(2)
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        flex: 1
    },
    title: {
        fontSize: '1.25rem',
        fontWeight: 500,
        color: theme.palette.text.primary
    },
    counter: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        padding: '2px 8px',
        borderRadius: 12,
        fontSize: '0.875rem',
        fontWeight: 500
    },
    searchContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    table: {
        '& .MuiTableCell-head': {
            fontWeight: 600,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary
        },
        '& .MuiTableCell-body': {
            fontSize: '0.875rem'
        }
    },
    tableRow: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        }
    },
    statusCell: {
        fontWeight: 500
    },
    warningRow: {
        backgroundColor: '#fffead',
        '&:hover': {
            backgroundColor: '#fff5c0'
        }
    },
    dangerRow: {
        backgroundColor: '#fa8c8c',
        '&:hover': {
            backgroundColor: '#ff9e9e'
        }
    },
    formControl: {
        minWidth: 120
    },
    searchField: {
        minWidth: 200
    }
}));

const reducer = (state, action) => {
    if (action.type === 'LOAD_COMPANIES') {
        const companies = action.payload;
        const newCompanies = [];

        companies.forEach((company) => {
            const companyIndex = state.findIndex((u) => u.id === company.id);
            if (companyIndex !== -1) {
                state[companyIndex] = company;
            } else {
                newCompanies.push(company);
            }
        });

        return [...state, ...newCompanies];
    }

    if (action.type === 'UPDATE_COMPANIES') {
        const company = action.payload;
        const companyIndex = state.findIndex((u) => u.id === company.id);

        if (companyIndex !== -1) {
            state[companyIndex] = company;
            return [...state];
        } else {
            return [company, ...state];
        }
    }

    if (action.type === 'DELETE_COMPANIES') {
        const companyId = action.payload;
        const companyIndex = state.findIndex((u) => u.id === companyId);
        if (companyIndex !== -1) {
            state.splice(companyIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const Companies = () => {
    const classes = useStyles();
    const history = useHistory();
    const { dateToClient, datetimeToClient } = useDate();
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [deletingCompany, setDeletingCompany] = useState(null);
    const [companyModalOpen, setCompanyModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [companies, dispatch] = useReducer(reducer, []);
    const [status, setStatus] = useState('all');

    useEffect(() => {
        async function fetchData() {
            if (!user.super) {
                toast.error('Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando.');
                setTimeout(() => {
                    history.push(`/`);
                }, 1000);
            }
        }
        fetchData();
    }, [user.super, history]);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam, status]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchCompanies = async () => {
                try {
                    const { data } = await api.get('/companiesPlan/', {
                        params: {
                            searchParam, pageNumber, ...(status != 'all' ? { status } : {})
                        },
                    });
                    dispatch({ type: 'LOAD_COMPANIES', payload: data.companies });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchCompanies();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, status]);

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };

    const handleCloseCompanyModal = () => {
        setSelectedCompany(null);
        setCompanyModalOpen(false);
    };

    const handleDeleteCompany = async (companyId) => {
        try {
            await api.delete(`/companies/${companyId}`);
            toast.success(i18n.t('companies.toasts.deleted'));
        } catch (err) {
            toastError(err);
        }
        setDeletingCompany(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const renderStatus = (row) => {
        return row === false ? 'Não' : 'Sim';
    };

    const renderPlanValue = (row) => {
        return row.planId !== null
            ? row.plan.amount
                ? row.plan.amount.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                : '00.00'
            : '-';
    };

    const IconDueDate = ({ record }) => {
        if (moment(record.dueDate).isValid()) {
            const now = moment();
            const dueDate = moment(record.dueDate);
            const diff = dueDate.diff(now, 'days');
            if (diff >= 1 && diff <= 5) {
                return <WarningIcon fontSize="small" style={{ color: 'rgb(223 220 0)' }} />
            }
            if (diff <= 0) {
                return <WarningIcon fontSize="small" style={{ color: 'rgb(244 67 54)' }} />
            }

            return null;
        }
        return classes.tableRow;
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={deletingCompany && `${i18n.t('companies.confirmationModal.deleteTitle')} ${deletingCompany.name}?`}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteCompany(deletingCompany.id)}
            >
                {i18n.t('companies.confirmationModal.deleteMessage')}
            </ConfirmationModal>

            <CompanyModal
                open={companyModalOpen}
                onClose={handleCloseCompanyModal}
                aria-labelledby="form-dialog-title"
                companyId={selectedCompany && selectedCompany.id}
            />

            <Box className={classes.header}>
                <div className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        {i18n.t('companies.title')}
                    </Typography>
                    <span className={classes.counter}>
                        {companies.length}
                    </span>
                </div>

                <div className={classes.searchContainer}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.formControl}
                    >
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={status}
                            onChange={handleChangeStatus}
                            label="Status"
                        >
                            <MenuItem value={'all'}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <FilterList color="action" style={{ paddingRight: '5px' }} />
                                    Todas
                                </Box>
                            </MenuItem>
                            <MenuItem value={true}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CheckCircle style={{ color: '#4caf50', paddingRight: '5px' }} />
                                    Ativas
                                </Box>
                            </MenuItem>
                            <MenuItem value={false}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Cancel style={{ color: '#f44336', paddingRight: '5px' }} />
                                    Inativas
                                </Box>
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        size="small"
                        label="Buscar empresa"
                        variant="outlined"
                        className={classes.searchField}
                        value={searchParam}
                        onChange={(e) => setSearchParam(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="action" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </Box>

            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('companies.table.status')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.name')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.namePlan')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.value')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.createdAt')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.dueDate')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.lastLogin')}</TableCell>
                            <TableCell align="center">{i18n.t('companies.table.totalSize')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((company) => (
                            <TableRow key={company.id}>
                                <TableCell align="center" className={classes.statusCell}>
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                        {company.status ?
                                            <Tooltip title="Empresa Ativa">
                                                <CheckCircle fontSize="small" style={{ color: '#4caf50' }} />
                                            </Tooltip>
                                            :
                                            <Tooltip title="Empresa Inativa">
                                                <Cancel fontSize="small" style={{ color: '#f44336' }} />
                                            </Tooltip>
                                        }
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1" fontWeight="bold">{company.name}</Typography>
                                        <Typography variant="body2" color="textSecondary">{company.email}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center">{company.plan.name}</TableCell>
                                <TableCell align="center">R$ {renderPlanValue(company)}</TableCell>
                                <TableCell align="center">{dateToClient(company.createdAt)}</TableCell>
                                <TableCell align="center">
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
                                        <IconDueDate record={company} />
                                        <Typography variant="body2" fontWeight="bold">{dateToClient(company.dueDate)}</Typography>
                                    </Box>
                                    <Typography variant="caption" color="textSecondary">
                                        {company.recurrence}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    {moment().diff(moment(company.lastLogin), 'days')} dias
                                </TableCell>
                                <TableCell align="center">{formatSizeFormat(company?.companySize || 0)}</TableCell>
                            </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={9} />}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Companies;