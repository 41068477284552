import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import ToggleOption from '../../ToggleOption';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    color: theme.mode === 'light' ? '#000000' : '#FFF',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    color: theme.mode === 'light' ? '#000000' : '#FFF',
    paddingLeft: 0,
    paddingRight: 0,
  },
  switchBase: {
    color: '#0079BF',
    '&.Mui-checked': {
      color: '#0079BF',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0079BF',
    },
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
      color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
    },
    '& .MuiInput-root': {
      color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgba(255, 255, 255, 0.2)',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(255, 255, 255, 0.3)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0079BF',
    },
    '& .MuiSelect-icon': {
      color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
    },
  },
  description: {
    color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
    fontSize: 12,
    marginTop: theme.spacing(1),
  },
}));

const visibilityText = {
  private: 'Visível apenas para membros vinculados.',
  admin: 'Visível apenas para administradores.',
  public: 'Visível para todos os usuários.',
};

const initialLoading = {
  enableComments: false,
  viewer: false
};

const SettingsMenu = () => {

  const classes = useStyles();
  const [viewer, setViewer] = useState('private');
  const [enableComments, setEnableComments] = useState(false);
  const [loading, setLoading] = useState(initialLoading);
  const { boardId } = useParams();

  console.info({ enableComments, viewer });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/kanban/board/${boardId}`);
        console.info({ data });
        if (data?.enableComments) setEnableComments(data?.enableComments);
        if (data?.viewer) setViewer(data.viewer);
      } catch (error) {
        console.error(error);
      } finally {

      }
    })();
  }, [boardId]);

  const handleEditField = async ({ field, value }) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, [field]: true }));
      await api.put(`/kanban/${boardId}`, { [field]: value });
      toast.success('Configurações salvas com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, [field]: false }));
    }
  };

  return (
    <Box className={classes.root}>
      {/*
      <Typography variant="subtitle1" className={classes.sectionTitle}>
        Visibilidade
      </Typography>

      <FormControl className={classes.formControl} disabled={loading?.viewer}>
        <InputLabel id="visibility-select-label">Visibilidade do Quadro</InputLabel>
        <Select
          labelId="visibility-select-label"
          id="visibility-select"
          disabled={loading?.viewer}
          value={viewer}
          onChange={e => {
            setViewer(e.target.value);
            handleEditField({ field: 'viewer', value: e.target.value })
          }}
        >
          <MenuItem value="private">Vinculados ao quadro</MenuItem>
          <MenuItem value="admin">Administradores</MenuItem>
          <MenuItem value="public">Público</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="body2" className={classes.description}>
        {visibilityText[viewer]}
      </Typography>
     */}

      <Typography variant="subtitle1" className={classes.sectionTitle}>
        Permissões
      </Typography>

      <List>
        <ListItem className={classes.listItem}>
          <ToggleOption
            title="Comentários"
            description="Permitir que membros adicionem comentários aos cartões"
            defaultEnabled={enableComments}
            loading={loading?.enableComments}
            onChange={(value) => {
              setEnableComments(value);
              handleEditField({ field: 'enableComments', value });
            }}
          />
        </ListItem>

        {/*
        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Votação"
            secondary="Permitir que membros votem em cartões"
            secondaryTypographyProps={{ style: { color: '#C9D1D9', fontSize: 12 } }}
          />
          <Switch
            color="primary"
            defaultChecked={false}
            classes={{ switchBase: classes.switchBase }}
          />
        </ListItem>
       

        <ListItem className={classes.listItem}>
          <ListItemText
            primary="Auto-arquivamento"
            secondary="Arquivar automaticamente cartões concluídos após 7 dias"
            secondaryTypographyProps={{ style: { color: '#C9D1D9', fontSize: 12 } }}
          />
          <Switch
            color="primary"
            defaultChecked
            classes={{ switchBase: classes.switchBase }}
          />
        </ListItem> 
        */}

      </List>
      {
        /* 
      <Typography variant="subtitle1" className={classes.sectionTitle}>
        Notificações
      </Typography>

      <List>
      
        <ListItem className={classes.listItem}>
        <ListItemText
          primary="Notificações por e-mail"
          secondary="Receber e-mails para atividades importantes"
          secondaryTypographyProps={{ style: { color: '#C9D1D9', fontSize: 12 } }}
        />
        <Switch
          color="primary"
          defaultChecked
          classes={{ switchBase: classes.switchBase }}
        />
      </ListItem>
       

      <ListItem className={classes.listItem}>
        <ListItemText
          primary="Notificações push"
          secondary="Receber notificações push para atividades importantes (Usuários logados)"
          secondaryTypographyProps={{ style: { color: '#C9D1D9', fontSize: 12 } }}
        />
        <Switch
          color="primary"
          defaultChecked
          classes={{ switchBase: classes.switchBase }}
        />
      </ListItem> 
    </List>  */
      }
    </Box>
  );
};

export default SettingsMenu;