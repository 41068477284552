const calculateTotalValueCardsAmount = (kanbanCards) => {
    if (!Array.isArray(kanbanCards)) {
        console.warn('Entrada inválida: kanbanCards não é um array.');
        return '0,00';
    }

    const total = kanbanCards.reduce((sum, card) => {
        if (!card.amount) return sum; // Ignora se amount for null, undefined ou vazio

        const amount = parseFloat(card.amount);
        if (isNaN(amount)) {
            console.warn(`Valor inválido encontrado em card.id ${card.id}: ${card.amount}`);
            return sum;
        }

        return sum + amount;
    }, 0);

    return total;
};

export default calculateTotalValueCardsAmount;
