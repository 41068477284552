import filterKanban from "./filterKanban";

/**
 * Transforma dados do Kanban para o formato adequado para a UI
 * @param {Object} data - Dados do Kanban
 * @param {Object} filters - Filtros aplicados, incluindo sortBy
 * @returns {Object} Dados transformados
 */
const transformKanbanData = (data, filters = {}) => {
    if (!data || !data.column || data.column.length === 0) return null;

    const transformedData = {
        columns: {},
        cards: {},
        columnOrder: []
    };

    // Criar as colunas e o order de colunas
    data.column.forEach((col) => {
        const columnId = `column-${col.id}`;
        console.info({ transformKanbanData: col.cards })
        // Ordenar os cards dentro da coluna pelo campo "order"
        let sortedCards = [...col?.cards || []].sort((a, b) => (a.order ?? 9999999) - (b.order ?? 9999999));

        // Aplicar ordenação personalizada se necessário
        if (filters && filters.sortBy && filters.sortBy !== 'default') {
            console.log(`Aplicando ordenação ${filters.sortBy} para coluna ${col.title}`);
            sortedCards = filterKanban({ cards: sortedCards, order: filters.sortBy });
        }

        // Mapear IDs dos cards na ordem correta
        const cardIds = sortedCards.map(card => `card-${card.id}`);

        // Adicionar coluna
        transformedData.columns[columnId] = {
            id: columnId,
            title: col.title,
            color: col.color,
            cardIds: cardIds  // IDs já na ordem correta
        };

        // Adicionar à ordem de colunas
        transformedData.columnOrder.push(columnId);

        // Processar cards e adicionar ao objeto de cards
        sortedCards.forEach(card => {
            const cardId = `card-${card.id}`;
            transformedData.cards[cardId] = {
                id: cardId,
                name: card.title,
                description: card.description || '',
                tags: card.tags,
                amount: card.amount,
                owner: card.owner,
                order: card.order,
                creator: card.creator,
                businessName: card.businessName,
                opportunitySource: card.opportunitySource,
                createdAt: card.createdAt,
                kanbanPriorityLevel: card.kanbanPriorityLevel,
                taskNumber: card.taskNumber,
                kanbanOpportunitySource: card.kanbanOpportunitySource,
            };
        });
    });

    transformedData.columnOrder.sort((a, b) => {
        const indexA = data.column.findIndex(col => `column-${col.id}` === a);
        const indexB = data.column.findIndex(col => `column-${col.id}` === b);

        const posA = indexA >= 0 ? data.column[indexA].position : 9999999;
        const posB = indexB >= 0 ? data.column[indexB].position : 9999999;

        return posA - posB;
    });

    return transformedData;
};

export default transformKanbanData;