import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomRevertToast = ({ title, description, onRevert }) => {
    // Estado para acompanhar alguma ação que possa ser revertida
    const [counter, setCounter] = useState(0);
    const [lastAction, setLastAction] = useState(null);

    // Função para realizar uma ação
    const performAction = () => {
        const previousValue = counter;
        const newValue = counter + 10;

        setCounter(newValue);
        setLastAction({
            previousValue,
            newValue,
            timestamp: new Date().toLocaleTimeString()
        });

        // Mostra o toast personalizado com botão de reverter
        showRevertToast(previousValue);
    };

    // Função para reverter a ação
    const revertAction = (previousValue) => {
        setCounter(previousValue);
        toast.success(`Ação revertida! Valor retornou para ${previousValue}`, {
            position: "bottom-center",
            autoClose: 3000
        });
    };

    // Função para mostrar o toast com botão de reverter
    const showRevertToast = (previousValue) => {
        toast(
            ({ closeToast }) => (
                <div className="custom-toast-content">
                    <div className="toast-header">
                        <span className="toast-icon">🔔</span>
                        <h4 className="toast-title">Ação Realizada</h4>
                    </div>

                    <div className="toast-body">
                        <p>Valor aumentado para <strong>{counter}</strong></p>
                        <p className="toast-description">
                            Esta ação pode ser revertida nos próximos 10 segundos.
                        </p>

                        <div className="toast-actions">
                            <button
                                className="revert-button"
                                onClick={() => {
                                    revertAction(previousValue);
                                    closeToast();
                                }}
                            >
                                Reverter Ação
                            </button>
                        </div>
                    </div>

                    <div className="toast-progress-container">
                        <div className="toast-progress-bar"></div>
                    </div>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                className: "custom-toast-container",
                bodyClassName: "custom-toast-wrapper",
                progressClassName: "custom-toast-progress",
                style: {
                    background: "transparent",
                    boxShadow: "none"
                }
            }
        );
    };

    return (
        <div className="app-container">
            <h1>Demo: Toast com Botão de Reverter</h1>

            <div className="counter-container">
                <h2>Contador Atual: <span className="counter-value">{counter}</span></h2>

                <button className="action-button" onClick={performAction}>
                    Aumentar Contador (+10)
                </button>
            </div>

            {lastAction && (
                <div className="action-history">
                    <h3>Última Ação:</h3>
                    <p>
                        <span>Horário: {lastAction.timestamp}</span><br />
                        <span>Alteração: {lastAction.previousValue} → {lastAction.newValue}</span>
                    </p>
                </div>
            )}

            {/* Contêiner do Toast */}
            <ToastContainer />

            {/* Estilos personalizados */}
            <style jsx global>{`
        /* Estilos gerais */
        body {
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          background-color: #f5f7fa;
        }
        
        .app-container {
          max-width: 600px;
          margin: 40px auto;
          padding: 30px;
          background-color: white;
          border-radius: 12px;
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        }
        
        h1 {
          color: #2c3e50;
          margin-bottom: 30px;
          border-bottom: 2px solid #eaeaea;
          padding-bottom: 10px;
        }
        
        .counter-container {
          margin-bottom: 30px;
          padding: 20px;
          background-color: #f8f9fa;
          border-radius: 8px;
          text-align: center;
        }
        
        .counter-value {
          color: #3498db;
          font-size: 1.5em;
          font-weight: bold;
        }
        
        .action-button {
          background-color: #3498db;
          color: white;
          border: none;
          padding: 12px 24px;
          font-size: 16px;
          border-radius: 6px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-top: 15px;
          font-weight: 600;
        }
        
        .action-button:hover {
          background-color: #2980b9;
        }
        
        .action-history {
          background-color: #f0f4f8;
          padding: 15px;
          border-radius: 8px;
          border-left: 4px solid #3498db;
        }
        
        .action-history h3 {
          margin-top: 0;
          color: #2c3e50;
          font-size: 1.1em;
        }
        
        /* Estilos personalizados do Toast */
        .custom-toast-container {
          width: 350px !important;
          padding: 0 !important;
          backdrop-filter: blur(10px);
        }
        
        .custom-toast-content {
          background-color: rgba(255, 255, 255, 0.97);
          border-radius: 10px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
          overflow: hidden;
          border-left: 4px solid #3498db;
        }
        
        .toast-header {
          padding: 12px 15px;
          background-color: #f8fafc;
          border-bottom: 1px solid #edf2f7;
          display: flex;
          align-items: center;
        }
        
        .toast-icon {
          font-size: 20px;
          margin-right: 10px;
        }
        
        .toast-title {
          margin: 0;
          font-size: 16px;
          color: #2d3748;
          font-weight: 600;
        }
        
        .toast-body {
          padding: 15px;
          color: #4a5568;
        }
        
        .toast-description {
          font-size: 14px;
          margin-top: 5px;
          color: #718096;
        }
        
        .toast-actions {
          margin-top: 15px;
          display: flex;
          justify-content: flex-end;
        }
        
        .revert-button {
          background-color: #e53e3e;
          color: white;
          border: none;
          padding: 8px 16px;
          border-radius: 5px;
          cursor: pointer;
          font-weight: 600;
          transition: all 0.2s;
          box-shadow: 0 2px 4px rgba(229, 62, 62, 0.3);
        }
        
        .revert-button:hover {
          background-color: #c53030;
          transform: translateY(-1px);
          box-shadow: 0 4px 6px rgba(229, 62, 62, 0.4);
        }
        
        .toast-progress-container {
          height: 4px;
          background-color: #edf2f7;
          width: 100%;
          position: relative;
          overflow: hidden;
        }
        
        .toast-progress-bar {
          height: 100%;
          background: linear-gradient(90deg, #3498db, #2980b9);
          width: 100%;
          position: absolute;
          animation: countdown 10s linear forwards;
        }
        
        @keyframes countdown {
          from {
            width: 100%;
          }
          to {
            width: 0%;
          }
        }
      `}</style>
        </div>
    );
};

export default CustomRevertToast;