import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  fade,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  AttachMoney as AttachMoneyIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  Label as LabelIcon,
  Person as PersonIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  Sort as SortIcon,
  TrendingDown as TrendingDownIcon,
  TrendingUp as TrendingUpIcon
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/Auth/AuthContext';
import api from '../../../../services/api';
import getInitialsName from '../../../../utils/getInitialsName';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';

const SORTING_OPTIONS = [
  { value: 'default', label: 'Padrão' },
  { value: 'newest', label: 'Mais Recentes', icon: <AccessTimeIcon /> },
  { value: 'oldest', label: 'Mais Antigos', icon: <AccessTimeIcon style={{ transform: 'rotate(180deg)' }} /> },
  { value: 'highestValue', label: 'Maior Valor', icon: <TrendingUpIcon /> },
  { value: 'lowestValue', label: 'Menor Valor', icon: <TrendingDownIcon /> },
];

const DATE_RANGES = [
  { value: 'all', label: 'Todo o período' },
  { value: 'yesterday', label: 'Ontem' },
  { value: 'today', label: 'Hoje' },
  { value: 'week', label: 'Semana' },
  { value: 'month', label: 'Mês' },
  { value: 'year', label: 'Ano' },
  { value: 'custom', label: 'Personalizado' },
];

// Estilos personalizados usando makeStyles
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 16,
    overflow: 'hidden',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
    maxHeight: '85vh',
  },
  dialogTitle: {
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${fade(theme.palette.divider, 0.05)}`,
  },
  dialogContent: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${fade(theme.palette.divider, 0.05)}`,
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: '5px',
    fontWeight: 500,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    "& .MuiChip-label": {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  chipSelected: {
    fontWeight: 600,
  },
  tabRoot: {
    minWidth: 'auto',
    padding: theme.spacing(1.5, 2),
    fontWeight: 500,
    textTransform: 'none',
    fontSize: '0.9rem',
  },
  tabSelected: {
    fontWeight: 600,
  },
  tabIndicator: {
    height: 3,
    borderRadius: 3,
  },
  sliderRoot: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sortingOption: {
    padding: theme.spacing(1),
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    border: `1px solid ${fade(theme.palette.divider, 0.1)}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.action.hover, 0.05),
    },
  },
  sortingOptionSelected: {
    backgroundColor: fade(theme.palette.primary.main, 0.08),
    borderColor: fade(theme.palette.primary.main, 0.5),
  },
  sortingIcon: {
    marginRight: theme.spacing(1),
    fontSize: 0,
    color: theme.palette.text.secondary,
  },
  sortingIconSelected: {
    color: theme.palette.primary.main,
  },
  resetButton: {
    borderRadius: 8,
    textTransform: 'none',
    fontWeight: 500,
  },
  applyButton: {
    borderRadius: 8,
    textTransform: 'none',
    fontWeight: 600,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: '0.9rem',
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  sectionIcon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  searchField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
    },
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: '0.8rem',
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  priorityDot: {
    display: 'inline-block',
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  filterSection: {
    marginBottom: theme.spacing(3),
  },
  statusChip: {
    height: 24,
    borderRadius: 12,
  },
  clearFilterButton: {
    marginLeft: 'auto',
    padding: 0,
    fontSize: '0.75rem',
    textTransform: 'none',
    color: theme.palette.text.secondary,
  },
}));

// Componente principal
const KanbanBoardFiltersModal = ({
  open,
  onClose,
  boardId,
  initialFilters = {},
  onApplyFilters,
  kanbanData
}) => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState({});

  // Estados para os filtros
  const [minValue, setMinValue] = useState(initialFilters.minValue);
  const [maxValue, setMaxValue] = useState(initialFilters.maxValue);
  const [selectedStatus, setSelectedStatus] = useState(initialFilters?.status || []);
  const [sortBy, setSortBy] = useState(initialFilters.sortBy);
  const [selectedOwners, setSelectedOwners] = useState(initialFilters?.owners || []);
  const [selectedTags, setSelectedTags] = useState(initialFilters?.tags || []);
  const [dateRange, setDateRange] = useState(initialFilters.dateRange);
  const [startDate, setStartDate] = useState(initialFilters.startDate);
  const [endDate, setEndDate] = useState(initialFilters.endDate);
  const [showCustomDateRange, setShowCustomDateRange] = useState(dateRange === 'custom');
  const [showMyOnly, setShowMyOnly] = useState(initialFilters.showMyOnly);
  const [showTaskNumber, setShowTaskNumber] = useState(initialFilters.showTaskNumber);
  
  // Estados para dados
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setMinValue(initialFilters.minValue);
    setMaxValue(initialFilters.maxValue);
    setSelectedStatus(initialFilters.status);
    setSortBy(initialFilters.sortBy);
    setSelectedOwners(initialFilters.owners);
    setSelectedTags(initialFilters.tags);
    setDateRange(initialFilters.dateRange);
    setStartDate(initialFilters.startDate);
    setEndDate(initialFilters.endDate);
    setShowMyOnly(initialFilters.showMyOnly);
    setShowTaskNumber(initialFilters.showTaskNumber);
  }, [initialFilters]);

  useEffect(() => {
    if (!open) return;

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, users: true }));
        const { data } = await api.get(`/follower/kanban/${boardId}`);
        setUsers(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, users: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, status: true }));
        const { data } = await api.get(`/priority/kanban/list/${boardId}`);
        setStatus(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, status: false }));
      };
    })();

    (async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, tags: true }));
        const { data } = await api.get(`/tag/kanban/list/${boardId}`);
        setTags(data);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, tags: false }));
      };
    })();

  }, [boardId, open]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => onClose();

  const applyFilters = () => {
    onApplyFilters({
      minValue,
      maxValue,
      status: selectedStatus,
      sortBy,
      owners: selectedOwners,
      tags: selectedTags,
      dateRange,
      startDate,
      endDate,
      showMyOnly,
      showTaskNumber
    });
    handleClose();
  };

  const resetFilters = () => {
    setMinValue(0);
    setMaxValue(1000000000);
    setSelectedStatus([]);
    setSortBy('default');
    setSelectedOwners([]);
    setSelectedTags([]);
    setDateRange('all');
    setStartDate(null);
    setEndDate(null);
    setShowCustomDateRange(false);
    setShowMyOnly(false);
    setShowTaskNumber(false);
  };

  const resetSectionFilters = (section) => {
    switch (section) {
      case 'owners':
        setSelectedOwners([]);
        break;
      case 'status':
        setSelectedStatus([]);
        break;
      case 'tags':
        setSelectedTags([]);
        break;
      case 'valueRange':
        setMinValue(0);
        setMaxValue(1000000000);
        break;
      case 'dateRange':
        setDateRange('all');
        setStartDate(null);
        setEndDate(null);
        setShowCustomDateRange(false);
        break;
      default:
        break;
    }
  };

  // Handler para alterações no período
  useEffect(() => {
    setShowCustomDateRange(dateRange === 'custom');
  }, [dateRange]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Renderização dos conteúdos das abas
  const renderBasicFilters = () => (
    <>
      {/* Opções Rápidas */}
      <Box className={classes.filterSection}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showMyOnly}
              onChange={(e) => setShowMyOnly(e.target.checked)}
              name="showMyOnly"
              color="primary"
              size="small"
              className={classes.checkbox}
            />
          }
          label="Mostrar apenas minhas tarefas"
        />
      </Box>
      <Box className={classes.filterSection}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showTaskNumber}
              onChange={(e) => setShowTaskNumber(e.target.checked)}
              name="showTaskNumber"
              color="primary"
              size="small"
              className={classes.checkbox}
            />
          }
          label="Exibir protocolo"
        />
      </Box>
      <Divider className={classes.divider} />

      <Box className={classes.filterSection}>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Typography className={classes.sectionTitle}>
            <RadioButtonCheckedIcon className={classes.sectionIcon} />
            Status
          </Typography>
          {selectedStatus?.length > 0 && (
            <Button
              className={classes.clearFilterButton}
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() => resetSectionFilters('status')}
            >
              Limpar
            </Button>
          )}
        </Box>
        <Autocomplete
          multiple
          limitTags={3}
          options={status}
          getOptionLabel={(option) => option.title}
          value={selectedStatus}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, newValue) => setSelectedStatus(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Selecione os status"
              size="small"
              className={classes.searchField}
            />
          )}
          renderOption={(option) => (
            <Box display="flex" alignItems="center">
              <Box
                className={classes.priorityDot}
                style={{ backgroundColor: option.color }}
              />
              <Typography variant="body2">{option.title}</Typography>
            </Box>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.title}
                size="small"
                style={{
                  backgroundColor: fade(option.color, 0.3),
                  color: option.color,
                  borderColor: option.color,
                }}
                {...getTagProps({ index })}
                className={classes.chip}
              />
            ))
          }
        />
      </Box>

      <Divider className={classes.divider} />

      <Box className={classes.filterSection}>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Typography className={classes.sectionTitle}>
            <AccessTimeIcon className={classes.sectionIcon} />
            Período
          </Typography>
          {dateRange !== 'all' && (
            <Button
              className={classes.clearFilterButton}
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() => resetSectionFilters('dateRange')}
            >
              Limpar
            </Button>
          )}
        </Box>
        <Box display="flex" flexWrap="wrap" marginY={-0.5} justifyContent={'space-between'}>
          {DATE_RANGES.map((range) => (
            <Chip
              key={range.value}
              label={range.label}
              className={`${classes.chip} ${dateRange === range.value ? classes.chipSelected : ''}`}
              variant={dateRange === range.value ? "default" : "outlined"}
              color={dateRange === range.value ? "primary" : "default"}
              onClick={() => setDateRange(range.value)}
            />
          ))}
        </Box>

        {showCustomDateRange && (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Data Inicial"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  variant="outlined"
                  size="small"
                  className={classes.searchField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Data Final"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  variant="outlined"
                  size="small"
                  className={classes.searchField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );

  const renderAdvancedFilters = () => (
    <>
      {/* Faixa de Valor */}
      <Box className={classes.filterSection}>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Typography className={classes.sectionTitle}>
            <AttachMoneyIcon className={classes.sectionIcon} />
            Faixa de Valor
          </Typography>
          {(minValue > 0 || maxValue < 1000000000) && (
            <Button
              className={classes.clearFilterButton}
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() => resetSectionFilters('valueRange')}
            >
              Limpar
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Valor Mínimo"
              variant="outlined"
              size="small"
              type="number"
              value={minValue}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value <= maxValue) {
                  setMinValue(value);
                } else {
                  setMinValue(maxValue);
                }
              }}
              inputProps={{ max: maxValue }}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              className={classes.searchField}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Valor Máximo"
              variant="outlined"
              size="small"
              type="number"
              value={maxValue}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value <= 1000000000) {
                  setMaxValue(value);
                } else {
                  setMaxValue(1000000000);
                }
              }}
              inputProps={{ max: 1000000000 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              className={classes.searchField}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider className={classes.divider} />

      {/* Responsáveis */}
      <Box className={classes.filterSection}>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Typography className={classes.sectionTitle}>
            <PersonIcon className={classes.sectionIcon} />
            {capitalizeFirstLetter(kanbanData?.ownerName || 'vendedor')}
          </Typography>
          {selectedOwners?.length > 0 && (
            <Button
              className={classes.clearFilterButton}
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() => resetSectionFilters('owners')}
            >
              Limpar
            </Button>
          )}
        </Box>
        <Autocomplete
          multiple
          limitTags={2}
          options={users}
          getOptionLabel={(option) => option.name}
          value={selectedOwners}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, newValue) => setSelectedOwners(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={`Selecione ${capitalizeFirstLetter(kanbanData?.ownerName || 'vendedor')}`}
              size="small"
              className={classes.searchField}
            />
          )}
          renderOption={(option) => (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{option.name}</Typography>
            </Box>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.name}
                size="small"
                {...getTagProps({ index })}
                className={classes.chip}
              />
            ))
          }
        />
      </Box>

      <Divider className={classes.divider} />

      {/* Tags */}
      <Box className={classes.filterSection}>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Typography className={classes.sectionTitle}>
            <LabelIcon className={classes.sectionIcon} />
            Tags
          </Typography>
          {selectedTags?.length > 0 && (
            <Button
              className={classes.clearFilterButton}
              startIcon={<ClearIcon fontSize="small" />}
              onClick={() => resetSectionFilters('tags')}
            >
              Limpar
            </Button>
          )}
        </Box>
        <Autocomplete
          multiple
          limitTags={3}
          options={tags}
          getOptionLabel={(option) => option.title}
          value={selectedTags}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, newValue) => setSelectedTags(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Selecione as tags"
              size="small"
              className={classes.searchField}
            />
          )}
          renderOption={(option) => (
            <Box display="flex" alignItems="center">
              <Box
                className={classes.priorityDot}
                style={{ backgroundColor: option.color }}
              />
              <Typography variant="body2">{option.title}</Typography>
            </Box>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.title}
                size="small"
                style={{
                  backgroundColor: fade(option.color, 0.1),
                  color: option.color,
                  borderColor: option.color,
                }}
                {...getTagProps({ index })}
                className={classes.chip}
              />
            ))
          }
        />
      </Box>
    </>
  );

  const renderSortOptions = () => (
    <>
      <Box className={classes.filterSection}>
        <Typography className={classes.sectionTitle}>
          <SortIcon className={classes.sectionIcon} />
          Ordenar Por
          <Tooltip title="Ao mudar o padrão, os cards no Kanban serão ajustados conforme a regra selecionada, independentemente da posição das oportunidades.">
            <InfoIcon className={classes.sectionIcon} style={{ marginLeft: '5px', color: '#616161' }} />
          </Tooltip>
        </Typography>
        <Grid container spacing={1}>
          {SORTING_OPTIONS.map((option) => (
            <Grid item xs={12} key={option.value}>
              <Box
                className={`${classes.sortingOption} ${sortBy === option.value ? classes.sortingOptionSelected : ''
                  }`}
                onClick={() => setSortBy(option.value)}
              >
                {option.icon && (
                  <Box
                    component="span"
                    className={`${classes.sortingIcon} ${sortBy === option.value ? classes.sortingIconSelected : ''
                      }`}
                  >
                    {option.icon}
                  </Box>
                )}
                <Typography variant="body2">
                  {option.label}
                </Typography>
                {sortBy === option.value && (
                  <CheckIcon
                    fontSize="small"
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <FilterListIcon color="primary" style={{ marginRight: 8 }} />
            <Typography variant="h6">Filtros</Typography>
          </Box>
          <IconButton edge="end" color="inherit" onClick={handleClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box px={3} pt={1}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{
            indicator: classes.tabIndicator,
          }}
        >
          <Tab
            label="Filtros Básicos"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
          <Tab
            label="Filtros Avançados"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
          <Tab
            label="Ordenação"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
        </Tabs>
      </Box>

      <DialogContent className={classes.dialogContent} dividers>
        {tabValue === 0 && renderBasicFilters()}
        {tabValue === 1 && renderAdvancedFilters()}
        {tabValue === 2 && renderSortOptions()}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={resetFilters}
          color="inherit"
          className={classes.resetButton}
          startIcon={<ClearIcon />}
        >
          Limpar Filtros
        </Button>
        <Button
          onClick={applyFilters}
          color="primary"
          variant="contained"
          className={classes.applyButton}
          startIcon={<CheckIcon />}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KanbanBoardFiltersModal;