import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { EventProvider } from './hooks/useEvent';
import * as serviceworker from './serviceWorker';

import App from './App';


const rootElement = document.getElementById('root');


if (rootElement) {
    createRoot(rootElement).render(
        <BrowserRouter>
            <EventProvider>
                <HelmetProvider>
                    <CssBaseline>
                        <App />
                    </CssBaseline>
                </HelmetProvider>
            </EventProvider>
        </BrowserRouter>
    );
} else {
    console.error('Elemento root não encontrado. Certifique-se de que há um elemento com o id "root" no seu HTML.');
}


serviceworker.register();
