import {
  AppBar,
  Badge,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  FilterList as FilterListIcon,
  Search as SearchIcon
} from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import TableChartIcon from '@material-ui/icons/TableChart';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth/AuthContext';
import { useEventHook } from '../../../hooks/useEvent';
import api from '../../../services/api';
import countChangedFiltersKanban from '../../../utils/countChangedFiltersKanban';
import KanbanBoardConfigDrawer from '../../KanbanBoardConfigDialog';
import KanbanBoardFiltersModal from './KanbanBoardFiltersModal';
import KanbanUsersAvatar from '../../KanbanUsersAvatar';

// Estilos com makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.type === 'light' ? '#dedede' : '#242424',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    pointerEvents: 'none',
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.getContrastText(theme.palette.error.main),
    },
  },
}));

const AdvancedFilterBar = ({
  onFilterApply,
  boards = [],
  initialBoardId = "",
  initialFilters = {},
  searchTerm,
  followers = [],
  setSearchTerm,
  kanbanData
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const emitter = useEventHook();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [boardId, setBoardId] = useState(initialBoardId);
  const [focusedSearch, setFocusedSearch] = useState(false);
  const history = useHistory();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBoardChange = async (e) => {
    const newBoardId = e.target.value;
    const companyId = user.companyId;
    localStorage.setItem("kanbanCache", JSON.stringify({ companyId, lastKanbanId: newBoardId, userId: user.id }));
    setBoardId(newBoardId);
    history.push(`/opportunities/board/${newBoardId}`)
    emitter.emit("kanban-set-board", { companyId, lastKanbanId: newBoardId, userId: user.id });
    await api.put(`/filters/user/${user.id}`, { lastKanbanId: newBoardId, companyId });
  };

  return (
    <>
      <AppBar position="sticky" style={{ backgroundColor: theme.palette.type === 'light' ? '#fff' : '#424242', boxShadow: 2 }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: 8, flexGrow: 1, maxWidth: 800, position: 'relative' }}>
            <FormControl variant="outlined" size="small" style={{ minWidth: 280 }}>
              <InputLabel id="board-select-label">Quadros de oportunidade</InputLabel>
              <Select
                labelId="board-select-label"
                id="board-select"
                value={boardId}
                onChange={(e) => handleBoardChange(e)}
                label="Quadros de oportunidade"
              >
                {boards.map((board) => (
                  <MenuItem key={board.id} value={board.id}>
                    {board.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <TextField
              fullWidth
              style={{
                width: focusedSearch ? '500px' : '300px',
                minWidth: '300px',
                marginRight: '20px',
                transition: 'width 0.2s ease',
                '& .MuiInputBase-input': {
                  padding: '3.5px 5px',
                },
              }}
              variant="outlined"
              size="small"
              placeholder={`Nome, Descrição ou Protocolo`}
              value={searchTerm}
              onChange={(e) => handleSearchChange(e)}
              onFocus={() => setFocusedSearch(true)}
              onBlur={() => setFocusedSearch(false)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: 'gray' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Abrir filtros avançados">
              <Badge badgeContent={countChangedFiltersKanban(initialFilters)} color="error">
                <Button
                  variant="outlined"
                  startIcon={<FilterListIcon />}
                  onClick={() => setDialogOpen(true)}
                  style={{ marginLeft: 8, padding: '7px 15px' }}
                >
                  Filtros
                </Button>
              </Badge>
            </Tooltip>
            <Box
              style={{
                marginLeft: '15px'
              }}
            >
              <KanbanUsersAvatar
                users={followers}
              />
            </Box>
            {user.profile === 'admin' && (
              <Tooltip title="Lista de Quadros">
                <Button
                  style={{ padding: '7px 7px', minWidth: '35px', marginLeft: 8 }}
                  onClick={() => history.push('/opportunities')}
                >
                  <TableChartIcon />
                </Button>
              </Tooltip>
            )}

            <Tooltip title="Configurações do quadro">
              <Button
                style={{ padding: '7px 7px', minWidth: '35px' }}
                onClick={() => setConfigDialogOpen(true)}
              >
                <SettingsIcon />
              </Button>
            </Tooltip>

          </Box>
        </Toolbar>
      </AppBar >

      {/* Diálogo de filtros avançados */}
      <KanbanBoardFiltersModal
        open={dialogOpen}
        onClose={setDialogOpen}
        boardId={boardId}
        initialFilters={initialFilters}
        onApplyFilters={onFilterApply}
        kanbanData={kanbanData}
      />
      <KanbanBoardConfigDrawer
        open={configDialogOpen}
        onClose={setConfigDialogOpen}
        kanbanData={kanbanData}
      />
    </>
  );
};

export default AdvancedFilterBar;