/**
 * Filtra e ordena cards do Kanban
 * @param {Object} params - Parâmetros
 * @param {Array} params.cards - Lista de cards
 * @param {string} params.order - Critério de ordenação
 * @returns {Array} Cards ordenados
 */
const filterKanban = ({ cards, order = 'default' }) => {
    if (!cards || !Array.isArray(cards)) {
        console.warn('filterKanban: cards inválidos', cards);
        return [];
    }
    
    if (order === 'default' || !order) {
        return cards;
    }

    const sortedCards = [...cards];
    
    const parseMoneyValue = (value) => {
        if (typeof value === 'number') return value;
        
        if (typeof value === 'string') {
            const cleanValue = value.replace(/[R$\s]/g, '')
                .replace(/\./g, '')
                .replace(/,/g, '.');
            const numValue = parseFloat(cleanValue);
            return isNaN(numValue) ? 0 : numValue;
        }
        
        return 0;
    };
    const getTimestamp = (card) => {
        if (!card || !card.createdAt) return 0;
        try {
            return new Date(card.createdAt).getTime();
        } catch (e) {
            return 0;
        }
    };

    if (order === 'highestValue') {
        return sortedCards.sort((a, b) => {
            const valueA = parseMoneyValue(a.amount);
            const valueB = parseMoneyValue(b.amount);
            return valueB - valueA;
        });
    } 
    
    if (order === 'lowestValue') {
        return sortedCards.sort((a, b) => {
            const valueA = parseMoneyValue(a.amount);
            const valueB = parseMoneyValue(b.amount);
            return valueA - valueB;
        });
    }
    
    if (order === 'newest') {
        return sortedCards.sort((a, b) => getTimestamp(b) - getTimestamp(a));
    }
    
    if (order === 'oldest') {
        return sortedCards.sort((a, b) => getTimestamp(a) - getTimestamp(b));
    }
    
    console.warn(`filterKanban: critério de ordenação desconhecido: ${order}`);
    return sortedCards;
};

export default filterKanban;