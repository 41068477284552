import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import getTextColor from '../../../utils/getTextColor';
import CreateOrEditTagsModal from './CreateOrEditTagsModal';
import { AuthContext } from '../../../context/Auth/AuthContext';

const useStyles = makeStyles((theme) => ({
  searchField: {
    margin: theme.spacing(2),
    width: '90% !important',
    '& .MuiInputBase-root': {
      color: theme.mode === 'light' ? '#000000' : '#FFF',
      backgroundColor: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
      borderRadius: 3,
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
  label: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    borderRadius: 3,
    color: theme.mode === 'light' ? '#000000' : '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%'
  },
  buttonsIcon: {
    fontSize: 14,
    color: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
  },
  createButton: {
    color: theme.mode === 'light' ? '#000000' : '#FFF',
    margin: theme.spacing(2, 0),
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
    },
  },
  colorBlindOption: {
    textAlign: 'center',
    padding: theme.spacing(2, 0),
    color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
    fontSize: 14,
  },
  divider: {
    backgroundColor: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
    margin: theme.spacing(2, 0),
  },
  sectionTitle: {
    color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
    marginBottom: 8,
    marginTop: theme.spacing(2),
  }
}));

const TagsMenu = () => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [tagId, settagId] = useState(null);
  const [openEditOrCreate, setOpenEditOrCreate] = useState(false);
  const [status, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { boardId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/tag/kanban/list/${boardId}`);
        setTags(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [boardId]);

  const handleCloseEditOrCreateModal = () => {
    settagId(null);
    setOpenEditOrCreate(false);
  };

  const handleCreateTags = async ({ title, color, isGlobal = false, kanbanId }) => {
    try {
      const { data } = await api.post(`/tag/kanban`, { title, color, isGlobal, kanbanId });
      console.info({ data });

      // Adiciona o novo status ao array de status existente
      setTags(prevTags => [...prevTags, data]);

    } catch (error) {
      console.error(error);
    }
  };

  const handleEditTags = async ({ title, color, isGlobal = false, kanbanId, tagId }) => {
    try {
      const { data } = await api.put(`/tag/kanban/${tagId}`, { title, color, isGlobal, kanbanId });
      console.info({ data });

      // Atualiza o status no array substituindo o item com o mesmo ID
      setTags(prevTags =>
        prevTags.map(item =>
          item.id === tagId ? data : item
        )
      );

    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteTags = async (tagId) => {
    try {
      await api.delete(`/tag/kanban/${tagId}`);
      setTags(prevTags => prevTags.filter(item => item.id !== tagId));
    } catch (error) {
      console.error(error);
    }
  };

  // Filtra a lista de status com base no termo de busca, verificando se title existe
  const filteredTags = status.filter(item =>
    item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Separa os status em locais e globais
  const localTags = filteredTags.filter(item => !item.isGlobal);
  const globalTags = filteredTags.filter(item => item.isGlobal);

  const renderTagsList = (statusList, title) => (
    <>
      <Typography variant="subtitle2" className={classes.sectionTitle}>
        {title}
      </Typography>
      {statusList.length > 0 ? (
        statusList.map((label, index) => (
          <div
            key={label.id || index}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: label.color,
              padding: '0px 16px',
              marginBottom: '8px',
              borderRadius: 3
            }}
          >
            <span style={{ color: getTextColor(label.color), flex: 1 }}>
              {label.title}
            </span>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <IconButton
                size="small"
                disabled={user?.profile !== 'admin'}
                onClick={() => {
                  settagId(label.id);
                  setOpenEditOrCreate(true);
                }}
                style={{
                  marginLeft: 4,
                  padding: '11px'
                }}
              >
                <EditIcon className={classes.buttonsIcon} style={{ color: getTextColor(label.color) }} />
              </IconButton>
            </div>
          </div>
        ))
      ) : (
        <Typography variant="body2" style={{ color: '#8B949E', fontStyle: 'italic', marginBottom: 8 }}>
          Nenhuma tag encontrada
        </Typography>
      )}
    </>
  );

  return (
    <>
      <CreateOrEditTagsModal
        open={openEditOrCreate}
        onClose={handleCloseEditOrCreateModal}
        tagId={tagId}
        onConfirm={async ({ title, color, isGlobal, tagId }) =>
          !!tagId
            ? await handleEditTags({ title, color, isGlobal, kanbanId: boardId, tagId })
            : await handleCreateTags({ title, color, isGlobal, kanbanId: boardId })
        }
        onDelete={handleDeleteTags}
      />
      <TextField
        className={classes.searchField}
        variant="outlined"
        placeholder="Buscar tags..."
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Box p={2}>
        {renderTagsList(localTags, "Tags do Quadro")}

        {globalTags.length > 0 && (
          <>
            <Divider className={classes.divider} />
            {renderTagsList(globalTags, "Tags Globais")}
          </>
        )}

        <Button disabled={user?.profile !== 'admin'} className={classes.createButton} onClick={() => setOpenEditOrCreate(true)} fullWidth>
          Criar uma nova tag
        </Button>
      </Box >
    </>
  );
};

export default TagsMenu;