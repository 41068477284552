import React, { useEffect, useState } from 'react';
import { Formik, Field, FieldArray, Form } from 'formik';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, IconButton, Box, Tooltip, useTheme, Typography, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import generatePassword from '../../utils/generatePassword';
import OtpInput from '../OtpInput';
import { getVariablesWithUserData } from '../../utils/variableUtils';

const validationSchema = Yup.object().shape({
    globalExtraInfo: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('A chave não pode ser vazia.'),
            value: Yup.string(),
            order: Yup.number().required('A ordem é obrigatória.')
        })
    )
});

function comparePasswordWithOtp(password, otpValue, isOpen) {
    if (!password || !otpValue || !isOpen) return false;
    
    const cleanedOtpValue = otpValue.join('');

    if (password === cleanedOtpValue) {
        console.log("Senha correta!");
        return true;
    } else {
        console.log("Senha incorreta!");
        return false;
    }
}

const ContactglobalExtraInfoModal = ({ open, onClose }) => {
    const theme = useTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState({ globalExtraInfo: [] });
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteInfo, setDeleteInfo] = useState(null);
    const [password, setPassword] = useState(generatePassword().toUpperCase());
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [otpValue, setOtpValue] = useState(Array(8).fill(""));

    const handleOtpChange = (value) => {
        setOtpValue(value);
    };

    useEffect(() => {
        if (open) {
            setPassword(generatePassword().toUpperCase());
            (async () => {
                try {
                    setIsSubmitting(true);
                    const { data } = await api.get('/global-extra-info');

                    data.forEach((item, index) => {
                        if (item.order === undefined || item.order === null) {
                            item.order = index + 1;
                        }
                    });

                    data.sort((a, b) => a?.order - b?.order);

                    setInitialValues({ globalExtraInfo: data });
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsSubmitting(false);
                }
            })();
        }
    }, [open]);

    const handleSaveGlobalExtraInfo = async (globalExtraInfo) => {
        try {
            setIsSubmitting(true);
            const { data } = await api.post('/global-extra-info', { globalExtraInfo });
            await getVariablesWithUserData();
            setInitialValues({ globalExtraInfo: data });
            toast.success("Campos salvos com sucesso!")
            setTimeout(() => { onClose(false) }, 500)
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDeleteGlobalExtraInfo = async (removeAssociatedFields = true) => {
        try {
            setIsSubmitting(true);
            if (deleteInfo?.id) {
                await api.delete(`/global-extra-info/${deleteInfo?.id}?removeAssociatedFields=${removeAssociatedFields}`);
            }
            setInitialValues(prev => ({
                ...prev,
                globalExtraInfo: prev.globalExtraInfo.filter((_, i) => i !== deleteIndex)
            }));
            await getVariablesWithUserData();
            toast.success("Campo deletado com sucesso!");
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
            setConfirmDeleteOpen(false);
        }
    };

    const confirmDelete = (info, index) => {
        setDeleteInfo(info);
        setDeleteIndex(index);
        setConfirmDeleteOpen(true);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const items = Array.from(initialValues.globalExtraInfo);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);
        items.forEach((item, index) => { item.order = index + 1; });
        setInitialValues({ globalExtraInfo: items });
    };

    const isMatch = comparePasswordWithOtp(password, otpValue, confirmDeleteOpen);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="contact-extra-info-dialog"
        >
            <DialogTitle id="contact-extra-info-dialog">Campos globais</DialogTitle>
            <DialogContent dividers>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={async (values) => await handleSaveGlobalExtraInfo(values.globalExtraInfo)}
                >
                    {({ values, handleChange, errors, touched }) => (
                        <Form>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <FieldArray name="globalExtraInfo">
                                                {({ push, remove }) => (
                                                    <>
                                                        {values.globalExtraInfo && values.globalExtraInfo.length > 0 && values.globalExtraInfo.map((info, index) => (
                                                            <Draggable key={`${index}-info`} draggableId={`${index}-info`} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided.draggableProps.style,
                                                                            marginBottom: '8px'
                                                                        }}
                                                                    >
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <Box
                                                                                    style={{
                                                                                        backgroundColor: theme.mode === "dark" ? 'rgb(82 81 81)' : 'rgb(241 241 241)',
                                                                                        padding: '16px',
                                                                                        borderRadius: '4px',
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'flex-start',
                                                                                        position: 'relative'
                                                                                    }}
                                                                                >
                                                                                    <Grid container spacing={2} alignItems="flex-start">
                                                                                        <Grid
                                                                                            item
                                                                                            xs={12}
                                                                                            sm={12}
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'space-between'
                                                                                            }}>
                                                                                            <Grid container xs={11} sm={11}>
                                                                                                <Grid item xs={12} sm={12}>
                                                                                                    <Field
                                                                                                        as={TextField}
                                                                                                        name={`globalExtraInfo[${index}].order`}
                                                                                                        variant="outlined"
                                                                                                        margin="dense"
                                                                                                        style={{ display: 'none' }}
                                                                                                        value={index}
                                                                                                        onChange={handleChange}
                                                                                                    />
                                                                                                    <Field
                                                                                                        as={TextField}
                                                                                                        label={i18n.t('contactModal.form.extraName')}
                                                                                                        name={`globalExtraInfo[${index}].name`}
                                                                                                        variant="outlined"
                                                                                                        margin="dense"
                                                                                                        fullWidth
                                                                                                        error={touched.globalExtraInfo?.[index]?.name && Boolean(errors.globalExtraInfo?.[index]?.name)}
                                                                                                        helperText={touched.globalExtraInfo?.[index]?.name && errors.globalExtraInfo?.[index]?.name}
                                                                                                        value={info.name}
                                                                                                        onChange={handleChange}
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid item xs={12} sm={12}>
                                                                                                    <Field
                                                                                                        as={TextField}
                                                                                                        label={i18n.t('contactModal.form.globalExtraValue')}
                                                                                                        name={`globalExtraInfo[${index}].value`}
                                                                                                        variant="outlined"
                                                                                                        margin="dense"
                                                                                                        maxRows={7}
                                                                                                        multiline={handleSizeMultiline(info?.value)}
                                                                                                        fullWidth
                                                                                                        helperText={info?.value.length > 0 ? 'Todos os campos vazios serão preenchidos com este texto por padrão ao criar ou atualizar um contato.' : ''}
                                                                                                        value={info.value}
                                                                                                        onChange={handleChange}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid item xs={1} sm={1}>
                                                                                                <Box
                                                                                                    display="flex"
                                                                                                    justifyContent="center"
                                                                                                    alignItems="center"
                                                                                                    height="100%"
                                                                                                >
                                                                                                    <Tooltip title="Excluir campo" arrow>
                                                                                                        <IconButton
                                                                                                            size="small"
                                                                                                            onClick={() => confirmDelete(info, index)}
                                                                                                            style={{
                                                                                                                color: '#f44336',
                                                                                                                padding: '8px'
                                                                                                            }}
                                                                                                        >
                                                                                                            <DeleteOutlineIcon />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                        <div>
                                                            <Button
                                                                style={{ flex: 1, marginTop: 8 }}
                                                                fullWidth
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => push({ name: '', value: '', order: values.globalExtraInfo.length + 1 })}
                                                            >
                                                                {`Novo campo`}
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={() => onClose(false)}
                                    color="default"
                                    disabled={isSubmitting}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <CircularProgress size={24} /> : 'Salvar'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                aria-labelledby="confirm-delete-dialog"
                maxWidth="md"
                fullWidth
                PaperProps={{
                    style: {
                        maxWidth: '665px',
                        padding: "16px",
                        borderRadius: "8px",
                        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <DialogTitle id="confirm-delete-dialog" style={{ textAlign: "center", fontWeight: "bold" }}>
                    Confirmação de Exclusão
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "16px", textAlign: "justify" }}>
                        Este campo global pode ter gerado campos associados. Você pode optar por manter esses campos ou excluí-los junto com o campo global. O que deseja fazer?
                    </Typography>
                    <Divider style={{ marginBottom: "16px" }} />
                    <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", marginBottom: "8px" }}>
                        Digite o seguinte código para liberar a exclusão do campo:
                    </Typography>
                    <Typography variant="h6" color="textSecondary" style={{ textAlign: "center", marginBottom: "16px" }}>
                        <strong>{`${password?.slice(0, 4)} - ${password?.slice(4, 8)}`}</strong>
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
                        <OtpInput length={8} otp={otpValue} isSplit setOtp={handleOtpChange} />
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between", padding: "16px" }}>
                    <Button
                        size="medium"
                        onClick={() => setConfirmDeleteOpen(false)}
                        variant="contained"
                        color="default"
                        style={{ padding: "8px 16px" }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="medium"
                        disabled={!isMatch}
                        onClick={() => handleDeleteGlobalExtraInfo(false)}
                        variant="outlined"
                        color="secondary"
                        style={{ padding: "8px 16px" }}
                    >
                        Excluir e manter campos
                    </Button>
                    <Button
                        size="medium"
                        disabled={!isMatch}
                        onClick={() => handleDeleteGlobalExtraInfo(true)}
                        variant="contained"
                        color="secondary"
                        style={{ padding: "8px 16px" }}
                    >
                        Excluir e remover campos
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
};

const handleSizeMultiline = (value) => {
    return value && value.length > 60;
};

export default ContactglobalExtraInfoModal;
