import {
    Box,
    Button,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    title: {
        color: theme.mode === 'light' ? '#000000' : '#FFF',
        marginBottom: theme.spacing(2),
    },
    descriptionField: {
        backgroundColor: theme.mode === 'light' ? 'rgb(217 217 217)' : 'rgba(255, 255, 255, 0.1)',
        borderRadius: 3,
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-root': {
            color: theme.mode === 'light' ? '#000000' : '#FFF',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.3)',
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.mode === 'light' ? '#333a40' : '#0079BF',
            },
        },
    },
    saveButton: {
        marginRight: theme.spacing(1),
    },
    cancelButton: {
    },
    activitySection: {
        marginTop: theme.spacing(4),
    },
    activityTitle: {
        color: theme.mode === 'light' ? '#000000' : '#FFF',
        marginBottom: theme.spacing(2),
    },
    activityEmpty: {
        color: theme.mode === 'light' ? '#575757' : '#C9D1D9',
        fontSize: 14,
        textAlign: 'center',
        padding: theme.spacing(3),
        backgroundColor: theme.mode === 'light' ? 'rgba(147, 147, 147, 0.35)' : 'rgba(255, 255, 255, 0.05)',
        borderRadius: 3,
    }
}));

const AboutBoardMenu = () => {
    const classes = useStyles();
    const [description, setDescription] = useState('');
    const [oldDescription, setOldDescription] = useState('');
    const [loading, setLoading] = useState(false);

    const { boardId } = useParams();

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const { data } = await api.put(`/kanban/${boardId}`, { description });
            setDescription(data?.description);
            setOldDescription(data?.description);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get(`/kanban/board/${boardId}`);
                if (data?.description) {
                    setDescription(data?.description);
                    setOldDescription(data?.description);
                };
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })()
    }, [boardId]);

    return (
        <Box className={classes.root}>
            <Typography variant="subtitle1" className={classes.title}>
                Descrição
            </Typography>

            <TextField
                disabled={loading}
                className={classes.descriptionField}
                variant="outlined"
                placeholder="Adicione uma descrição mais detalhada aqui..."
                multiline
                rows={6}
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
            />

            <Box style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Button
                    disabled={loading || (description === oldDescription)}
                    variant={(loading || (description === oldDescription)) ? 'contained' : "outlined"}
                    color="secondary"
                    onClick={() => setDescription(oldDescription)}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={loading || (description === oldDescription)}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave()}
                >
                    Salvar
                </Button>
            </Box>
            <Box className={classes.activitySection}>
                <Typography variant="subtitle1" className={classes.activityTitle}>
                    Atividade Recente
                </Typography>
                <div className={classes.activityEmpty}>
                    Em breve.
                </div>
                {/*
                <div className={classes.activityEmpty}>
                    Ainda não há atividades registradas neste quadro.
                </div>
                */}
            </Box>
        </Box>
    );
};

export default AboutBoardMenu;