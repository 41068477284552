import {
    Drawer,
    IconButton,
    makeStyles,
    Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useState } from 'react';

// Import submenu components
import AboutBoardMenu from './AboutBoardMenu';
import MainMenu from './MainMenu';
import SettingsMenu from './SettingsMenu';
import StatusMenu from './StatusMenu';
import TagsMenu from './TagsMenu';
import SourceMenu from './SourceMenu';
import identificarGenero from '../../utils/identificarGenero';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { AuthContext } from '../../context/Auth/AuthContext';

const useStyles = makeStyles((theme) => ({
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: '325px',
        backgroundColor: theme.mode === 'light' ? '#FFF' : '#424242',
        color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    backButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    header: {
        color: theme.mode === 'light' ? '#000000' : '#FFF',
        textAlign: 'center',
        padding: theme.spacing(2),
        paddingTop: '15px',
        borderBottom: `1px solid rgba(255, 255, 255, 0.1)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    content: {
        padding: 0,
    },
}));

const KanbanBoardConfigDrawer = ({ open, onClose, kanbanData }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [currentScreen, setCurrentScreen] = useState('main');
    const [previousScreen, setPreviousScreen] = useState(null);

    const navigateToScreen = (screen) => {
        setPreviousScreen(currentScreen);
        setCurrentScreen(screen);
    };

    const handleClose = () => {
        setCurrentScreen('main');
        onClose(false);
    };

    const handleBack = () => {
        if (previousScreen) {
            setCurrentScreen(previousScreen);
            setPreviousScreen(null);
        } else {
            handleClose();
        }
    };

    // Map of screen names to their titles
    const screenTitles = {
        main: 'Menu',
        status: 'Status',
        tags: 'Tags',
        about: 'Sobre este quadro',
        ...(user?.role === 'admin' && { settings: 'Configurações' }),
        source: `${capitalizeFirstLetter((kanbanData?.taskName || 'oportunidade').toLowerCase())}s`,
    };

    // Map of screen components
    const renderContent = () => {
        switch (currentScreen) {
            case 'main':
                return <MainMenu onNavigate={navigateToScreen} kanbanData={kanbanData} />;
            case 'status':
                return <StatusMenu kanbanData={kanbanData} />;
            case 'tags':
                return <TagsMenu kanbanData={kanbanData} />;
            case 'source':
                return <SourceMenu kanbanData={kanbanData} />;
            case 'about':
                return <AboutBoardMenu kanbanData={kanbanData} />;
            case 'settings':
                return <SettingsMenu kanbanData={kanbanData} />;
            default:
                return <MainMenu onNavigate={navigateToScreen} kanbanData={kanbanData} />;
        }
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="right"
            open={open}
            onClose={handleClose}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div>
                <Typography variant="h6" className={classes.header}>
                    {currentScreen !== 'main' && (
                        <IconButton className={classes.backButton} onClick={handleBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {screenTitles[currentScreen] || 'Menu'}
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Typography>
                <div className={classes.content}>
                    {renderContent()}
                </div>
            </div>
        </Drawer>
    );
};

export default KanbanBoardConfigDrawer;