import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField,
    Grid,
    Typography,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { ColorPicker } from "material-ui-color";
import api from '../../../../services/api';
import ToggleOption from '../../../ToggleOption';

// Estilos usando makeStyles
const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            backgroundColor: theme.mode === 'light' ? '#ffffff' : '#424242',
            color: theme.mode === 'light' ? '#000000' : '#FFF',
            width: "100%",
            maxWidth: "380px",
            borderRadius: '8px',
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        borderBottom:  theme.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.2)' :  '1px solid rgba(255, 255, 255, 0.1)',
        '& h6': {
            fontWeight: 'normal',
            fontSize: '1rem',
        },
    },
    closeButton: {
        color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    dialogContent: {
        padding: theme.spacing(2),
        color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            color: theme.mode === 'light' ? '#000000' : '#FFF',
            '& fieldset': {
                borderColor:  theme.mode === 'light' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.3)',
            },
            '&:hover fieldset': {
                borderColor:  theme.mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3287c9',
            },
        },
        '& .MuiInputLabel-root': {
            color:  theme.mode === 'light' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
        },
    },
    colorSection: {
        marginTop: theme.spacing(1),
    },
    colorLabel: {
        fontSize: '0.9rem',
        color: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.7)' :  'rgba(255, 255, 255, 0.7)',
        marginBottom: theme.spacing(1),
    },
    colorPickerWrapper: {
        '& .ColorPicker-ColorPicker': {
            background: 'none',
        },
        '& .ColorPicker-ColorPicker-palette': {
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '8px',
            width: '100%',
            margin: 0,
        },
        '& .ColorPicker-ColorPicker-palette-color': {
            width: '100%',
            height: 0,
            paddingBottom: '75%',
            borderRadius: '4px',
            margin: 0,
            border: '2px solid transparent',
            '&:hover': {
                border: theme.mode === 'light' ? '2px solid rgba(0, 0, 0, 0.5)' : '2px solid rgba(255, 255, 255, 0.5)',
            },
            '&.selected': {
                border: '2px solid white',
            },
        },
        '& .ColorPicker-ColorPicker-input': {
            display: 'none',
        },
    },
    removeColorButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        backgroundColor: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        color: theme.mode === 'light' ? '#000000' : '#FFF',
        cursor: 'pointer',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
        },
    },
    buttonWrapper: {
        position: 'relative',
    },
    createButton: {
    },
    deleteButton: {
        marginRight: theme.spacing(2),
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: theme.mode === 'light' ? '#000000' : '#FFF',
    },
    dialogActions: {
        padding: theme.spacing(2),
        justifyContent: 'flex-end',
    },
}));

const CreateOrEditStatusModal = ({ open, onClose, statusId, onConfirm, onDelete }) => {
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [title, setTitle] = useState('');
    const [error, setError] = useState(false);
    const [color, setColor] = useState('#3287c9');
    const [isGlobal, setIsGlobal] = useState(false);

    useEffect(() => {
        if (!open && !statusId) return;
        (async () => {
            try {
                const { data } = await api.get(`/priority/kanban/${statusId}`);
                console.info({ CreateOrEditStatusModal: data });
                setTitle(data?.title);
                setColor(data?.color);
                setIsGlobal(data?.isGlobal);
            } catch (error) {
                console.error(error)
            }
        })()
    }, [statusId]);

    const handleClose = () => {
        setTitle('');
        setError(false);
        setIsGlobal(false);
        setColor('#3287c9');
        onClose(false);
    };

    const handleConfirm = async () => {
        if (!title?.trim()) {
            setError(true);
            return;
        }

        setIsSubmitting(true);
        try {
            await onConfirm({ title, color, isGlobal, statusId });
            handleClose();
        } catch (error) {
            console.error('Erro ao criar coluna:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = async () => {
        setIsSubmitting(true);
        try {
            await onDelete(statusId);
            handleClose();
        } catch (error) {
            console.error('Erro ao deletar coluna:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleColorChange = (newColor) => {
        setColor(`#${newColor.hex}`);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="kanban-dialog"
            className={classes.dialog}
        >
            <DialogTitle id="kanban-dialog" className={classes.dialogTitle} disableTypography>
                <Typography variant="h6">{!statusId ? 'Criar Status' : 'Editar Status'}</Typography>
                <IconButton className={classes.closeButton} onClick={handleClose} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="Título do status"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                        setError(false);
                    }}
                    error={error}
                    helperText={error ? 'O título é obrigatório' : ''}
                    className={classes.textField}
                />
                <ToggleOption
                    title="Publico a todos"
                    description="Permitir que este status seja visivel e utilizado em outros quadros."
                    defaultEnabled={isGlobal}
                    onChange={(value) => setIsGlobal(value)}
                />
                <div className={classes.colorSection}>
                    <Typography className={classes.colorLabel}>
                        Selecionar uma cor
                    </Typography>
                    <div className={classes.colorPickerWrapper}>
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            label="Cor do status"
                            value={color}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <ColorPicker
                                        value={color}
                                        onChange={handleColorChange}
                                        hideTextfield
                                    />
                                ),
                            }}
                            className={classes.textField}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <div className={classes.buttonWrapper}>
                    {statusId && (
                        <Button
                            onClick={handleDelete}
                            variant="outlined"
                            color="secondary"
                            className={classes.deleteButton}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Excluindo...' : 'Excluir'}
                        </Button>
                    )}
                    <Button
                        onClick={handleConfirm}
                        variant="contained"
                        color="primary"
                        className={classes.createButton}
                        disabled={isSubmitting || !title?.trim()}
                    >
                        {isSubmitting ?
                            statusId ?
                                'Editando...' :
                                'Criando...' :
                            statusId ?
                                'Editar' :
                                'Criar'
                        }
                    </Button>
                    {isSubmitting && (
                        <CircularProgress size={24} className={classes.progress} />
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOrEditStatusModal;