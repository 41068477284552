function getInitialsName(name) {
    if (!name || typeof name !== "string") return "";
    const words = name.trim().split(/\s+/);
    const ignoreWords = new Set(["da", "de", "do", "dos", "das", "e", "a", "o", "as", "os", "um", "uma", "uns", "umas", "no", "na", "nos", "nas", "pelo", "pela", "pelos", "pelas"]);
    const filteredWords = words.filter(word => !ignoreWords.has(word.toLowerCase()));
    if (filteredWords.length === 0) return "";
    const firstInitial = filteredWords[0][0].toUpperCase();
    const lastInitial = filteredWords.length > 1 ? filteredWords[filteredWords.length - 1][0].toUpperCase() : firstInitial;
    return firstInitial + lastInitial;
}

export default getInitialsName; 