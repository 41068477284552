import React, { useState } from "react";
import { toast } from "react-toastify";
import MarkdownWrapper from "../../MarkdownWrapper";
import { useTheme } from "@material-ui/core";

// Estilos encapsulados para a animação (injetados apenas uma vez)
let animationStylesInjected = false;
const injectAnimationStyles = () => {
    if (animationStylesInjected || typeof document === 'undefined') return;

    const styleSheet = document.createElement("style");
    styleSheet.id = 'toast-revert-animation';
    styleSheet.textContent = `
    @keyframes toastCountdown {
      from { width: 100%; }
      to { width: 0%; }
    }
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(10px); }
      to { opacity: 1; transform: translateY(0); }
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
    document.head.appendChild(styleSheet);
    animationStylesInjected = true;
};

// Componente de spinner para o botão
const LoadingSpinner = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ animation: "spin 1s linear infinite" }}
    >
        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeOpacity="0.25" />
        <path d="M12 2a10 10 0 0 1 10 10" />
    </svg>
);

// Ícone de reverter
const RevertIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M2.5 2v6h6M2.66 15.57a10 10 0 1 0 .57-8.38" />
    </svg>
);

// Componente do Toast com estilos inline melhorados
const ToastContent = ({ title, description, onRevert, closeToast }) => {
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    // Injetar os estilos de animação no primeiro render
    React.useEffect(() => {
        injectAnimationStyles();
    }, []);

    // Função para lidar com o clique no botão de reverter
    const handleRevert = async () => {
        if (isLoading) return;

        setIsLoading(true);
        try {
            // Executa a função onRevert e aguarda sua conclusão
            if (typeof onRevert === 'function') {
                await onRevert();
            }
            // Fecha o toast apenas após a conclusão bem-sucedida
            closeToast();
        } catch (error) {
            console.error("Erro ao reverter ação:", error);
            setIsLoading(false);
            // Opcional: pode adicionar um tratamento visual para o erro
        }
    };

    return (
        <div style={{
            backgroundColor: theme.palette.type === 'light' ? "#ffffff" : "#424242",
            borderRadius: "5px",
            boxShadow: "0 8px 30px rgba(0, 0, 0, 0.12)",
            overflow: "hidden",
            width: "100%",
            animation: "fadeIn 0.3s ease-out forwards",
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif'
        }}>
            <div style={{
                padding: "14px 18px",
                backgroundColor: theme.palette.type === 'light' ? "#f1f5f9" : "#2b2b2b",
                borderBottom: "1px solid #e2e8f0",
                display: "flex",
                alignItems: "center"
            }}>
                <div style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "50%",
                    backgroundColor: "#3b82f6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "12px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold"
                }}>i</div>
                <h4 style={{
                    margin: 0,
                    fontSize: "16px",
                    fontWeight: 600,
                    color: theme.palette.type === 'light' ? "#00000" : "#FFFFF",
                    letterSpacing: "-0.01em"
                }}>{title}</h4>
            </div>

            <div style={{ padding: "16px 18px", color: theme.palette.type === 'light' ? "#334155" : "#fff"}}>
                <p
                    style={{
                        fontSize: "14px",
                        margin: "0 0 18px 0",
                        color: theme.palette.type === 'light' ? "#64748b" : "#FFFFF",
                        lineHeight: "1.5"
                    }}
                >
                    <MarkdownWrapper>{description}</MarkdownWrapper>
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        disabled={isLoading}
                        style={{
                            backgroundColor: isLoading ? "#f87171" : "#ef4444",
                            color: "white",
                            border: "none",
                            padding: "10px 18px",
                            borderRadius: "6px",
                            cursor: isLoading ? "not-allowed" : "pointer",
                            fontWeight: 600,
                            fontSize: "14px",
                            boxShadow: "0 2px 4px rgba(239, 68, 68, 0.2)",
                            transition: "all 0.2s ease",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "6px",
                            minWidth: "150px",
                            opacity: isLoading ? 0.85 : 1
                        }}
                        onMouseOver={(e) => {
                            if (!isLoading) {
                                e.currentTarget.style.backgroundColor = "#dc2626";
                                e.currentTarget.style.transform = "translateY(-2px)";
                                e.currentTarget.style.boxShadow = "0 4px 8px rgba(239, 68, 68, 0.3)";
                            }
                        }}
                        onMouseOut={(e) => {
                            if (!isLoading) {
                                e.currentTarget.style.backgroundColor = "#ef4444";
                                e.currentTarget.style.transform = "translateY(0)";
                                e.currentTarget.style.boxShadow = "0 2px 4px rgba(239, 68, 68, 0.2)";
                            }
                        }}
                        onClick={handleRevert}
                    >
                        {isLoading ? <LoadingSpinner /> : <RevertIcon />}
                        {isLoading ? "Revertendo..." : "Reverter Ação"}
                    </button>
                </div>
            </div>

            {!isLoading && (
                <div style={{
                    height: "4px",
                    backgroundColor: "#f1f5f9",
                    width: "100%",
                    position: "relative",
                    overflow: "hidden"
                }}>
                    <div style={{
                        height: "100%",
                        background: "linear-gradient(90deg, #3b82f6, #2563eb)",
                        width: "100%",
                        position: "absolute",
                        animation: "toastCountdown 10s linear forwards"
                    }}></div>
                </div>
            )}
        </div>
    );
};

/**
 * Exibe um toast com botão de reverter ação
 * @param {string} title - Título do toast
 * @param {string} description - Descrição da ação que pode ser revertida
 * @param {function} onRevert - Função executada quando o usuário clica em "Reverter Ação" (pode ser assíncrona)
 * @returns {string} ID do toast criado
 */
const revertToast = (title, description, onRevert) => {
    // Injetamos os estilos de animação ao chamar a função
    injectAnimationStyles();

    return toast(
        ({ closeToast }) => (
            <ToastContent
                title={title}
                description={description}
                onRevert={onRevert}
                closeToast={closeToast}
            />
        ),
        {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            pauseOnHover: true,
            draggable: false,
            style: {
                background: "transparent",
                boxShadow: "none",
                width: "380px",
                padding: 0
            }
        }
    );
};

// Exporta apenas a função revertToast
export { revertToast };