import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import clsx from 'clsx';
import { format, isSameDay, parseISO } from 'date-fns';

import { Badge, Button, CircularProgress, Divider, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

import { AccessTime, Done, DoneAll, ExpandMore, Facebook, GetApp, Instagram } from '@material-ui/icons';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import whatsBackgroundDark from '../../assets/wa-background-dark.png';
import whatsBackground from '../../assets/wa-background.png';
import LocationPreview from '../LocationPreview';
import MarkdownWrapper from '../MarkdownWrapper';
import MessageOptionsMenu from '../MessageOptionsMenu';
import ModalImageCors from '../ModalImageCors';

import { ReplyMessageContext } from '../../context/ReplyingMessage/ReplyingMessageContext';
import toastError from '../../errors/toastError';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import VcardPreview from '../VcardPreview';
import getUUIDFromURL from '../../helpers/getUUIDFromURL';
import getFirstName from '../../helpers/getFirstName';
import { i18n } from '../../translate/i18n';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';

const removeDuplicateMessages = (messagesList) => {
    const messageMap = new Map();
    messagesList.forEach((message) => {
        const existingMessage = messageMap.get(message.messageId);
        if (
            !existingMessage ||
            message.ack > existingMessage.ack ||
            (message.ack === existingMessage.ack && new Date(message.createdAt) > new Date(existingMessage.createdAt))
        ) {
            messageMap.set(message.messageId, message);
        }
    });
    return Array.from(messageMap.values());
};

const useStyles = makeStyles((theme) => ({
    messagesListWrapper: {
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        minWidth: 200,
        minHeight: 200,
    },

    messageCenter: {
        marginTop: 5,
        alignItems: 'center',
        verticalAlign: 'center',
        alignContent: 'center',
        backgroundColor: '#E1F5FEEB',
        fontSize: '12px',
        minWidth: 100,
        maxWidth: 270,
        color: '#272727',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: '0 1px 1px #b3b3b3',
    },

    messagesList: {
        backgroundImage: theme.mode === 'light' ? `url(${whatsBackground})` : `url(${whatsBackgroundDark})`,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '20px 20px 20px 20px',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },

    circleLoading: {
        color: blue[500],
        position: 'absolute',
        opacity: '70%',
        top: 0,
        left: '50%',
        marginTop: 12,
    },

    messageLeft: {
        marginRight: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: 'auto',
        display: 'block',
        position: 'relative',
        '&:hover #messageActionsButton': {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
        },

        whiteSpace: 'pre-wrap',
        backgroundColor: theme.mode === 'dark' ? '#303030' : 'white',
        alignSelf: 'flex-start',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
    },

    quotedContainerLeft: {
        margin: '-3px -80px 6px -6px',
        overflow: 'hidden',
        backgroundColor: theme.mode === 'light' ? '#f0f0f0' : '#424242',
        borderRadius: '7.5px',
        display: 'flex',
        position: 'relative',
    },

    quotedMsg: {
        padding: 10,
        maxWidth: 300,
        height: 'auto',
        display: 'block',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
    },

    quotedSideColorLeft: {
        flex: 'none',
        width: '4px',
        backgroundColor: '#6bcbef',
    },

    messageRight: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: 'auto',
        display: 'block',
        position: 'relative',
        '&:hover #messageActionsButton': {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
        },

        whiteSpace: 'pre-wrap',
        backgroundColor: '#dcf8c6',
        color: '#303030',
        alignSelf: 'flex-end',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: '0 1px 1px #b3b3b3',
    },

    messageRightNotepad: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: 'auto',
        display: 'block',
        position: 'relative',
        '&:hover #messageActionsButton': {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
        },

        whiteSpace: 'pre-wrap',
        backgroundColor: () => theme.palette.type === "dark" ? "#f3f3f3" : '#fffdc4',
        color: '#303030',
        alignSelf: 'flex-end',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: '0 1px 1px #b3b3b3',
    },

    quotedContainerRight: {
        margin: '-3px -80px 6px -6px',
        overflowY: 'hidden',
        backgroundColor: '#cfe9ba',
        borderRadius: '7.5px',
        display: 'flex',
        position: 'relative',
    },

    quotedMsgRight: {
        padding: 10,
        maxWidth: 300,
        height: 'auto',
        whiteSpace: 'pre-wrap',
    },

    quotedSideColorRight: {
        flex: 'none',
        width: '4px',
        backgroundColor: '#35cd96',
    },

    messageActionsButton: {
        display: 'none',
        position: 'relative',
        color: '#999',
        zIndex: 1,
        backgroundColor: 'inherit',
        opacity: '90%',
        '&:hover, &.Mui-focusVisible': { backgroundColor: 'inherit' },
    },

    messageContactName: {
        display: 'flex',
        color: '#6bcbef',
        fontWeight: 500,
    },

    textContentItem: {
        overflowWrap: 'break-word',
        padding: '3px 80px 6px 6px',
    },

    textContentItemEdited: {
        overflowWrap: "break-word",
        padding: '3px 80px 6px 6px',
    },

    textContentItemDeleted: {
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.36)',
        overflowWrap: 'break-word',
        padding: '3px 80px 6px 6px',
    },

    messageMedia: {
        objectFit: 'cover',
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    timestamp: {
        fontSize: 11,
        padding: 1,
        position: 'absolute',
        bottom: 0,
        right: 5,
        color: '#999',
    },

    timestampEdited: {
        fontSize: 11,
        padding: 1,
        position: 'absolute',
        bottom: 0,
        right: 5,
        color: '#999',
    },

    dailyTimestamp: {
        alignItems: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        width: '110px',
        backgroundColor: '#e1f3fb',
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 1px 1px #b3b3b3',
    },

    dailyTimestampText: {
        color: '#808888',
        padding: 8,
        alignSelf: 'center',
        marginLeft: '0px',
    },

    ackIcons: {
        fontSize: 18,
        verticalAlign: 'middle',
        marginLeft: 4,
    },

    deletedIcon: {
        fontSize: 18,
        verticalAlign: 'middle',
        marginRight: 4,
    },

    ackDoneAllIcon: {
        color: blue[500],
        fontSize: 18,
        verticalAlign: 'middle',
        marginLeft: 4,
    },

    downloadMedia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit',
        padding: 10,
    },

    // messageCenter: {
    //   marginTop: 5,
    //   alignItems: "center",
    //   verticalAlign: "center",
    //   alignContent: "center",
    //   backgroundColor: "#E1F5FEEB",
    //   fontSize: "12px",
    //   minWidth: 100,
    //   maxWidth: 270,
    //   color: "#272727",
    //   borderTopLeftRadius: 0,
    //   borderTopRightRadius: 8,
    //   borderBottomLeftRadius: 8,
    //   borderBottomRightRadius: 8,
    //   paddingLeft: 5,
    //   paddingRight: 5,
    //   paddingTop: 5,
    //   paddingBottom: 0,
    //   boxShadow: "0 1px 1px #b3b3b3",
    // },

    deletedMessage: {
        color: '#f55d65',
    },
    forwardMessage: {
        color: '#999999',
        fontStyle: 'italic',
        fontSize: '13px'
    },
}));

const reducer = (state, action) => {
    if (action.type === 'LOAD_MESSAGES') {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach((message) => {
            const messageIndex = state.findIndex((m) => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...newMessages, ...state];
    }

    if (action.type === 'ADD_MESSAGE') {
        const newMessage = action.payload;
        const messageIndex = state.findIndex((m) => m.id === newMessage.id || m.id === action.preMessageId);
        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }

        return [...state];
    }

    if (action.type === 'UPDATE_MESSAGE') {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);
        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const MessagesList = ({ ticket, ticketId, isGroup, isSpying = false }) => {

    const classes = useStyles();
    const { setMedias } = useContext(ReplyMessageContext);
    let [messagesListArray, dispatch] = useReducer(reducer, []);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const lastMessageRef = useRef();
    const [selectedMessage, setSelectedMessage] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const messageOptionsMenuOpen = Boolean(anchorEl);
    const currentTicketId = useRef(ticketId);
    const messagesList = removeDuplicateMessages(messagesListArray);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
        currentTicketId.current = ticketId;
    }, [ticketId]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async () => {
                if (ticketId === undefined) return;
                try {
                    const { data } = await api.get('/messages/' + ticketId, {
                        params: { pageNumber },
                    });

                    if (currentTicketId.current === ticketId) {
                        dispatch({ type: 'LOAD_MESSAGES', payload: data.messages });
                        setHasMore(data.hasMore);
                        setLoading(false);
                    }

                    if (pageNumber === 1 && data.messages.length > 1) {
                        scrollToBottom();
                    }
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchMessages();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [pageNumber, ticketId]);

    console.info({ messagesList });

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-appMessage`, (data) => {

            const uuidUrl = isSpying ? (ticket.uuid || null) : getUUIDFromURL();

            const socketTicketUuid = data?.ticket?.uuid || data?.message?.ticket?.uuid || null;

            if (uuidUrl == socketTicketUuid) {

                if (data.action == 'create') {
                    dispatch({ type: 'ADD_MESSAGE', payload: data.message, preMessageId: data.preMessageId });
                    scrollToBottom();
                }

                if (data.action == 'update') {
                    dispatch({ type: 'UPDATE_MESSAGE', payload: data.message });
                }

            }
        });

        socket.on(`company-${companyId}-preMessage`, (data) => {

            const uuidUrl = isSpying ? (ticket.uuid || null) : getUUIDFromURL();

            const socketTicketUuid = data?.ticket?.uuid || data?.message?.ticket?.uuid || null;

            if (uuidUrl == socketTicketUuid) {

                if (data.action === 'create') {
                    dispatch({ type: 'ADD_MESSAGE', payload: data.message });
                    scrollToBottom();
                }

                if (data.action === 'update') {
                    dispatch({ type: 'UPDATE_MESSAGE', payload: data.message });
                }

            }
        });
    }, [ticketId, ticket]);

    const loadMore = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({});
        }
    };

    const handleScroll = (e) => {
        if (!hasMore) return;
        const { scrollTop } = e.currentTarget;

        if (scrollTop === 0) {
            document.getElementById('messagesList').scrollTop = 1;
        }

        if (loading) {
            return;
        }

        if (scrollTop < 50) {
            loadMore();
        }
    };

    const handleOpenMessageOptionsMenu = (e, message) => {
        setAnchorEl(e.currentTarget);
        setSelectedMessage(message);
    };

    const handleCloseMessageOptionsMenu = (e) => {
        setAnchorEl(null);
    };

    const extrairNomeENumero = (vcard) => {
        const nomeMatch = vcard.match(/FN:(.*?)\n/);
        const nome = nomeMatch ? nomeMatch[1] : '';

        const numeroMatch = vcard.match(/waid=(\d+)/);
        const numero = numeroMatch ? numeroMatch[1].replace(/\D/g, '') : '';
        if (nome && numero) {
            return { nome, numero };
        } else {
            return null;
        }
    };

    const checkMessageMedia = (message) => {
        if (message.mediaType === 'locationMessage' && message.body.split('|').length >= 2) {
            let locationParts = message.body.split('|');
            let imageLocation = locationParts[0];
            let linkLocation = locationParts[1];

            let descriptionLocation = null;

            if (locationParts.length > 2) descriptionLocation = message.body.split('|')[2];

            // console.log('locationParts', locationParts)
            // console.log('imageLocation', imageLocation)
            // console.log('linkLocation', linkLocation)
            // console.log('descriptionLocation', descriptionLocation)

            return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />;
        }

        if (message.mediaType === 'contactMessage') {
            const vcardPreviewInfo = extrairNomeENumero(message.body);

            return <VcardPreview key={message.id} contact={vcardPreviewInfo?.nome} number={vcardPreviewInfo?.numero} />;
        }

        if (message.mediaType === 'contactsArrayMessage') {
            const contactsArray = JSON.parse(message.body);

            return contactsArray.map((contact, index) => {
                const vcardPreviewInfo = extrairNomeENumero(contact);

                return <VcardPreview key={index} contact={vcardPreviewInfo?.nome} number={vcardPreviewInfo?.numero} />;
            });
        }

        if (message.mediaType === 'image') {
            return <ModalImageCors imageUrl={message?.media?.mediaUrl || message?.mediaUrl} />;
        }

        if (message.mediaType === 'audio') {
            return (
                <audio controls>
                    <source src={message?.media?.mediaUrl || message?.mediaUrl} type="audio/ogg"></source>
                </audio>
            );
        }

        if (message.mediaType === 'video') {
            return <video className={classes.messageMedia} src={message?.media?.mediaUrl || message?.mediaUrl} controls />;
        } else {
            return (
                <>
                    <div className={classes.downloadMedia}>
                        <Button
                            startIcon={<GetApp />}
                            color="primary"
                            variant="outlined"
                            target="_blank"
                            href={message?.media?.mediaUrl || message?.mediaUrl}
                        >
                            Download
                        </Button>
                    </div>
                    <Divider />
                </>
            );
        }
    };

    const renderMessageAck = (message) => {
        if (message.ack === 0) {
            return <AccessTime fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 1) {
            return <Done fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 2) {
            return <DoneAll fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 3 || message.ack === 4) {
            return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
        }
    };

    const isEdited = (message) => {
        if (message.isEdited) {
            if (message.user && message.user.name) {
                return (
                    <Tooltip title={message.user.name} arrow>
                        <i>{i18n.t("messagesList.others.editedMessage")}&nbsp;{`(${getFirstName(message.user.name)})`}&nbsp;&nbsp;</i>
                    </Tooltip>
                );
            } else {
                return (
                    <i>{i18n.t("messagesList.others.editedMessage")}&nbsp;&nbsp;</i>
                );
            }
        }
        return '';
    };

    const renderDailyTimestamps = (message, index) => {
        if (index === 0) {
            return (
                <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
                    <div className={classes.dailyTimestampText}>
                        {format(parseISO(messagesList[index].createdAt), 'dd/MM/yyyy hh:mm a')}
                    </div>
                </span>
            );
        }
        if (index < messagesList.length - 1) {
            let messageDay = parseISO(messagesList[index].createdAt);
            let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

            if (!isSameDay(messageDay, previousMessageDay)) {
                return (
                    <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
                        <div className={classes.dailyTimestampText}>
                            {format(parseISO(messagesList[index].createdAt), 'dd/MM/yyyy')}
                        </div>
                    </span>
                );
            }
        }
        if (index === messagesList.length - 1) {
            return (
                <>
                    <div key={`ref-${message.createdAt}`} ref={lastMessageRef} style={{ float: 'left', clear: 'both' }} />
                </>
            );
        }
    };

    const renderNumberTicket = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageTicket = message.ticketId;
            let previousMessageTicket = messagesList[index - 1].ticketId;

            if (messageTicket !== previousMessageTicket) {
                return (
                    <center>
                        <div className={classes.ticketNunberClosed}>
                            Conversa encerrada:{' '}
                            {format(parseISO(messagesList[index - 1].createdAt), 'dd/MM/yyyy HH:mm:ss')}
                        </div>
                        <div className={classes.ticketNunberOpen}>
                            Conversa iniciada: {format(parseISO(message.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                        </div>
                    </center>
                );
            }
        }
    };

    const renderMessageDivider = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageUser = messagesList[index].fromMe;
            let previousMessageUser = messagesList[index - 1].fromMe;

            if (messageUser !== previousMessageUser) {
                return <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>;
            }
        }
    };

    const renderQuotedMessage = (message) => {
        return (
            <div
                className={clsx(classes.quotedContainerLeft, {
                    [classes.quotedContainerRight]: message.fromMe,
                })}
            >
                <span
                    className={clsx(classes.quotedSideColorLeft, {
                        [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
                    })}
                ></span>
                <div className={classes.quotedMsg}>
                    {!message.quotedMsg?.fromMe && (
                        <span className={classes.messageContactName}>{message.quotedMsg?.contact?.name}</span>
                    )}

                    {message.quotedMsg.mediaType === 'audio' && (
                        <div className={classes.downloadMedia}>
                            <audio controls>
                                <source src={message?.quotedMsg?.media?.mediaUrl || message?.quotedMsg?.mediaUrl} type="audio/ogg"></source>
                            </audio>
                        </div>
                    )}
                    {message.quotedMsg.mediaType === 'video' && (
                        <video className={classes.messageMedia} src={message?.quotedMsg?.media?.mediaUrl || message?.quotedMsg?.mediaUrl} controls />
                    )}
                    {message.quotedMsg.mediaType === 'application' && (
                        <div className={classes.downloadMedia}>
                            <Button
                                startIcon={<GetApp />}
                                color="primary"
                                variant="outlined"
                                target="_blank"
                                href={message?.quotedMsg?.media?.mediaUrl || message?.quotedMsg?.mediaUrl}
                            >
                                Download
                            </Button>
                        </div>
                    )}

                    {(message.quotedMsg.mediaType === 'image' && (
                        <ModalImageCors imageUrl={message?.quotedMsg?.media?.mediaUrl || message?.quotedMsg?.mediaUrl} />
                    )) ||
                        message.quotedMsg?.body}
                    {!message.quotedMsg.mediaType === 'image' && message.quotedMsg?.body}
                </div>
            </div>
        );
    };

    const renderMessages = () => {
        if (messagesList.length > 0) {
            const viewMessagesList = messagesList.map((message, index) => {
                if (message.mediaType === 'call_log') {
                    return (
                        <React.Fragment key={message.id}>

                            {renderDailyTimestamps(message, index)}
                            {renderNumberTicket(message, index)}
                            {renderMessageDivider(message, index)}
                            <div className={classes.messageCenter}>
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    id="messageActionsButton"
                                    disabled={message.isDeleted}
                                    className={classes.messageActionsButton}
                                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                                >
                                    <ExpandMore />
                                </IconButton>
                                {isGroup && <span className={classes.messageContactName}>{message.contact?.name}</span>}
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" width="20" height="17">
                                        <path
                                            fill="#df3333"
                                            d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                                        ></path>
                                    </svg>{' '}
                                    <span>
                                        Chamada de voz/vídeo perdida às {format(parseISO(message.createdAt), 'HH:mm')}
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
                if (!message.fromMe) {
                    return (
                        <React.Fragment key={message.id}>

                            {renderDailyTimestamps(message, index)}
                            {renderNumberTicket(message, index)}
                            {renderMessageDivider(message, index)}
                            <div className={classes.messageLeft}>
                                {message.isForwarded && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                        <ReplyAllIcon style={{ transform: 'scaleX(-1)', fontSize: 16, marginRight: 5, color: '#999999' }} />
                                        <span className={classes.forwardMessage}>
                                            Encaminhada
                                        </span>
                                    </div>
                                )}
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    id="messageActionsButton"
                                    disabled={message.isDeleted}
                                    className={classes.messageActionsButton}
                                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                                >
                                    <ExpandMore />
                                </IconButton>

                                {isGroup && <span className={classes.messageContactName}>{message.contact?.name}</span>}

                                {/* aviso de mensagem apagado pelo contato */}
                                {message.isDeleted && (
                                    <div>
                                        <span className={classes.deletedMessage}>
                                            <span role="img" aria-label="deleted-message">
                                                🚫
                                            </span>{' '}
                                            Este mensaje ha sido eliminado por el contacto. &nbsp;
                                        </span>
                                    </div>
                                )}
                                {((message.mediaUrl || message?.media?.mediaUrl) ||
                                    message.mediaType === 'locationMessage' ||
                                    message.mediaType === 'contactMessage') &&
                                    //|| message.mediaType === "multi_vcard"
                                    checkMessageMedia(message)}

                                <div className={message.isEdited ? classes.textContentItemEdited : classes.textContentItem}>
                                    {message.quotedMsg && renderQuotedMessage(message)}

                                    {!['reactionMessage', 'locationMessage', 'contactMessage'].includes(
                                        message.mediaType
                                    ) && <MarkdownWrapper>{message.body}</MarkdownWrapper>}

                                    {message.quotedMsg && message.mediaType === 'reactionMessage' && (
                                        <>
                                            <Badge
                                                className={classes.badge}
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                badgeContent={
                                                    <span
                                                        style={{
                                                            fontSize: '4em',
                                                            marginTop: '-95px',
                                                            marginLeft: '340px',
                                                        }}
                                                    >
                                                        {message.body}
                                                    </span>
                                                }
                                            ></Badge>
                                            <span style={{ marginLeft: '0px' }}>
                                                <MarkdownWrapper>
                                                    {'_*' + message.contact.name + '*_ reagiu...'}
                                                </MarkdownWrapper>
                                            </span>
                                        </>
                                    )}

                                    <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                                        {isEdited(message)}
                                        {format(parseISO(message.createdAt), 'HH:mm')}
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment key={message.id}>

                            {renderDailyTimestamps(message, index)}
                            {renderMessageDivider(message, index)}
                            <div className={message.mediaType === 'notepad' ? classes.messageRightNotepad : classes.messageRight}>
                                {message.isForwarded && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                        <ReplyAllIcon style={{ transform: 'scaleX(-1)', fontSize: 16, marginRight: 5, color: '#999999' }} />
                                        <span className={classes.forwardMessage}>
                                            Encaminhada
                                        </span>
                                    </div>
                                )}
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    id="messageActionsButton"
                                    disabled={message.isDeleted}
                                    className={classes.messageActionsButton}
                                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                                >
                                    <ExpandMore />
                                </IconButton>
                                {((message.mediaUrl || message?.media?.mediaUrl) ||
                                    message.mediaType === 'locationMessage' ||
                                    message.mediaType === 'contactMessage') &&
                                    //|| message.mediaType === "multi_vcard"
                                    checkMessageMedia(message)}
                                <div
                                    className={(message.isEdited && !message.isDeleted) ?
                                        classes.textContentItemEdited :
                                        clsx(classes.textContentItem, {
                                            [classes.textContentItemDeleted]: message.isDeleted,
                                        })}
                                >

                                    {message.isDeleted && `🚫`}

                                    {message.quotedMsg && renderQuotedMessage(message)}
                                    {message.mediaType !== 'reactionMessage' &&
                                        message.mediaType !== 'locationMessage' && (
                                            <MarkdownWrapper>{message.body}</MarkdownWrapper>
                                        )}

                                    {message.quotedMsg && message.mediaType === 'reactionMessage' && (
                                        <>
                                            <Badge
                                                className={classes.badge}
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                badgeContent={
                                                    <span
                                                        style={{
                                                            fontSize: '4em',
                                                            marginTop: '42px',
                                                            marginLeft: '0px',
                                                        }}
                                                    >
                                                        {message.body.split('*')[2]}
                                                    </span>
                                                }
                                            ></Badge>
                                            <span style={{ marginLeft: '-135px' }}>
                                                <MarkdownWrapper>
                                                    {message.body.split(':')[0] + '* reagiu....'}
                                                </MarkdownWrapper>
                                            </span>
                                        </>
                                    )}

                                    <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                                        {isEdited(message)}
                                        {format(parseISO(message.createdAt), 'HH:mm')}
                                        {message.mediaType === 'notepad' ?
                                            <LocalOfferIcon
                                                style={{
                                                    width: '20px',
                                                    height: '15px',
                                                    marginBottom: '-3px'
                                                }} />
                                            :
                                            renderMessageAck(message)
                                        }
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            });
            return viewMessagesList;
        } else {
            return <div>¡Saluda a tu nuevo contacto!</div>;
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();

        const droppedFiles = Array.from(event.dataTransfer.files);
        setMedias(droppedFiles);
        event.dataTransfer.clearData();
    };

    const handleDragOver = (event) => {
        event.preventDefault();

        event.dataTransfer.dropEffect = 'copy';
    };

    const handleInputPaste = (e) => {
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]]);
        }
    };

    return (
        <div className={classes.messagesListWrapper}>
            <MessageOptionsMenu
                ticket={ticket}
                message={selectedMessage}
                anchorEl={anchorEl}
                menuOpen={messageOptionsMenuOpen}
                handleClose={handleCloseMessageOptionsMenu}
            />
            <div
                id="messagesList"
                className={classes.messagesList}
                onScroll={handleScroll}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onPaste={handleInputPaste}
            >
                {messagesList.length > 0 ? renderMessages() : []}
            </div>
            {(ticket?.channel === 'instagram' || ticket?.channel === 'facebook') && (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        padding: '10px',
                        alignItems: 'center',
                        backgroundColor: '#E1F3FB',
                    }}
                >
                    {ticket?.channel === 'facebook' ? <Facebook /> : <Instagram />}

                    <span>
                        Tiene 24 horas para responder después de recibir un mensaje, según las políticas de Facebook.
                    </span>
                </div>
            )}
            {loading && (
                <div>
                    <CircularProgress className={classes.circleLoading} />
                </div>
            )}
        </div>
    );
};

export default MessagesList;
