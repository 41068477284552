/**
 * Filtra os cards do Kanban com base em múltiplos critérios
 * @param {Object} kanbanData - Objeto contendo a estrutura do Kanban
 * @param {Object} filters - Objeto contendo os critérios de filtragem
 * @returns {Object} - Nova estrutura do Kanban contendo apenas os cards que correspondem aos critérios
 */
const filterKanbanData = (kanbanData, filters = {}) => {
    // Desestrutura os filtros
    const {
        searchTerm = '',
        minValue,
        maxValue,
        status = [],
        owners = [],
        tags = [],
        dateRange,
        startDate,
        endDate,
        showMyOnly,
        sortBy,
        userId // ID do usuário atual, necessário para filtro showMyOnly
    } = filters;

    console.info({
        status,
        owners,
        tags
    });

    // Se não houver filtros, retorna os dados originais
    if (
        !searchTerm.trim() &&
        minValue === undefined &&
        maxValue === undefined &&
        status.length === 0 &&
        owners.length === 0 &&
        tags.length === 0 &&
        !dateRange &&
        !startDate &&
        !endDate &&
        !showMyOnly
    ) {
        return kanbanData;
    }

    // Normaliza o termo de busca (remove espaços extras, converte para minúsculo)
    const normalizedSearchTerm = searchTerm.trim().toLowerCase();

    // Cria uma cópia profunda do objeto original para não modificá-lo
    const result = JSON.parse(JSON.stringify(kanbanData));

    // Converte os IDs dos status, owners e tags para arrays para facilitar a comparação
    const statusIds = status.map(item => item.id);
    const ownerIds = owners.map(item => item.id);
    const tagIds = tags.map(item => item.id);

    /**
     * Converte uma data para o formato YYYY-MM-DD, removendo a parte de horas/minutos/segundos
     * @param {Date|string} date - Data para converter
     * @returns {string} - Data no formato YYYY-MM-DD
     */
    const formatDateToYYYYMMDD = (date) => {
        if (!date) return null;
        let dateObj;
        if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
            return date;
        }
        if (typeof date === 'string') {
            if (date.indexOf('T') === -1) {
                dateObj = new Date(`${date}T12:00:00`);
            } else {
                dateObj = new Date(date);
            }
        } else {
            dateObj = date;
        }
        const isoDate = dateObj.toISOString().substring(0, 10);
        return isoDate;
    };

    // Função para verificar se uma data está dentro do intervalo desejado
    const isDateInRange = (dateString) => {
        if (!dateRange || dateRange === 'all') return true;
        const cardDateStr = formatDateToYYYYMMDD(new Date(dateString));

        // Obter a data atual
        const now = new Date();
        const todayStr = formatDateToYYYYMMDD(now);

        // Obter ontem
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = formatDateToYYYYMMDD(yesterday);

        // Início da semana (Domingo)
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - now.getDay());
        const startOfWeekStr = formatDateToYYYYMMDD(startOfWeek);

        // Início do mês
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const startOfMonthStr = formatDateToYYYYMMDD(startOfMonth);

        // Início do ano
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        const startOfYearStr = formatDateToYYYYMMDD(startOfYear);

        switch (dateRange) {
            case 'today':
                return cardDateStr === todayStr;
            case 'yesterday':
                return cardDateStr === yesterdayStr;
            case 'week':
                return cardDateStr >= startOfWeekStr && cardDateStr <= todayStr;
            case 'month':
                return cardDateStr >= startOfMonthStr && cardDateStr <= todayStr;
            case 'year':
                return cardDateStr >= startOfYearStr && cardDateStr <= todayStr;
            case 'custom':
                const startDateStr = startDate ? formatDateToYYYYMMDD(startDate) : null;
                const endDateStr = endDate ? formatDateToYYYYMMDD(endDate) : null;

                console.info({
                    cardDate: cardDateStr,
                    startDate: startDateStr,
                    endDate: endDateStr
                });

                if (startDateStr && endDateStr) {
                    return cardDateStr >= startDateStr && cardDateStr <= endDateStr;
                }
                if (startDateStr) {
                    return cardDateStr >= startDateStr;
                }
                if (endDateStr) {
                    return cardDateStr <= endDateStr;
                }
                return true;
            default:
                return true;
        }
    };

    // Filtra os cards em cada coluna
    result.column = result.column.map(column => {
        // Filtra os cards que correspondem a todos os critérios de filtragem
        const filteredCards = column.cards.filter(card => {
            // 1. Filtragem por termo de busca (título ou descrição)
            const titleMatch = !normalizedSearchTerm ||
                (card.title && card.title.toLowerCase().includes(normalizedSearchTerm));
            const descriptionMatch = !normalizedSearchTerm ||
                (card.description && card.description.toLowerCase().includes(normalizedSearchTerm));
            const textSearchMatch = titleMatch || descriptionMatch;
            if (normalizedSearchTerm && !textSearchMatch) return false;

            // 2. Filtragem por valor (amount)
            const amount = parseFloat(card.amount || 0);
            if (minValue !== undefined && amount < minValue) return false;
            if (maxValue !== undefined && amount > maxValue) return false;

            // 3. Filtragem por status
            if (statusIds.length > 0) {
                if (!card.kanbanPriorityId || !statusIds.includes(card.kanbanPriorityId)) return false;
            }

            // 4. Filtragem por proprietário (owner)
            if (ownerIds.length > 0) {
                if (!card.ownerId || !ownerIds.includes(card.ownerId)) return false;
            }

            // 5. Filtragem por tags
            if (tagIds.length > 0) {
                // Verifica se o card tem pelo menos uma das tags especificadas
                console.info(card.tags);
                const cardTagIds = (card.tags || []).map(tag => tag.id);
                const hasMatchingTag = tagIds.some(tagId => cardTagIds.includes(tagId));
                if (!hasMatchingTag) return false;
            }

            // 6. Filtragem por data
            if (dateRange && dateRange !== 'all') {
                if (!isDateInRange(card.createdAt)) return false;
            }

            // 7. Mostrar apenas cards do usuário atual
            if (showMyOnly && userId) {
                if (card.ownerId !== userId) return false;
            }

            // Passou por todos os filtros
            return true;
        });

        // Retorna a coluna com os cards filtrados
        return {
            ...column,
            cards: filteredCards
        };
    });

    // Ordenação dos cards, se necessário
    if (sortBy && sortBy !== 'default') {
        result.column.forEach(column => {
            column.cards.sort((a, b) => {
                switch (sortBy) {
                    case 'newest':
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    case 'oldest':
                        return new Date(a.createdAt) - new Date(b.createdAt);
                    case 'highestValue':
                        return parseFloat(b.amount || 0) - parseFloat(a.amount || 0);
                    case 'lowestValue':
                        return parseFloat(a.amount || 0) - parseFloat(b.amount || 0);
                    default:
                        return 0;
                }
            });
        });
    }

    return result;
};

export default filterKanbanData;