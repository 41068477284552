import React, { useContext, useRef, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import { Menu } from '@material-ui/core';
import { ReplyMessageContext } from '../../context/ReplyingMessage/ReplyingMessageContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ConfirmationModal from '../ConfirmationModal';
import ForwardMessageModal from '../ForwardMessageModal';
import EditMessageModal from '../EditMessageModal';
import ForwardMessageToContactModal from '../ForwardMessageToContactModal';

const MessageOptionsMenu = ({ ticket, message, menuOpen, handleClose, anchorEl }) => {
    const { setReplyingMessage } = useContext(ReplyMessageContext);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editMessage, setEditMessage] = useState(null);
    const [forwardMessageModalOpen, setForwardMessageModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    // const [selectedSchedule, setSelectedSchedule] = useState(null);
    const isMounted = useRef(true);

    const handleForwardMessage = () => {
        setForwardMessageModalOpen(true);
        handleClose();
    };

    // const handleForwardMessage = () => {
    // 	//setSelectedSchedule(schedule);
    // 	handleClose();
    // 	setScheduleModalOpen(true);
    // };

    // const handleCloseScheduleModal = () => {
    // 	setSelectedSchedule(null);
    // 	setScheduleModalOpen(false);
    // 	handleClose();
    // };

    const SubmitEditMessage = async (body) => {
        setLoading(true);
        try {
            await api.post(`/messages/edit/${message.id}`, { body });
            setEditOpen(false);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseForwardMessageModal = () => {
        if (isMounted.current) {
            setForwardMessageModalOpen(false);
        }
    };

    const handleDeleteMessage = async () => {
        try {
            await api.delete(`/messages/${message.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const hanldeReplyMessage = () => {
        setReplyingMessage(message);
        handleClose();
    };

    const handleOpenConfirmationModal = (e) => {
        setConfirmationOpen(true);
        handleClose();
    };

    const handleEditMessage = () => {
        setEditMessage(message);
        handleClose();
        setEditOpen(true);
    };

    function checkElapsedTime(creationDate) {
        const creationDateObj = new Date(creationDate);
        const now = new Date();
        const timeDifferenceInMilliseconds = now - creationDateObj;
        const elapsedTimeInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
        if (elapsedTimeInMinutes > 15) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <>
            <EditMessageModal
                title={i18n.t("messageOptionsMenu.confirmationModal.title")}
                message={editMessage}
                loading={loading}
                open={editOpen}
                onClose={setEditOpen}
                onSave={SubmitEditMessage}
            >
                {i18n.t("messageOptionsMenu.confirmationModal.message")}
            </EditMessageModal>
            <ConfirmationModal
                title={i18n.t('messageOptionsMenu.confirmationModal.title')}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteMessage}
            >
                {i18n.t('messageOptionsMenu.confirmationModal.message')}
            </ConfirmationModal>
            {/* <ForwardModal
				open={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				message={message}
			/> */}
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
            >
                {/* Rendering the edit menu item only if the message is older than 15 minutes */}
                {message.fromMe && checkElapsedTime(message.createdAt) && (
                    <MenuItem key="edit" onClick={handleEditMessage}>
                        {i18n.t("messageOptionsMenu.edit")}
                    </MenuItem>
                )}
                {message.fromMe && (
                    <MenuItem onClick={handleOpenConfirmationModal}>{i18n.t('messageOptionsMenu.delete')}</MenuItem>
                )}
                <MenuItem onClick={hanldeReplyMessage}>{i18n.t('messageOptionsMenu.reply')}</MenuItem>

                <MenuItem onClick={() => handleForwardMessage()}>{i18n.t('messageOptionsMenu.toForward')}</MenuItem>
                {/* <MenuItem onClick={() => handleForwardMessage(message)}>
					{i18n.t("messageOptionsMenu.toForward")}
				</MenuItem> */}
            </Menu>

            {/*
             <ForwardMessageModal
                modalOpen={forwardMessageModalOpen}
                onClose={handleCloseForwardMessageModal}
                message={message}
            />
            */}

            <ForwardMessageToContactModal
                ticketId={ticket.id}
                open={forwardMessageModalOpen}
                onClose={handleCloseForwardMessageModal}
                message={message}
            />
        </>
    );
};

export default MessageOptionsMenu;
