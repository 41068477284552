import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import CreateTicketForForwardMessage from '../CreateTicketForForwardMessage';
import formatBrazilianNumber from '../../utils/formatBrazilianNumber';
import toastError from '../../errors/toastError';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        borderRadius: 8,
        maxWidth: 500,
        width: '100%',
        height: '80%',
        maxHeight: 700,
        margin: 0,
    },
    dialogTitle: {
        padding: '10px 12px',
        borderBottom: '1px solid #e9edef',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.mode === 'dark' ? '#303030' : '#3287c9',
        color: 'white',
    },
    titleText: {
        fontWeight: 500,
        fontSize: '1.1rem',
        marginLeft: 8,
    },
    dialogContent: {
        padding: '0px',
    },
    searchContainer: {
        padding: '8px 12px',
        backgroundColor: theme.mode === 'dark' ? '#303030' : '#f0f2f5',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    selectedContacts: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
        padding: '8px 12px',
        backgroundColor: theme.mode === 'dark' ? '#303030' : '#fff',
        borderBottom: '1px solid #e9edef',
        position: 'sticky',
        top: 56,
        zIndex: 1,
    },
    listContainer: {
        height: 'calc(100% - 56px)',
        overflowY: 'auto',
        backgroundColor: theme.mode === 'dark' ? '#303030' : '#fff',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c5c5c5',
            borderRadius: '3px',
        },
    },
    contactChip: {
        backgroundColor: '#e7f7e4',
        borderRadius: '12px',
        height: '32px',
        margin: '2px',
        '& .MuiChip-label': {
            paddingLeft: '8px',
            paddingRight: '4px',
        },
        '& .MuiChip-deleteIcon': {
            color: '#8a8a8a',
            margin: '0 4px 0 -4px',
            '&:hover': {
                color: '#000',
            },
        },
    },
    listItem: {
        padding: '8px 16px',
        '&:hover': {
            backgroundColor: theme.mode === 'dark' ? '#424242' : '#f5f5f5',
        },
    },
    listItemDisabled: {
        padding: '8px 16px',
        opacity: 0.5,
        pointerEvents: 'none',
    },
    dialogActions: {
        borderTop: '1px solid #e9edef',
        padding: '8px 12px',
        justifyContent: 'flex-end',
    },
    checkbox: {
        color: '#8a8a8a',
        '&.Mui-checked': {
            color: '#3287c9',
        },
    },
    avatar: {
        backgroundColor: '#e6e6e6',
        color: '#666',
    },
    searchInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 20,
            backgroundColor: theme.mode === 'dark' ? '#222222' : '#white',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    countBadge: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: '#3287c9',
        color: 'white',
        fontSize: '0.75rem',
        marginLeft: 8,
    },
    backButton: {
        color: 'white',
        padding: 6,
    },
    sendButton: {
        backgroundColor: '#3287c9',
        color: 'white',
        borderRadius: 20,
        textTransform: 'none',
        padding: '4px 16px',
        '&:hover': {
            backgroundColor: '#3287c9',
        },
        '&.Mui-disabled': {
            backgroundColor: '#e9edef',
            color: '#8a8a8a',
        },
    },
    cancelButton: {
        color: '#ff0000',
        textTransform: 'none',
    },
}));

const MAX_CONTACTS = 5;

const ForwardMessageToContactModal = ({ ticketId, open, onClose, message }) => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState('');
    const [tickets, setTickets] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [openCreateTicket, setOpenCreateTicket] = useState(false);
    const [limit, setLimit] = useState(10);

    console.info({ message, ticketId });

    useEffect(() => {
        if (!open) return;
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get('/forward-message/', {
                    params: {
                        searchParam,
                        pageNumber,
                        limit
                    }
                });

                // Map tickets to include isTicket flag
                const ticketsWithFlag = (data?.tickets || []).map(ticket => ({
                    ...ticket,
                    contact: {
                        ticketId: ticket.id,
                        ...ticket.contact,
                        isTicket: true
                    }
                }));
                setTickets(ticketsWithFlag);

                // Map contacts to include isTicket flag
                const contactsWithFlag = (data?.contacts || []).map(contact => ({
                    ticketId: null,
                    ...contact,
                    isTicket: false
                }));
                
                setContacts((prevState) => [...prevState, ...contactsWithFlag]);
                setHasMore(data.hasMore);

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [open, searchParam, pageNumber]);


    useEffect(() => {
        setTickets([]);
        setContacts([]);
        setPageNumber(1);
    }, [searchParam]);

    const handleSearchChange = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleToggleContact = (contact) => {
        setSelectedContacts(prevSelected => {
            // Se o contato já está selecionado, apenas remover ele
            if (prevSelected.some(c => c.id === contact.id)) {
                return prevSelected.filter(c => c.id !== contact.id);
            }

            // Se tentar adicionar mais que o limite
            if (prevSelected.length >= MAX_CONTACTS) {
                console.error("Máximo de 5 contatos selecionados");
                return prevSelected;
            }

            // Caso contrário, adicionar o contato
            return [...prevSelected, contact];
        });
    };


    const handleConfirm = () => {
        const hasNewTickets = selectedContacts.some(contact => !contact.isTicket);
        if (hasNewTickets) {
            setOpenCreateTicket(true);
        } else {
            handleCreateMessage({ selectedContacts: selectedContacts.map(contact => contact.id), selectedQueue: null });
        }
    };

    useEffect(() => {
        if (!open) {
            setSearchParam('');
            setSelectedContacts([]);
        }
    }, [open]);

    // Verificar se o limite foi atingido
    const isSelectionDisabled = selectedContacts.length >= MAX_CONTACTS;

    const loadMore = () => setPageNumber((prevState) => prevState + 1);

    const handleScroll = (e) => {
        console.info('scroll')
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleCreateMessage = async ({ selectedContacts, selectedQueue }) => {
        try {
            setLoading(true);
            const { data } = await api.post('/message/forward', {
                messageId: message.id,
                contacts: selectedContacts,
                ticketId
            });
            onClose(false);
        } catch (error) {
            console.error(error)
            toastError('Erro ao encaminhar mensagem');
        } finally {
            setLoading(false);
        };
    };

    return (
        <>
            <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
                <DialogTitle className={classes.dialogTitle} disableTypography>
                    <IconButton className={classes.backButton} size="small" onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography className={classes.titleText}>
                        Encaminhar mensagem
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box className={classes.searchContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Buscar contatos"
                            value={searchParam}
                            onChange={handleSearchChange}
                            size="small"
                            className={classes.searchInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" style={{ color: '#8a8a8a' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <List className={classes.listContainer} onScroll={handleScroll}>
                        {tickets.length > 0 && (
                            <>
                                <Typography variant="subtitle2" style={{ padding: '16px 16px 0', color: '#3287c9' }}>
                                    Atendimentos ativos
                                </Typography>
                                {tickets.map(({ contact }) => {
                                    const isSelected = selectedContacts.some(c => c.id === contact.id);
                                    const isDisabled = isSelectionDisabled && !isSelected;

                                    return (
                                        <ListItem
                                            key={contact.id}
                                            className={isDisabled ? classes.listItemDisabled : classes.listItem}
                                            dense
                                            button
                                            onClick={() => handleToggleContact(contact)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar src={contact?.profilePicUrl} alt={contact.name} className={classes.avatar}>{contact.name.charAt(0).toUpperCase()}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={contact.name}
                                                secondary={formatBrazilianNumber(contact.number)}
                                                primaryTypographyProps={{ style: { fontWeight: 500 } }}
                                                secondaryTypographyProps={{ style: { color: '#8a8a8a', fontSize: '0.8rem' } }}
                                            />
                                            <Checkbox
                                                checked={isSelected}
                                                className={classes.checkbox}
                                                size="small"
                                                disabled={isDisabled}
                                                color="default"
                                            />
                                        </ListItem>
                                    );
                                })}
                            </>
                        )}
                        {contacts.length > 0 && (
                            <>
                                <Typography variant="subtitle2" style={{ padding: '16px 16px 0', color: '#3287c9' }}>
                                    Todos os contatos
                                </Typography>
                                {contacts.map((contact) => {
                                    const isSelected = selectedContacts.some(c => c.id === contact.id);
                                    const isDisabled = isSelectionDisabled && !isSelected;

                                    return (
                                        <ListItem
                                            key={contact.id}
                                            className={isDisabled ? classes.listItemDisabled : classes.listItem}
                                            dense
                                            button
                                            onClick={() => handleToggleContact(contact)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar src={contact?.profilePicUrl} alt={contact.name} className={classes.avatar}>{contact.name.charAt(0).toUpperCase()}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={contact.name}
                                                secondary={formatBrazilianNumber(contact.number)}
                                                primaryTypographyProps={{ style: { fontWeight: 500 } }}
                                                secondaryTypographyProps={{ style: { color: '#8a8a8a', fontSize: '0.8rem' } }}
                                            />
                                            <Checkbox
                                                checked={isSelected}
                                                className={classes.checkbox}
                                                size="small"
                                                disabled={isDisabled}
                                                color="default"
                                            />
                                        </ListItem>
                                    );
                                })}
                            </>
                        )}
                        {loading && (
                            <Box ml={2} display="flex" alignItems="center">
                                <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                <Box ml={2} style={{ width: '200px' }}>
                                    <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" height={10} width="70%" />
                                </Box>
                            </Box>
                        )}
                    </List>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary" variant="outlined" disabled={loading}>Cancelar</Button>
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            disabled={selectedContacts.length === 0 || loading}
                            startIcon={loading ? <CircularProgress size={20} /> : null}
                            variant="outlined"
                        >
                            {loading ? 'Enviando...' : `Enviar (${selectedContacts.length}/5)`}
                        </Button>
                    </DialogActions>
                </DialogActions>
            </Dialog>
            <CreateTicketForForwardMessage
                modalOpen={openCreateTicket}
                onClose={setOpenCreateTicket}
                handleCreateMessage={handleCreateMessage}
                selectedContacts={selectedContacts}
            />
        </>
    );
};

export default ForwardMessageToContactModal;