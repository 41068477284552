function countChangedFiltersKanban(currentFilters) {
    // Definir os valores padrão
    const defaultFilters = {
        minValue: 0,
        maxValue: 1000000000,
        selectedStatus: [],
        sortBy: 'default',
        selectedOwners: [],
        selectedTags: [],
        dateRange: 'all',
        startDate: null,
        endDate: null,
        showMyOnly: false,
        showTaskNumber: false
    };

    let changesCount = 0;

    console.info({ currentFilters, defaultFilters })
    // Verificar cada propriedade para determinar se foi alterada
    if (currentFilters.minValue !== defaultFilters.minValue) changesCount++;
    if (currentFilters.maxValue !== defaultFilters.maxValue) changesCount++;
    if (currentFilters.sortBy !== defaultFilters.sortBy) changesCount++;
    if (currentFilters.dateRange !== defaultFilters.dateRange) changesCount++;
    if (currentFilters.showMyOnly !== defaultFilters.showMyOnly) changesCount++;
    if (currentFilters.showTaskNumber !== defaultFilters.showTaskNumber) changesCount++;
    // Para arrays, verificamos se o comprimento mudou (indicando que foi adicionado um item)
    if (currentFilters.selectedStatus.length > 0) changesCount++;
    if (currentFilters.selectedOwners.length > 0) changesCount++;
    if (currentFilters.selectedTags.length > 0) changesCount++;

    // Para datas, verificamos se passaram de null para algum valor
    if (currentFilters?.dateRange === "custom") {
        if (currentFilters.startDate !== null) changesCount++;
        if (currentFilters.endDate !== null) changesCount++;
    };

    return changesCount;
}

export default countChangedFiltersKanban;