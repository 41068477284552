import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';

// Define styles using makeStyles (Material-UI v4 approach)
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: 'none',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        paddingLeft: '5px',
    },
    exceedBackground: {
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
    exceedText: {
        color: 'red',
    },
    counterText: {
        margin: '0px 0px 0px 15px',
    },
    shakeAnimation: {
        animation: '$shake 0.3s ease',
    },
    '@keyframes shake': {
        '0%': { transform: 'translateX(0)' },
        '25%': { transform: 'translateX(-5px)' },
        '50%': { transform: 'translateX(5px)' },
        '75%': { transform: 'translateX(-5px)' },
        '100%': { transform: 'translateX(0)' },
    },
}));

const KanbanInput = forwardRef(({ label, value, onSave, onCancel, fontSize, limit, ...props }, ref) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [isShaking, setIsShaking] = useState(false);
    const paperRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (paperRef.current && !paperRef.current.contains(event.target)) {
                if (inputValue.trim() !== value?.trim()) {
                    handleSave();
                } else {
                    onCancel();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputValue, value, onCancel]);

    const handleSave = () => {
        if (inputValue.trim() === value?.trim()) {
            return;
        }

        if (inputValue.trim() === '' && inputValue.trim() !== value?.trim()) {
            console.warn('Input cannot be empty');
            onCancel();
            return;
        }

        onSave(inputValue);
        setInputValue('');
    };

    const isExceeded = limit ? inputValue.length >= limit : false;

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (isExceeded && newValue.length > inputValue.length) {
            setIsShaking(true);
            setTimeout(() => setIsShaking(false), 1000);
            return;
        }
        setInputValue(newValue);
    };

    const handleBlur = () => {
        handleSave();
    };

    const handleKeyDown = (event) => {
        // Check if the pressed key is Enter
        if (event.key === 'Enter') {
            // If it's Enter without Shift, save the text
            if (!event.shiftKey) {
                event.preventDefault(); // Prevent line break
                handleSave();
            }
        }
    };

    return (
        <div>
            <Paper
                component="form"
                className={`${classes.root} ${isExceeded ? classes.exceedBackground : ''} ${isShaking ? classes.shakeAnimation : ''}`}
                style={{ backgroundColor: isExceeded ? 'rgba(255, 0, 0, 0.1)' : 'transparent' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                }}
                ref={paperRef}
            >
                <InputBase
                    {...props}
                    inputRef={inputRef}
                    className={classes.input}
                    style={{
                        fontSize: fontSize || 'inherit',
                        color: isExceeded ? 'red' : 'inherit'
                    }}
                    placeholder={label}
                    inputProps={{ 'aria-label': 'input' }}
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    rowsMax={10}
                    onKeyDown={handleKeyDown}
                />
            </Paper>
            {!!limit && (
                <Typography
                    variant="body2"
                    color={isExceeded ? "error" : "textSecondary"}
                    className={classes.counterText}
                >
                    {`${inputValue.length}/${limit}`}
                </Typography>
            )}
        </div>
    );
});

export default KanbanInput;