import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    inputRoot: {
        flexWrap: 'wrap',
        padding: '6px !important'
    },
    chip: {
        margin: '2px',
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        color: theme.palette.primary.main,
        fontWeight: 500,
        borderRadius: '4px',
    },
    loading: {
        color: theme.palette.primary.main,
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    chipAvatar: {
        width: 24,
        height: 24,
    }
}));

const KanbanCardSelectUsersAutoComplete = ({
    label = 'Buscar',
    placeholder = 'Digite para buscar...',
    multiple = true,
    defaultValue = [],
    primary = null,
    onChange
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedValues, setSelectedValues] = useState(defaultValue || []);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const listRef = useRef(null);
    const initialLoadRef = useRef(true);

    // Efeito para carregar usuários iniciais
    useEffect(() => {
        setLoading(true);
        api.get('/users/', { params: { searchParam: '', pageNumber: 1 } })
            .then(({ data }) => {
                setOptions(data.users);
                setHasMore(data.hasMore);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, []);

    // Efeito para atualizar os valores selecionados quando defaultValue mudar
    useEffect(() => {
        if (defaultValue && defaultValue.length > 0) {
            setSelectedValues(defaultValue);
        }
    }, [defaultValue]);

    // Efeito para buscar usuários quando o inputValue mudar
    useEffect(() => {
        // Evitar chamada API na primeira renderização quando defaultValue estiver definido
        if (initialLoadRef.current && defaultValue && defaultValue.length > 0) {
            initialLoadRef.current = false;
            return;
        }

        // Pular se for o reset após seleção
        if (inputValue === '') return;

        setLoading(true);
        setPage(1);

        api.get('/users/', { params: { searchParam: inputValue, pageNumber: 1 } })
            .then(({ data }) => {
                setOptions(data.users);
                setHasMore(data.hasMore);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [inputValue]);

    const loadMoreData = async () => {
        if (!hasMore || loading) return;

        setLoading(true);
        try {
            const { data } = await api.get('/users/', {
                params: { searchParam: inputValue, pageNumber: page + 1 }
            });
            setOptions(prevOptions => [...prevOptions, ...data.users]);
            setHasMore(data.hasMore);
            setPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.2) {
            loadMoreData();
        }
    };

    const handleChange = (event, newValue) => {
        setSelectedValues(newValue);
        if (onChange) onChange(newValue);
        setInputValue('');
    };

    return (
        <div className={classes.root}>
            <Autocomplete
                size="small"
                multiple={multiple}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={selectedValues}
                onChange={handleChange}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                options={options}
                loading={loading}
                ListboxProps={{ onScroll: handleScroll, ref: listRef }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            size="small"
                            key={option.id}
                            // avatar={<Avatar alt={option.name} className={classes.chipAvatar}>{option.name.charAt(0)}</Avatar>}
                            label={option.name}
                            {...getTagProps({ index })}
                            className={classes.chip}
                        />
                    ))
                }
                renderOption={(option) => (
                    <div className={classes.optionContainer}>
                        {/*<Avatar alt={option.name}>{option.name.charAt(0)}</Avatar>*/}
                        <div>
                            <div>{option.name}</div>
                            <div style={{ fontSize: '0.85em', color: '#666' }}>{option.profile}</div>
                        </div>
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: loading && <CircularProgress color="inherit" size={20} className={classes.loading} />
                        }}
                        classes={{ root: classes.inputRoot }}
                    />
                )}
            />
        </div>
    );
};

export default KanbanCardSelectUsersAutoComplete;